import React, { Component, useEffect, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner1 from '../../assets/images/demos/demo-2/banners/Home_banner.png';
import banner2 from '../../assets/images/demos/demo-2/slider/slide-2.jpg';
import banner3 from '../../assets/images/demos/demo-2/slider/slide-3.jpg';
import WebClient from '../../client/webclient.client';


function Banner({type="home"}){
    const [banners,setBanners]=useState([]);
    useEffect(()=>{
        WebClient.get({path:'/banners/'+type+'_main_banner'}).then(resp=>{
            setBanners(Object.values(resp.data));
        }).catch(err=>{
            alert(err);
        })
    },[])
    return(
    <div className='banner'>
        <div className="intro-slider-container">
        <OwlCarousel items={1}  
          className="owl-theme"  
          loop  
          nav = {false}
          margin={8}
          
          autoplay={true}
          autoplayTimeout={3000}
          autoplayHoverPause={true} >  
          {
        banners?banners.map(banner=>(<div className="intro-slide" style={{backgroundImage: `url(${banner})`}}>
            <div className="container intro-content">
                {/* <h3 className="intro-subtitle">Bedroom Furniture</h3>
                <h1 className="intro-title">Find Comfort <br></br>That Suits You.</h1>

                <a href="category.html" className="btn btn-primary">
                    <span>Shop Now</span>
                    <i className="icon-long-arrow-right"></i>
                </a> */}
            </div>{/* End .container intro-content */}
        </div>)):null
}
    </OwlCarousel>  {/* End .owl-carousel owl-simple */}

    <span className="slider-loader text-white"></span>{/* End .slider-loader */}
</div>{/* End .intro-slider-container */}

<div className="brands-border owl-carousel owl-simple" data-toggle="owl" 
    data-owl-options='{
        "nav": false, 
        "dots": false,
        "margin": 0,
        "loop": false,
        "responsive": {
            "0": {
                "items":2
            },
            "420": {
                "items":3
            },
            "600": {
                "items":4
            },
            "900": {
                "items":5
            },
            "1024": {
                "items":6
            },
            "1360": {
                "items":7
            }
        }
    }'>
    <a href="#" className="brand">
        <img src="assets/images/brands/1.png" alt="Brand Name"/>
    </a>

    <a href="#" className="brand">
        <img src="assets/images/brands/2.png" alt="Brand Name"/>
    </a>

    <a href="#" className="brand">
        <img src="assets/images/brands/3.png" alt="Brand Name"/>
    </a>

    <a href="#" className="brand">
        <img src="assets/images/brands/4.png" alt="Brand Name"/>
    </a>

    <a href="#" className="brand">
        <img src="assets/images/brands/5.png" alt="Brand Name"/>
    </a>

    <a href="#" className="brand">
        <img src="assets/images/brands/6.png" alt="Brand Name"/>
    </a>

    <a href="#" className="brand">
        <img src="assets/images/brands/7.png" alt="Brand Name"/>
    </a>
</div>{/* End .owl-carousel */}

        {/* <div className='banner-input' hidden={!(props.isInput)}>
            banner input fields
        </div> */}
    </div>
);
}
export default Banner;