
import { useEffect, useState } from "react";
import WebClient from "../../client/webclient.client";
import AddToCart from "../common/addToCartButton";
import { fixName } from "../common/helper";
import AddToWishlist from "../common/addToWishlistButton";

function LargeProductCard({id}){
    const [product,setProduct]=useState({});
    const [quantity,setQuantity] = useState(1);
    const [expand,setExpand]=useState();
    useEffect(()=>{
        WebClient.get({
            path:`/products/${id}`
        }).then(resp=>{
            setProduct(resp.data.Items)
        }).catch(err=>{
            console.log(err);
        })
    },[id]);
    function increment(value){
        setQuantity(value);
    }
    return (
        <>
            {/* <div className={'product-'+id}>
                <div className="largePImage">
                    <img src={product.image}/>
                </div>
                <div className="largePContent">
                    <div className="largePTitle">
                        <h1>
                            {product.productName}
                        </h1>
                    </div>
                    <div className="largePDesc">
                        <p>
                            {product.productDesc||'Sample desc'}
                        </p>
                    </div>
                </div>
            </div> */}

                <div className="page-content">
                    <div className="container">
                        <div className="product-details-top">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="product-gallery product-gallery-vertical">
                                        <div className="ht-f ht-fc ht-ac">
                                            <figure className="product-main-image">
                                                <img id="product-zoom" src={product.image} data-zoom-image="" alt="product image"/>

                                                <a href="#" id="btn-product-gallery" className="btn-product-gallery">
                                                    <i className="icon-arrows"></i>
                                                </a>
                                            </figure>

                                            <div id="product-zoom-gallery" className="product-image-gallery">
                                                <a className="product-gallery-item active" href="#" data-image="assets/images/products/single/1.jpg" data-zoom-image="assets/images/products/single/1-big.jpg">
                                                    <img src={product.image} alt="product side"/>
                                                </a>

                                                {/* <a className="product-gallery-item" href="#" data-image="assets/images/products/single/2.jpg" data-zoom-image="assets/images/products/single/2-big.jpg">
                                                    <img src={product.image} alt="product cross"/>
                                                </a>

                                                <a className="product-gallery-item" href="#" data-image="assets/images/products/single/3.jpg" data-zoom-image="assets/images/products/single/3-big.jpg">
                                                    <img src={product.image} alt="product with model"/>
                                                </a>

                                                <a className="product-gallery-item" href="#" data-image="assets/images/products/single/4.jpg" data-zoom-image="assets/images/products/single/4-big.jpg">
                                                    <img src={product.image} alt="product back"/>
                                                </a> */}
                                            </div>{/* End .product-image-gallery */}
                                        </div>{/* End .row */}
                                    </div>{/* End .product-gallery */}
                                </div>{/* End .col-md-6 */}
                                <div className="col-md-6">
                                    <div className="product-details">
                                        <h1 className="product-title text-left">{product.productName}</h1>

                                        <div className="ratings-container">
                                            <div className="ratings">
                                                <div className="ratings-val"></div>{/* End .ratings-val */}
                                            </div>{/* End .ratings */}
                                            <a className="ratings-text" href="#product-review-link" id="review-link">( 2 Reviews )</a>
                                        </div>{/* End .rating-container */}

                                        <div className="product-price">
                                            Rs. {product.saleRate}
                                        </div>{/* End .product-price */}
                                        <div className="product-cat text-left prod-detail">
                                                <span className="label">Category:</span>
                                                <a href={"/products?category="+product.category}>{fixName(product.category||"")}</a>
                                            </div>

                                            {/* <div className="social-icons social-icons-sm prod-detail">
                                                <span className="label">Share:</span>
                                                <a href="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                                                <a href="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                                                <a href="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                                                <a href="#" className="social-icon" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>
                                            </div> */}
                                        <div className="product-content">
                                            <p>{product.productDesc}</p>
                                        </div>{/* End .product-content */}
                                        <div className="details-filter-row details-row-size">
                                            <label for="qty">Qty:</label>
                                            <div className="product-details-quantity">
                                                <input type="number" id="qty" className="form-control" defaultValue={quantity} min="1" max="10" step="1" data-decimals="0" onChange={(e)=>{increment(e.target.value)}} required/>
                                            </div>{/* End .product-details-quantity */}
                                        </div>{/* End .details-filter-row */}

                                        <div className="product-details-action">
                                            <AddToCart item={product} qty={quantity}/>
                                            <AddToWishlist className="btn-product btn-wishlist" title="Wishlist" item={product} source={"large-product-card"}></AddToWishlist>
                                        </div>{/* End .product-details-action */}

                                        <div className="product-details-footer">
                                            <p>{product.description}</p>
                                        </div>{/* End .product-details-footer */}
                                        
                                    </div>{/* End .product-details */}
                                    
                                </div>{/* End .col-md-6 */}
                                
                            </div>{/* End .row */}
                        </div>{/* End .product-details-top */}


                        <div className="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl" 
                            data-owl-options='{
                                "nav": false, 
                                "dots": true,
                                "margin": 20,
                                "loop": false,
                                "responsive": {
                                    "0": {
                                        "items":1
                                    },
                                    "480": {
                                        "items":2
                                    },
                                    "768": {
                                        "items":3
                                    },
                                    "992": {
                                        "items":4
                                    },
                                    "1200": {
                                        "items":4,
                                        "nav": true,
                                        "dots": false
                                    }
                                }
                            }'>
                            <div className="product product-7 text-center">
                                <figure className="product-media">
                                    <span className="product-label label-new">New</span>
                                    <a href="product.html">
                                        <img src="assets/images/products/product-4.jpg" alt="Product image" className="product-image"/>
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                    </div>{/* End .product-action-vertical */}

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                    </div>{/* End .product-action */}
                                </figure>{/* End .product-media */}

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Women</a>
                                    </div>{/* End .product-cat */}
                                    <h3 className="product-title"><a href="product.html">Brown paperbag waist <br></br>pencil skirt</a></h3>{/* End .product-title */}
                                    <div className="product-price">
                                        $60.00
                                    </div>{/* End .product-price */}
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val"></div>{/* End .ratings-val */}
                                        </div>{/* End .ratings */}
                                        <span className="ratings-text">( 2 Reviews )</span>
                                    </div>{/* End .rating-container */}

                                    <div className="product-nav product-nav-thumbs">
                                        <a href="#" className="active">
                                            <img src="assets/images/products/product-4-thumb.jpg" alt="product desc"/>
                                        </a>
                                        <a href="#">
                                            <img src="assets/images/products/product-4-2-thumb.jpg" alt="product desc"/>
                                        </a>

                                        <a href="#">
                                            <img src="assets/images/products/product-4-3-thumb.jpg" alt="product desc"/>
                                        </a>
                                    </div>{/* End .product-nav */}
                                </div>{/* End .product-body */}
                            </div>{/* End .product */}

                            <div className="product product-7 text-center">
                                <figure className="product-media">
                                    <span className="product-label label-out">Out of Stock</span>
                                    <a href="product.html">
                                        <img src="assets/images/products/product-6.jpg" alt="Product image" className="product-image"/>
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                    </div>{/* End .product-action-vertical */}

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                    </div>{/* End .product-action */}
                                </figure>{/* End .product-media */}

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Jackets</a>
                                    </div>{/* End .product-cat */}
                                    <h3 className="product-title"><a href="product.html">Khaki utility boiler jumpsuit</a></h3>{/* End .product-title */}
                                    <div className="product-price">
                                        <span className="out-price">$120.00</span>
                                    </div>{/* End .product-price */}
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val"></div>{/* End .ratings-val */}
                                        </div>{/* End .ratings */}
                                        <span className="ratings-text">( 6 Reviews )</span>
                                    </div>{/* End .rating-container */}
                                </div>{/* End .product-body */}
                            </div>{/* End .product */}

                            <div className="product product-7 text-center">
                                <figure className="product-media">
                                    <span className="product-label label-top">Top</span>
                                    <a href="product.html">
                                        <img src="assets/images/products/product-11.jpg" alt="Product image" className="product-image"/>
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                    </div>{/* End .product-action-vertical */}

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                    </div>{/* End .product-action */}
                                </figure>{/* End .product-media */}

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Shoes</a>
                                    </div>{/* End .product-cat */}
                                    <h3 className="product-title"><a href="product.html">Light brown studded Wide fit wedges</a></h3>{/* End .product-title */}
                                    <div className="product-price">
                                        $110.00
                                    </div>{/* End .product-price */}
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val"></div>{/* End .ratings-val */}
                                        </div>{/* End .ratings */}
                                        <span className="ratings-text">( 1 Reviews )</span>
                                    </div>{/* End .rating-container */}

                                    <div className="product-nav product-nav-thumbs">
                                        <a href="#" className="active">
                                            <img src="assets/images/products/product-11-thumb.jpg" alt="product desc"/>
                                        </a>
                                        <a href="#">
                                            <img src="assets/images/products/product-11-2-thumb.jpg" alt="product desc"/>
                                        </a>

                                        <a href="#">
                                            <img src="assets/images/products/product-11-3-thumb.jpg" alt="product desc"/>
                                        </a>
                                    </div>{/* End .product-nav */}
                                </div>{/* End .product-body */}
                            </div>{/* End .product */}

                            <div className="product product-7 text-center">
                                <figure className="product-media">
                                    <a href="product.html">
                                        <img src="assets/images/products/product-10.jpg" alt="Product image" className="product-image"/>
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                    </div>{/* End .product-action-vertical */}

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                    </div>{/* End .product-action */}
                                </figure>{/* End .product-media */}

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Jumpers</a>
                                    </div>{/* End .product-cat */}
                                    <h3 className="product-title"><a href="product.html">Yellow button front tea top</a></h3>{/* End .product-title */}
                                    <div className="product-price">
                                        $56.00
                                    </div>{/* End .product-price */}
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val"></div>{/* End .ratings-val */}
                                        </div>{/* End .ratings */}
                                        <span className="ratings-text">( 0 Reviews )</span>
                                    </div>{/* End .rating-container */}
                                </div>{/* End .product-body */}
                            </div>{/* End .product */}

                            <div className="product product-7 text-center">
                                <figure className="product-media">
                                    <a href="product.html">
                                        <img src="assets/images/products/product-7.jpg" alt="Product image" className="product-image"/>
                                    </a>

                                    <div className="product-action-vertical">
                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                        <a href="popup/quickView.html" className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
                                        <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
                                    </div>{/* End .product-action-vertical */}

                                    <div className="product-action">
                                        <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                    </div>{/* End .product-action */}
                                </figure>{/* End .product-media */}

                                <div className="product-body">
                                    <div className="product-cat">
                                        <a href="#">Jeans</a>
                                    </div>{/* End .product-cat */}
                                    <h3 className="product-title"><a href="product.html">Blue utility pinafore denim dress</a></h3>{/* End .product-title */}
                                    <div className="product-price">
                                        $76.00
                                    </div>{/* End .product-price */}
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val"></div>{/* End .ratings-val */}
                                        </div>{/* End .ratings */}
                                        <span className="ratings-text">( 2 Reviews )</span>
                                    </div>{/* End .rating-container */}
                                </div>{/* End .product-body */}
                            </div>{/* End .product */}
                        </div>{/* End .owl-carousel */}
                    </div>{/* End .container */}
                </div>
        </>
    );
}


export default LargeProductCard;