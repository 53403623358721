import { useParams } from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

function ShippingDelivery(props){
    let {id} = useParams();
    // useEffect(()=>{console.log("Updated",id)},[id])
    return(<>
        <Header/>
            <section className="container">
                <div className="row">
                    <div className="col-md-12 mb-4 mt-4 text-left">
                    <h2 className="mb-4 text-center">Delivery Policy</h2>
                    <p>
                        At HTC MART, we currently provide delivery services to specific areas within our designated service
                        regions. Please note that delivery outside of these standard zones may be subject to additional
                        charges or, in some cases, may not be available.
                    </p>
                    <p>
                        To ensure a smooth delivery process, it is important that the delivery address provided is accurate
                        and complete. Any delays or failed deliveries resulting from incorrect or incomplete addresses will
                        not be the responsibility of HTC MART.
                    </p>
                    <p>
                        In the event that we are unable to deliver your order after multiple attempts due to unavailability at
                        the provided address, the groceries will be returned to our store. In such cases, re-delivery may incur
                        additional charges. We kindly request that someone is available to receive the order during your
                        selected delivery time slot.
                    </p>
                    <h4 className="mt-4">Quality Control</h4>
                    <p>
                        We take great care in delivering fresh and high-quality groceries. If any perishable items do not meet
                        your expectations, please inform us immediately, and we will arrange for a replacement or refund.
                    </p>
                    <h4 className="mt-4">Order Fulfillment</h4>
                    <p>
                        For larger orders, some items may be delivered in separate shipments. We will notify you in advance
                        if any items are delayed or unavailable.
                    </p>
                    <h4 className="mt-4">Missing or Damaged Items</h4>
                    <p>
                        If you receive any damaged or missing items in your delivery, please report it within 24 hours of
                        receipt. Our team will assist you in arranging for a replacement or refund.
                    </p>
                    <p>
                        Please note that we currently only offer delivery within our local service areas. International shipping
                        is not available at this time.
                    </p>
                    <h4 className="mt-4">Contactless Delivery</h4>
                    <p>
                        For your safety and convenience, we offer contactless delivery. Our delivery staff will place your
                        order at your doorstep and notify you upon arrival. To facilitate this, we request that all payments be
                        made online before delivery.
                    </p>
                    </div>
                </div>
            </section>
        <Footer/>
    </>)
}
export default ShippingDelivery;


