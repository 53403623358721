import { useParams } from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

function Contact(props){
    let {id} = useParams();
    // useEffect(()=>{console.log("Updated",id)},[id])
    return(<>
        <Header/>
            <section className="container">
                <div className="row">
                    <div className="col-md-12 mb-4 mt-4 text-left">
                    <h2 className="mb-4 text-center">Contact HTC Mart</h2>
                    <h4 className="contact-address">
                        <i className="icon-location-pin"></i> 350 Bay Meadows St. Reynoldsburg, OH 43068
                    </h4>
                    <h5 className="contact-time mb-4">
                        <i className="icon-clock"></i> Mon - Fri: 9:00 am - 9:00 pm
                    </h5>

                    <div className="contact-phone">
                        
                        <h3><a href="tel:+919216136074"><i className="icon-phone"></i>+91 9216136074</a></h3>
                        <h3><a href="tel:+918619966885"><i className="icon-phone"></i>+91 8619966885</a></h3>
                    </div>

                    </div>
                </div>
            </section>
        <Footer/>
    </>)
}
export default Contact;


