import { connect, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Checkbox, emphasize, InputLabel, TextField, Typography } from "@mui/material";
import {Buffer} from 'buffer';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { WriteRating } from "../common/ratings";
import { listCategories } from "../../actions/categoryAction";

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import WebClient from "../../client/webclient.client";
import StaticProductImage from '../../assets/images/products/v1.webp'
import SmallCategoryCard from "../categoryComponents/smallCategoryCard";
import { canonicalizeKey, empty } from "../common/helper";
function UpdatePopoverModal({category,isModalOpen,reset}){
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [categoryLabel,setCategoryLabel] = useState(category.categoryLabel);
    const [categoryName,setCategoryName] = useState(category.categoryName);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const user = useSelector(state=>state.user.user)
    
    useEffect(()=>{
        if(categoryLabel&&(!categoryLabel.match(/[a-zA-Z0-9 ]+/g))){
            alert("Category should only contain Alpha numberic characters");
        }
        if(categoryLabel&& categoryLabel.match(/[a-zA-Z0-9 ]+/g)){
            setCategoryName(canonicalizeKey(categoryLabel));
        }

    },[categoryLabel])
    function updateCategory(){
        let updatedCategory = {image:fileData,categoryLabel};
        WebClient.put({ 
            path: '/categories/'+category.categoryName,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: updatedCategory
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    function deleteCategory(){
        
        WebClient.delete({ 
            path: '/categories/'+category.categoryName,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {}
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setDeleteConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
    }
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            {(confirmation)?(<div className="ad-confirmation" >
                <h6>Do you want to update this category?</h6>
                <Button variant="contained"  className="mr-2" color="success" onClick={updateCategory}>Ok</Button>
                <Button variant="outlined" color="error" onClick={(e)=>{setConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
            {(deleteConfirmation)?(<div className="ad-confirmation" >
                <h6>Do you want to permanently delete this category and related sub-categories?</h6>
                <p>This would set all product under category as <b>misc</b> and sub category as <b>na</b></p>
                <Button variant="contained mr-2" color="success" onClick={deleteCategory}>Ok</Button>
                <Button variant="contained" color="error" onClick={(e)=>{setDeleteConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{reset()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-image">
                    <img src={fileData?(`data:${mimeType};base64,${fileData}`):category.image}></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                </div>
                <div className="edit-details">
                <TextField id="category-name" className="mt-2" label="Category name" variant="filled" disabled  value={category.categoryName}/>
                    <TextField id="category-label" className="mt-2" label="Category label" variant="filled" defaultValue={category.categoryLabel} onChange={(e)=>(setCategoryLabel(e.target.value))}/>
                    
                    <div className="admin-popup-footer">
                        <Button variant="contained" className="mr-2" color="success" onClick={(e)=>{setConfirmation(true)}}>Update</Button>
                        <Button variant="outlined" color="error" onClick={(e)=>{setDeleteConfirmation(true)}}>Delete</Button>
                    </div>
                </div>
        </div>
    );
}
function NewPopoverModal({isModalOpen,CloseNewModal}){
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [categoryLabel,setCategoryLabel] = useState();
    const [categoryName,setCategoryName] = useState();
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const user = useSelector(state=>state.user.user);

    useEffect(()=>{
        if(categoryLabel&&(!categoryLabel.match(/[a-zA-Z0-9 ]+/g))){
            alert("Category should only contain Alpha numberic characters");
        }
        if(categoryLabel&&(categoryLabel.match(/[a-zA-Z0-9 ]+/g))){
            setCategoryName(canonicalizeKey(categoryLabel));
        }
    },[categoryLabel])
    
    function createCategory(){
        let category = {categoryName,categoryLabel,image:fileData};   
        WebClient.post({ 
            path: '/categories',
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: category
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            CloseNewModal();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
    }
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            {(confirmation)?(<div className="ad-confirmation">
                <h6>Do you want to add this category?</h6>
                <Button variant="contained"  className="mr-2" onClick={createCategory}>Ok</Button>
                <Button variant="contained"  className="mr-2" color="error" onClick={(e)=>{setConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{CloseNewModal()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-image">
                    <img src={fileData?(`data:${mimeType};base64,${fileData}`):StaticProductImage }></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                </div>
                <div className="edit-details">
                    <TextField id="category-label" className="mt-2" label="Category label" variant="filled" onChange={(e)=>(setCategoryLabel(e.target.value))}/>
                </div>
                <div className="admin-popup-footer">
                        <Button variant="contained" color="success" onClick={(e)=>{setConfirmation(true)}}>Create</Button>
                    </div>
        </div>
    );
}
function AdminCategories({searchTerm,categories,listCategories}){
    const [editPopOver,setEditPopOver] = useState(null);
    const [newPopOver,setNewPopOver] = useState(null);
    const [editCategory,setEditCategory] = useState(null);
    useEffect(()=>{
        if(!empty(searchTerm)){
            listCategories({searchTerm})
        }else{
            listCategories()
        }
    },[searchTerm])
    useEffect(()=>{
        listCategories()
    },[listCategories])
    return (
        <>
        <div className="admin-products ht-productList-page">
            <Button className="add-product mdc-button mdc-button--raised" variant="contained" onClick={(e)=>{setNewPopOver(true)}}>Add category</Button>
            {   <div className="admin-products-group product-list">
                {(categories)?(
                    categories.map(category=>{
                        return(
                            <SmallCategoryCard category={category} setEditCategory={setEditCategory}/>
                        )
                    })
                ):null}
                </div>
            }
            {
                (editCategory)?(<UpdatePopoverModal category={editCategory} isModalOpen={editCategory} reset={()=>{setEditCategory(null)}}></UpdatePopoverModal>):null
            }
            {
                (newPopOver)?(<NewPopoverModal isModalOpen={newPopOver} CloseNewModal={()=>{setNewPopOver(false)}}></NewPopoverModal>):null
            }
        </div>
       
        </>
    );
}
const mapStateToProps = (state) => ({
    categories: state.category.categories
});
  
export default connect(mapStateToProps, { listCategories })(AdminCategories);