
import { useEffect } from "react";

function SmallSubCategoryCard({subCategory,setEditSubCategory}){
    return(
        <div class="subCategory product subCategory-11 text-center" key={"subCategory"+subCategory.subCategoryName} onClick={(e)=>{e.preventDefault();setEditSubCategory(subCategory);}}>
            
            <div class="subCategory-body">
                <h5>{subCategory.subCategoryLabel}</h5>
                <h3 className="product-title">{subCategory.subCategoryName}</h3>
                <h5>{subCategory.category}</h5>

            </div>{/* End .subCategory-body */}
            {/* <AddToCart item={subCategory}/> */}
        </div>
    )
}
export default SmallSubCategoryCard;