import { useSelector } from "react-redux";
import WebClient from "../client/webclient.client";

export const addToWishlist = (item) => {
    return async (dispatch)=>{
        dispatch({type: 'ADD_TO_WISHLIST',
        payload: {item}});
    };
  };
export const setWishlistToAccount = (user,wishlist) => {
  return async (dispatch)=>{
    try{
      let response = await WebClient.post({path:`/wishlists/user/${user.phoneNumber}`,headers:{authorization:`Bearer ${user.token}`},parameters:{},body:wishlist})
    }catch(err){
      dispatch({ type: 'SET_WISHLIST', payload: {items:[]} });
    }
  };
};
export const setWishlistToLocal = (user) => {
  return async (dispatch)=>{
    try{
      let response = await WebClient.get({path:`/wishlists/user/${user.phoneNumber}`,headers:{authorization:`Bearer ${user.token}`},parameters:{}})
      if(response.data.Items.length>0){      
        dispatch({ type: 'SYNC_WISHLIST', payload: response.data.Items[0].wishlist});
      }
    }catch(err){
      alert(err.message);
      console.log(err);
      dispatch({ type: 'SET_WISHLIST', payload: {items:[]} });
    }
  };
};
  
  export const removeFromWishlist = (item) => {
    return async (dispatch) => {
      dispatch({
        type: 'REMOVE_FROM_WISHLIST',
        payload: item
      });
    };
  };
  export const resetWishlist = (user) => {

    return async (dispatch) => {
      try{
        let response = await WebClient.post({path:`/wishlists/user/${user.phoneNumber}`,headers:{authoeization:`Bearer ${user.token}`},parameters:{},body:{items:[]}})
        console.log("Set wishlist",response);
        dispatch({
          type: 'RESET_WISHLIST'
        });
      }catch(err){
        dispatch({
          type: 'RESET_WISHLIST'
        });
      }
    };
  };
  