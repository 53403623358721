import WebClient from "../client/webclient.client";

export const setSubCategories = (categories) => {
    return {
      type: 'SET_SUB_CATEGORIES',
      payload: categories
    };
  };
  export const listSubCategories = (filter={}) => {
    return async (dispatch) => {
      //dispatch({ type: 'LIST_SUB_CATEGORIES',payload:[]});
      try {
        const response = await WebClient.get({
          path:'/sub_categories',
          parameters:filter
        });
        const data = await response.data;
        console.log("sub category",data.Items)
        dispatch({ type: 'LIST_SUB_CATEGORIES', payload: data.Items });
      } catch (error) {
        console.log('Failed',error)
        dispatch({ type: 'LIST_SUB_CATEGORIES', payload: error });
      }
    }
  };
  