const initialState = {
  products: []
  };
  
  const featuredProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LIST_FEATURED_PRODUCTS_START':
          return {
            ...state,
            products:[...action.payload]
          };
      case 'LIST_FEATURED_PRODUCTS_LOADING':
        return {
          ...state,
          products:[...action.payload]
        };
        case 'LIST_FEATURED_PRODUCTS_COMPLETED':
          return {
            ...state,
            products:[...action.payload]
          };
      default:
        return state;
    }
  };
  
  export default featuredProductReducer;
  