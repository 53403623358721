import WebClient from "../client/webclient.client";

  

  export const listFeaturedProducts = (products) => {
    
    return async (dispatch) => {
      dispatch({ type: 'LIST_FEATURED_PRODUCTS_START',payload:[]});
      try {
        const response = await WebClient.get({
          path:'/products',
          parameters:{isFeatured:true}
        })
        let data = await response.data;
        data.Items=data.Items.map(item=>{
          item.updatedAt=item.updatedAt?item.updatedAt:0;
          return item;
        })
        if(!data.hasOwnProperty('LastEvaluatedKey')){
          dispatch({ type: 'LIST_FEATURED_PRODUCTS_LOADING', payload: data.Items });
        }
        while(data.hasOwnProperty('LastEvaluatedKey')){
          let  response = await WebClient.get({
            path:'/products',
            parameters:{isFeatured:true,startKey:data.LastEvaluatedKey.id,hasImages:true}
          })
          response = await response.data;

          data.Items = [...data.Items,...response.Items]
          data.Items=data.Items.map(item=>{
            item.updatedAt=item.updatedAt?item.updatedAt:0;
            return item;
          })
          if(response.hasOwnProperty('LastEvaluatedKey')){
            data.LastEvaluatedKey=response.LastEvaluatedKey;
          }else{
            delete data.LastEvaluatedKey;
          }
        }
        let sortedData = [...data.Items];
        sortedData.sort((a, b) => b.updatedAt - a.updatedAt);
        console.log("Sorted data",sortedData);
        dispatch({ type: 'LIST_FEATURED_PRODUCTS_COMPLETED', payload: sortedData });
      } catch (error) {
        console.log('Failed',error)
        dispatch({ type: 'LIST_FEATURED_PRODUCTS_COMPLETED', payload: error });
      }
    }
  };