const initialState = {
  products: []
  };
  
  const trendingSellingProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LIST_TRENDING_SELLS_START':
          return {
            ...state,
            products:[...action.payload]
          };
      case 'LIST_TRENDING_SELLS_LOADING':
        return {
          ...state,
          products:[...action.payload]
        };
        case 'LIST_TRENDING_SELLS_COMPLETED':
          return {
            ...state,
            products:[...action.payload]
          };
      default:
        return state;
    }
  };
  
  export default trendingSellingProductReducer;
  