import { connect } from "react-redux";
import { listProducts, loadMoreProducts } from "../actions/productAction";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {ReadRating} from "../components/common/ratings";
import AddToCart from "../components/common/addToCartButton";
import { fixName } from "../components/common/helper";
import { colors } from "@mui/material";
import Header from "../components/common/header";
import FilterCard from "../components/common/filterCard";
import Footer from "../components/common/footer";
import { listSubCategories } from "../actions/subCategoryAction";
import { listCategories } from "../actions/categoryAction";
import Wishlist from "../components/common/wishlist";
import AddToWishlist from "../components/common/addToWishlistButton";
import Banner from "../components/common/banner";

function ProductsList({products,nextPage,subCategories,categories,listCategories,listSubCategories,listProducts,loadMoreProducts}){
    const [searchParams, setSearchParams] = useSearchParams();
    const [categoryFilter,setCategoryFilter] = useState(null);
    const [subCategoryFilter,setSubCategoryFilter]=useState(null);
    const [lowPriceFilter,setLowPriceFilter]=useState(0);
    const [highPriceFilter,setHighPriceFilter]=useState(10000);
    const [baseFilter,setBaseFilter] = useState({});
    const navigate = useNavigate();
    useEffect(()=>{
        let isHotDealData = searchParams.get('isHotDeal');
        let isPromotionsData = searchParams.get('isPromotions');
        let searchTermData = searchParams.get('q');
        let categoryData = searchParams.get('category');
        setBaseFilter({})
        if(isHotDealData){
            setBaseFilter({isHotDeal:true});
        }if(isPromotionsData){
            setBaseFilter({isPromotions:true});
        }if(searchTermData){
            setBaseFilter({...baseFilter,searchTerm:searchTermData});
        }
        if(categoryData){
            setCategoryFilter(categoryData);
        }
        
        listProducts({...baseFilter});
    },[searchParams])
    useEffect(()=>{
        console.log("bf",baseFilter)
        listProducts({...baseFilter});
    },[baseFilter]) 
    const addCategoryFilter = useCallback((value) => {
        setCategoryFilter(value);
      }, [categoryFilter]);
      const addSubCategoryFilter = useCallback((value) => {
        setSubCategoryFilter(value);
      }, [subCategoryFilter]);
    const changeLowPriceFilter = useCallback((value)=>{
        setLowPriceFilter(value);
    })
    const changeHighPriceFilter = useCallback((value)=>{
        setHighPriceFilter(value);
    })
    
    
    useEffect(()=>{
        listProducts({...baseFilter});  
    },[listProducts])
    
    useEffect(()=>{
        listSubCategories()
    },[listSubCategories])
    useEffect(()=>{
        loadMoreProducts()
    },[loadMoreProducts])
    useEffect(()=>{
        listCategories()
        console.log("prodlist",categories)
    },[listCategories])
    
    useEffect(()=>{
        console.log("CategoryFilterUpdated",categoryFilter);
        let filter = {...baseFilter,category:categoryFilter}
        
        listProducts(filter);
    },[categoryFilter])
    useEffect(()=>{
        console.log("SubCategoryFilterUpdated",subCategoryFilter);
        let filter = {...baseFilter,subCategoryName:subCategoryFilter,category:categoryFilter}
        
        listProducts(filter);
    },[subCategoryFilter])
    const listMoreProducts = ()=>{
        console.log('called');
        loadMoreProducts({...baseFilter,subCategoryName:subCategoryFilter,category:categoryFilter,startKey:nextPage})
    }
    
  
    console.log("ps",products.length,products,baseFilter)
    return(
        <>
            <Header/>
            <div className="ht-productList-banner">
               <Banner type="product"/>
            </div>
            <div className="ht-productList-page container mt-5">
                
                <FilterCard 
                    subCategories={subCategories}
                    categories={categories}
                    categorFilter={categoryFilter} 
                    addCategoryFilter={addCategoryFilter} 
                    subCategoryFilter={subCategoryFilter} 
                    addSubCategoryFilter={addSubCategoryFilter}
                    lowPriceFilter={lowPriceFilter}
                    changeLowPriceFilter={changeLowPriceFilter}
                    highPriceFilter={highPriceFilter}
                    changeHighPriceFilter={changeHighPriceFilter}
                />
                <div className="product-list">
                    {
                        
                        (products.length>0)?(products.map((product)=>{
                                return(
                                    (product.saleRate>lowPriceFilter&&product.saleRate<highPriceFilter)?(<div className="product product-11 text-center">
                                                    <figure className="product-media">
                                                        <Link to={(product.id)}>
                                                            <img src={product.image} alt="Product image" className="product-image"/>
                                                        </Link>

                                                        <div className="product-action-vertical">
                                                            {/* <a href="#" className="btn-product-icon btn-wishlist"><span>add to wishlist</span></a> */}
                                                            <AddToWishlist item={product}/>
                                                        </div>{/* End .product-action-vertical */}
                                                    </figure>{/* End .product-media */}


                                                    <div className="product-body">
                                                        <h5><Link to={`products?category=${product.category}`}>{()=>{
                                                            try{
                                                                return fixName(product.category)}catch(err){
                                                            console.log("Error",product)
                                                        }}}</Link></h5>
                                                        <h3 className="product-title"><Link to={(product.id)}>{product.productName}</Link></h3>{/* End .product-title */}
                                                        <ReadRating value={product.rating}/>
                                                        <div className="new-price">
                                                            Rs.{product.saleRate}
                                                        </div>{/* End .product-price */}
                                                    </div>{/* End .product-body */}
                                                    <AddToCart item={product}/>
                                                </div>):(<></>)
                                )
                            
                        })):(<></>)
                    }
                <input className="load-more-prods" type="button" hidden={(nextPage!==null&&nextPage!==undefined)?false:true} value={'Load more'} onClick={(e)=>{e.preventDefault();listMoreProducts();}}/>

                </div>
            </div>
            <Footer/>
        </>
    )
}
const mapStateToProps = (state) => ({
    products: state.products.items,
    nextPage: state.products.nextPage,
    subCategories:state.subCategory.categories,
    categories:state.category.categories
  });
  
export default connect(mapStateToProps, { listProducts,listSubCategories,listCategories,loadMoreProducts })(ProductsList);