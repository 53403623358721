import { Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { Box, Button, Chip, FormControl, InputLabel, OutlinedInput, TextField } from "@mui/material";

function Address({addresses, setAddresses}){
  
  const handleChange = (index, event) => {
    const newAddresses = addresses.map((address, addrIndex) => {
        console.log(index,event);
      if (index !== addrIndex){
        if((event.target.name==="isDefault") && (event.target.checked)){
          address.isDefault=false;
        }
        return address;
      }
      return { ...address, [event.target.name]: (event.target.name==="isDefault")?(event.target.checked):(event.target.value) };
    });
    setAddresses(newAddresses);
  };

  const handleAddAddress = () => {
    setAddresses([...addresses, { street: '', city: '', state: '', zip: '' }]);
  };

  const handleRemoveAddress = (index) => {
    setAddresses(addresses.filter((_, addrIndex) => index !== addrIndex));
  };

  return (
    <form>
      {addresses.length>0?(addresses.map((address, index) => (
        <div key={index} className="address">
          <h4>Address {index + 1}</h4>
          <label>Street</label>
          <input
            type="text"
            name="street"
            className='form-control'
            value={address.street}
            placeholder="Street"
            onChange={(e) => handleChange(index, e)}
          />
          <label>City</label>
          <input
            type="text"
            className='form-control'
            name="city"
            value={address.city}
            placeholder="City"
            onChange={(e) => handleChange(index, e)}
          />
          <label>State</label>
          <input
            type="text"
            className='form-control'
            name="state"
            value={address.state}
            placeholder="State"
            onChange={(e) => handleChange(index, e)}
          />

          <label>Country</label>
          <input
            type="text"
            className='form-control'
            name="country"
            value={address.country}
            placeholder="Country"
            onChange={(e) => handleChange(index, e)}
          />
          <label>Zip</label>
          <input
            type="text"
            className='form-control'
            name="zip"
            value={address.zip}
            placeholder="ZIP Code"
            onChange={(e) => handleChange(index, e)}
          />
          <Checkbox labelId="user-address-default" name="isDefault" size="large" checked={address.isDefault} onChange={(e)=>handleChange(index,e)}></Checkbox>
          <label htmlFor="user-address-default">Is default</label>  
          <div>
          <Button type="button" variant="outlined" color='error' className='mb-2' onClick={() => handleRemoveAddress(index)}>Clear Address</Button>
          </div>
        </div>
      ))):(null)}
      <Button variant="outlined" size="large" className="mb-3" type="button" onClick={handleAddAddress}>Add Address</Button>
    </form>
  );
};

export default Address;
