
import StaticUserImage from '../../assets/images/users/default-profile.webp';
function SmallUserCard({user,setEditUser}){
    const userCategory = {
        0:'Global Administrator',
        1:'Manager',
        9:'Customer'
    }
    return(
        <div class="user user-11 text-center" key={"user"+user.phoneNumber} onClick={(e)=>{e.preventDefault();setEditUser(user);}}>
            <figure class="user-media">
                {/* <Link to={'users/'+(user.id)}> */}
                    <img src={user.image || StaticUserImage} alt="User image" class="user-image"/>
                {/* </Link> */}

            </figure>{/* End .user-media */}

            <div class="user-body">
                <h3 class="user-title">{user.userDisplayName}</h3>{/* End .user-title */}
                <h6 class="user-phone">{user.phoneNumber}</h6>{/* End .user-title */}
                <h5 class="user-type">{userCategory[user.userType]}</h5>
            </div>{/* End .user-body */}
            {/* <AddToCart item={user}/> */}
        </div>
    )
}
export default SmallUserCard;