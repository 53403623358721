const initialState = {
    items: [],
    total: 0
  };
  const reduceDuplicates = (items)=>{
    const itemMap = {};
    items.forEach(item => {
        const key = item.id;
        if (itemMap[key]) {
            itemMap[key].qty += 1;
        } else {
            itemMap[key] = { ...item, qty: 1 };
        }
    });
    return Object.values(itemMap);
}
  const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_TO_CART':
        const item = action.payload.item;

            // Check if the item is already in the cart
        const existItem = state.items.find(cartItem => cartItem.id === item.id);

        if (existItem) {
            // If the item exists, increment the quantity
            return {
                ...state,
                items: state.items.map(cartItem =>
                    cartItem.id === existItem.id
                        ? { ...cartItem, qty: cartItem.qty + action.payload.qty }
                        : cartItem
                ),
                total: state.total + item.saleRate
            };
        } else {
            // If the item doesn't exist, add it to the cart with qty 1
            return {
                ...state,
                items: [...state.items, { ...item, qty: action.payload.qty }],
                total: state.total + item.saleRate
            };
        }
      case 'RESET_CART':
        return initialState;
      case 'SET_CART':
        return {
          items:action.payload.items,
          total:action.payload.total
        };
      case 'SYNC_CART':
        const existList=[]
        return {
          items:[...state.items,...action.payload.items].filter(item=>{
            if(existList.includes(item.id)){
              return false
            }else{
              existList.push(item.id);
              return true;
            }
          }),
          total:state.total+action.payload.total
        };
      case 'REMOVE_FROM_CART':
        const updatedItems = (action.payload.qty>1)?(state.items.map(item=>{
          if(item.id === action.payload.id){
            let newObj = Object.assign({},item);
            newObj.qty = newObj.qty-1;
            return newObj;
          }
          return item;
        })):(state.items.filter(item => (
          item.id !== action.payload.id
        )));
        return {
          ...state,
          items: updatedItems,
          total: (updatedItems.length===0)?0:state.total - (action.payload.saleRate || 0)
        };
      default:
        return state;
    }
  };
  
  export default cartReducer;
  