import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import Header from "../components/common/header";

function UnAuthorizedPage(){
    const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(()=>{
            navigate('/');
        },5000);
    },[])
    return(
        <>
        <Header refTo={'/'}/>
        <div className="UnAuthorized">
            <h1>
                UnAuthorized Access
            </h1>
        </div>
        </>
    )
}
export default UnAuthorizedPage;