import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {configureStore} from '@reduxjs/toolkit'
import cartReducer from '../reducers/cartReducer';
import productReducer from '../reducers/productReducer';
import userReducer from '../reducers/userReducer';
import categoryReducer from '../reducers/categoryReducer';
import featuredProductReducer from '../reducers/featuredProductReducer';
import dailyBestProductReducer from '../reducers/dailyBestProductReducer';
import popularCategoryReducer from '../reducers/popularCategoryReducer';
import sessionStorage from 'redux-persist/es/storage/session';
import topSellingProductReducer from '../reducers/topSellingProductReducer';
import trendingSellingProductReducer from '../reducers/trendingSellingProductReducer';
import recentlyAddedProductReducer from '../reducers/recentlyAddedProductReducer';
import subCategoryReducer from '../reducers/subCategoryReducer';
import orderReducer from '../reducers/orderReducer';
import wishlistReducer from '../reducers/wishlistReducer';

// Combine all reducers into a single rootReducer
const persistConfig = {
  key: 'root',
  storage,
  whitelist:["cart","user","order","wishlist"]
};
const appReducer = combineReducers({
  cart: cartReducer,
  products: productReducer,
  user: userReducer,
  category:categoryReducer,
  featuredProducts:featuredProductReducer,
  dailyProducts:dailyBestProductReducer,
  popularCategory:popularCategoryReducer,
  topSells:topSellingProductReducer,
  trendingSells:trendingSellingProductReducer,
  recentlyAdded:recentlyAddedProductReducer,
  subCategory:subCategoryReducer,
  order:orderReducer,
  wishlist:wishlistReducer
});
const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    let newState={};
    newState.cart=Object.assign({},state.cart);
    return appReducer(newState, action)
  }
  return appReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
// Create the Redux store with the rootReducer and apply middleware
const store = configureStore({
  reducer: persistedReducer
});
const persistor = persistStore(store);
export {store,persistor};
