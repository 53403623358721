import { useEffect } from 'react';
import { listTrendingSells } from '../../actions/trendingSellingProductAction';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function TrendingSells({trendingSells,listTrendingSells}){
    useEffect(()=>{
        listTrendingSells();
    },[listTrendingSells]);
    return(
        <div className="ht-top-sells">
            <div className="heading heading-center mb-3">
                <h2 className="title text-left" key="trending-sell-title">Trending Sells</h2>
            </div>
            <div className="product-list">
                {
                    trendingSells.map(product=>{
                        return(
                    <div className="products">
                    <div className="column justify-content-center">
                        <div className="col-12 mb-3">
                            <div className="product ht-f product-11 text-center mb-3">
                                <figure className="product-media small-prods">
                                    <Link to={'products/'+(product.id)}>
                                        <img src={product.image} alt="Product image" className="product-image"/>
                                    </Link>
                                </figure> {/*End .product-media */}

                                <div className="product-body">
                                    <h3 className="product-title"><Link to={'products/'+(product.id)}>{product.productName}</Link></h3> {/*End .product-title */}
                                    <div className="product-price"><span className="new-price">Rs.{product.saleRate}</span><span className="old-price">Rs.{product.mrp}</span></div>
                                </div> {/*End .product-body */}
                                
                            </div> {/*End .product */}
                        </div> {/*End .col-sm-6 col-md-4 col-lg-3 */}

                        
                    </div> {/*End .row */}
                </div>);
                })
                }
            </div> {/*.End .tab-pane */}
        </div>
        
    );
}
const mapStateToProps = (state) => ({
    trendingSells: state.trendingSells.products
  });
  
export default connect(mapStateToProps, { listTrendingSells })(TrendingSells);