import { useParams } from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

function TermsConditions(props){
    let {id} = useParams();
    // useEffect(()=>{console.log("Updated",id)},[id])
    return(<>
        <Header/>
            <section className="container">
                <div className="row">
                    <div className="col-md-12 mb-4 mt-4 text-left">
                    <h2 className="mb-4 text-center">Terms and Conditions</h2>
                    <p className="mb-4">For these Terms and Conditions, the terms "we", "us", and "our" refer to HTC MART, located at Near
                    Reliance Petrol Pump, Main Boranada Road, Jodhpur. The terms "you", "your", "user", and "visitor"
                    apply to any individual or legal entity who visits our website or agrees to purchase through us.
                    </p>
                    <p className="mb-4">The content on this website is subject to change at any time without prior notice.
                    We and any associated third parties do not provide any warranty or assurance regarding the
                    accuracy, timeliness, performance, completeness, or relevance of the information and materials
                    provided on this website for any specific purpose. You understand and agree that the information
                    and materials may contain errors or inaccuracies. We disclaim any responsibility for such inaccuracies
                    or errors to the fullest extent permitted by law.
                    </p>
                    <p className="mb-4">Any use of the information or materials on our website or product pages is done entirely at your own
                    risk, and we will not be held liable for any consequences thereof. You are responsible for ensuring
                    that any products, services, or information available through our website or product pages meet your
                    specific needs and requirements.
                    </p>
                    <p className="mb-4">The content of our website, including design, layout, visuals, and graphics, is owned by or licensed to
                    us. This material is protected and may not be reproduced except by the copyright terms outlined in
                    these Terms and Conditions.
                    </p>
                    <p className="mb-4">Any trademarks displayed on our website that are not owned or licensed to the operator are duly
                    acknowledged.
                    </p>
                    <p className="mb-4">Unauthorized use of any information provided by us may result in a claim for damages or could
                    constitute a criminal offence.
                    </p>
                    <p className="mb-4">Occasionally, our website may include links to third-party websites for additional information and
                    convenience.
                    </p>
                    <p className="mb-4">You are prohibited from creating a link to our website from any other website or document without
                    the prior written permission of HTC MART.
                    </p>
                    <p className="mb-4">Any disputes arising from your use of our website, purchases made, or engagement with us will be
                    governed by the laws of India.
                    </p>
                    <p className="mb-4">We will not be liable for any loss or damage arising directly or indirectly from the refusal of
                    authorization for any transaction due to the cardholder exceeding the pre-set limit agreed upon by
                    us and our acquiring bank.</p>
                    </div>
                </div>
            </section>
        <Footer/>
    </>)
}
export default TermsConditions;


