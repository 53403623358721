import Footer from "../components/common/footer";
import Header from "../components/common/header";

function InvalidPage(){
    return (<>
        <Header/>
        <div>
            <h1>Invalid URL</h1>
        </div>
        <Footer/>
    </>)
}
export default InvalidPage;