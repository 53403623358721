import {  useSelector } from "react-redux";
import {  useEffect, useRef, useState } from "react";
import { Box, Button, Chip, FormControl, InputLabel, OutlinedInput, TextField } from "@mui/material";
import StaticProfile from '../../assets/images/users/default-profile.webp';
import {Buffer} from 'buffer';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import WebClient from "../../client/webclient.client";
import { canonicalizeKey, empty, scopeList } from "../common/helper";
import SmallUserCard from "../userComponents/smallUserCard";
import { PhoneInput } from "react-international-phone";
import Address from "../common/address";
function UpdatePopoverModal({user,isModalOpen,reset}){
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [userDisplayName,setUserDisplayName] = useState(user.userDisplayName);
    const [phoneNumber,setPhoneNumber] = useState(user.phoneNumber);
    const [userType,setUserType] = useState(user.userType?.toString()||'9');
    const [firstName,setFirstName] = useState(user.firstName);
    const [lastName,setLastName] = useState(user.lastName);
    const [email,setEmail] = useState(user.email);
    const [sex,setSex] = useState(user.sex);
    const [alternatePhone,setAlternatePhone] = useState(user.alternatePhone);
    const [addresses, setAddresses] = useState(user.addresses||[]);
    const [scope,setScope]=useState(user.scope||[]);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const authorizedUser = useSelector(state=>state.user.user);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };
    useEffect(()=>{
        switch(userType){
            case "0": setScope(scopeList.filter(item=>(item.endsWith('.all'))));break;
            case "1": setScope(scopeList.filter(item=>(!item.endsWith('.all'))));break;
            default: setScope([]);break;
        }
    },[userType])

    function updateUser(){
        let updatedUser = {image:fileData,userDisplayName,phoneNumber,userType:Number(userType),firstName,lastName,email,alternatePhone,addresses,userScope:scope,sex};
        console.log("updated user",updatedUser);
        WebClient.put({ 
            path: '/users/admin/'+phoneNumber.replaceAll(/[^0-9]/g,''),
            headers: { authorization: `Bearer ${authorizedUser.token}` },
            parameters: {},
            body: updatedUser
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    function deleteUser(){
        
        WebClient.delete({ 
            path: '/users/admin/'+phoneNumber.replaceAll(/[^0-9]/g,''),
            headers: { authorization: `Bearer ${authorizedUser.token}` },
            parameters: {}
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setDeleteConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
    }
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            {(confirmation)?(<div className="ad-confirmation" >
                <h6>Do you want to update this user?</h6>
                <Button variant="contained" className="mr-2" onClick={updateUser}>Ok</Button>
                <Button variant="contained" className="mr-2" color="error" onClick={(e)=>{setConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
            {(deleteConfirmation)?(<div className="ad-confirmation" >
                <h6>Do you want to delete this user?</h6>
                <Button variant="contained" className="mr-2" onClick={deleteUser}>Ok</Button>
                <Button variant="contained" className="mr-2" color="error" onClick={(e)=>{setDeleteConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{reset()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-image">
                    <img src={fileData?(`data:${mimeType};base64,${fileData}`):user.image }></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                </div>
                <div className="edit-details">
                    <TextField id="user-display-name" className="mt-2" label="User display name" variant="filled" defaultValue={userDisplayName} onChange={(e)=>(setUserDisplayName(e.target.value))}/>
                    <TextField id="user-first-name" className="mt-2" label="First name" variant="filled" defaultValue={firstName} onChange={(e)=>(setFirstName(e.target.value))}/>
                    <TextField id="user-last-name" className="mt-2" label="Last name" variant="filled" defaultValue={lastName} onChange={(e)=>(setLastName(e.target.value))}/>
                    <InputLabel id="user-phone-label">Phone number</InputLabel>
                    <PhoneInput
                            labelId="user-phone-label"
                            defaultCountry="in"
                            value={phoneNumber}
                            onChange={(number) => setPhoneNumber(number)}
                        />
                    <InputLabel id="user-alternate-phone-label">Alternate Phone number</InputLabel>
                    <PhoneInput
                            labelId="user-alternate-phone-label"
                            defaultCountry="in"
                            value={alternatePhone}
                            onChange={(number) => setAlternatePhone(number)}
                        />
                    <TextField id="user-email-address" className="mt-2" label="User email address" variant="filled" defaultValue={email} onChange={(e)=>(setEmail(e.target.value))}/>
                    <InputLabel id="sex-label">Sex</InputLabel>
                    <Select
                        className="ad-select"
                        labelId="sex-label"
                        id="sex"
                        label="Sex"
                        value={sex}
                        onChange={(e)=>{setSex(e.target.value)}}
                    >
                        
                        <MenuItem value="m">Male</MenuItem>
                        <MenuItem value="f">Female</MenuItem>
                            
                        
                    </Select>
                    <InputLabel id="user-type-label">User type</InputLabel>
                    <Select
                        className="ad-select mb-2"
                        labelId="user-type-label"
                        id="userType"
                        label="User type"
                        value={userType}
                        inputMode="numeric"
                        onChange={(e)=>{setUserType(e.target.value)}}
                    >    
                        <MenuItem value="0">Global Administrator</MenuItem>
                        <MenuItem value="1">Manager</MenuItem>
                        <MenuItem value="9">Customer</MenuItem>
                    </Select>
                    {(userType!=='9')?(<FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-chip-label">Scope</InputLabel>
                        <Select
                        className="ad-select mb-2"
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={scope}
                        onChange={(e)=>{(typeof e.target.value==="string")?setScope(e.target.value.split(',')):setScope(e.target.value)}}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {scopeList.map((name) => (
                            <MenuItem
                            key={name}
                            value={name}
                            >
                            {name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>):(null)}
                    <Address addresses={addresses} setAddresses={setAddresses}></Address>
                    <div className="admin-popup-footer">
                        <Button variant="contained" color="success" className="mr-2" onClick={(e)=>{setConfirmation(true)}}>Update</Button>
                        <Button variant="outlined" color="error" onClick={(e)=>{setDeleteConfirmation(true)}}>Delete</Button>
                    </div>
                </div>
        </div>
    );
}
function NewPopoverModal({isModalOpen,CloseNewModal}){
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [userDisplayName,setUserDisplayName] = useState(null);
    const [phoneNumber,setPhoneNumber] = useState('');
    const [userType,setUserType] = useState('9');
    const [firstName,setFirstName] = useState(null);
    const [lastName,setLastName] = useState(null);
    const [email,setEmail] = useState(null);
    const [alternatePhone,setAlternatePhone] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [confirmation,setConfirmation] = useState(false);

    const [sex,setSex] = useState(null);
    const [scope,setScope]=useState([]);


    const authorizedUser = useSelector(state=>state.user.user);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };
    const scopeList = [
        "products.read","products.all",
        "categories.read","categories.all",
        "subCategories.read","subCategories.all",
        "orders.read","orders.all"
    ];

    
    function createUser(){
        let createuser = {image:fileData,userDisplayName,phoneNumber,userType:Number(userType),firstName,lastName,email,alternatePhone,addresses,userScope:scope,sex};
        WebClient.post({ 
            path: '/users',
            headers: { authorization: `Bearer ${authorizedUser.token}` },
            parameters: {},
            body: createuser
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            CloseNewModal();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
    }
    return(
        <div className={`ht-login-modal admin-user-popover admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            {(confirmation)?(<div className="ad-confirmation">
                {/* <h6>Do you want to add this user?</h6> */}
                <Button  variant="contained" className="mr-2" onClick={createUser}>Ok</Button>
                <Button  variant="contained"  color="error" onClick={(e)=>{setConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{CloseNewModal()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-image">
                    <img src={fileData?(`data:${mimeType};base64,${fileData}`):StaticProfile }></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                </div>
                <div className="edit-details">
                    <TextField id="user-display-name" className="mt-2" label="User display name" variant="filled" defaultValue={userDisplayName} onChange={(e)=>(setUserDisplayName(e.target.value))}/>
                    <TextField id="user-first-name" className="mt-2" label="First name" variant="filled" defaultValue={firstName} onChange={(e)=>(setFirstName(e.target.value))}/>
                    <TextField id="user-last-name" className="mt-2" label="Last name" variant="filled" defaultValue={lastName} onChange={(e)=>(setLastName(e.target.value))}/>
                    <InputLabel id="user-phone-label">Phone number</InputLabel>
                    <PhoneInput
                            labelId="user-phone-label"
                            defaultCountry="in"
                            value={phoneNumber}
                            onChange={(number) => setPhoneNumber(number)}
                        />
                    <InputLabel id="user-alternate-phone-label">Alternate Phone number</InputLabel>
                    <PhoneInput
                            labelId="user-alternate-phone-label"
                            defaultCountry="in"
                            value={alternatePhone}
                            onChange={(number) => setAlternatePhone(number)}
                        />
                    <TextField id="user-email-address" className="mt-2" label="User email address" variant="filled" defaultValue={email} onChange={(e)=>(setEmail(e.target.value))}/>
                    <InputLabel id="sex-label">Sex</InputLabel>
                    <Select
                        className="ad-select"
                        labelId="sex-label"
                        id="sex"
                        label="Sex"
                        value={sex}
                        onChange={(e)=>{setSex(e.target.value)}}
                    >
                        
                        <MenuItem value="m">Male</MenuItem>
                        <MenuItem value="f">Female</MenuItem>
                            
                        
                    </Select>
                    <InputLabel id="user-type-label">User type</InputLabel>
                    <Select
                        className="ad-select mb-2"
                        labelId="user-type-label"
                        id="userType"
                        label="User type"
                        value={userType}
                        inputMode="numeric"
                        onChange={(e)=>{setUserType(e.target.value)}}
                    >    
                        <MenuItem value="0">Global Administrator</MenuItem>
                        <MenuItem value="1">Manager</MenuItem>
                        <MenuItem value="9">Customer</MenuItem>
                    </Select>
                    {(userType!=='9')?(<FormControl sx={{ m: 1, width: 200 }}>
                        <InputLabel id="demo-multiple-chip-label">Scope</InputLabel>
                        <Select
                        className="ad-select mb-2"
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={scope}
                        onChange={(e)=>{(typeof e.target.value==="string")?setScope(e.target.value.split(',')):setScope(e.target.value)}}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {scopeList.map((name) => (
                            <MenuItem
                            key={name}
                            value={name}
                            >
                            {name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>):(null)}
                    <Address addresses={addresses} setAddresses={setAddresses}></Address>
                    
                </div>
                <div className="admin-popup-footer">
                        <Button variant="contained" color="success" className="" onClick={(e)=>{setConfirmation(true)}}>Create</Button>
                    </div>
        </div>
    );
}
function AdminUsers({searchTerm}){
    const [editPopOver,setEditPopOver] = useState(null);
    const [newPopOver,setNewPopOver] = useState(null);
    const [users,setUsers] = useState([]);
    const [user,setEditUser]=useState(null);
    const [userType,setUserType] = useState(9);
    const [params,setParams]=useState({});
    const authorizedUser = useSelector(state=>state.user.user);
    useEffect(()=>{
        WebClient.get({path:'/users',headers:{Authorization:`Bearer ${authorizedUser.token}`},parameters:{userType}}).then(resp=>{
            console.log("User list",resp.data.Items);
            setUsers(resp.data.Items);
        })
    },[]);
    useEffect(()=>{
        WebClient.get({path:'/users',headers:{Authorization:`Bearer ${authorizedUser.token}`},parameters:{userType}}).then(resp=>{
            console.log("User list",resp.data.Items);
            setUsers(resp.data.Items);
        })
    },[userType]);
    useEffect(()=>{
        if(!empty(searchTerm)){
            WebClient.get({path:'/users',parameters:{'searchTerm':searchTerm,userType},headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(resp=>{
                console.log("User list",resp.data.Items);
                setUsers(resp.data.Items);
            })
        }else{
            WebClient.get({path:'/users',parameters:{userType},headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(resp=>{
                console.log("User list",resp.data.Items);
                setUsers(resp.data.Items);
            })
        }
    },[searchTerm]);
    return (
        <>
        <div className="admin-users admin-products ht-productList-page">
                <Button className="add-product mdc-button mdc-button--raised" variant="contained" onClick={(e)=>{setNewPopOver(true)}}>Add users</Button>
                <div className="user-type-dd">
                    <InputLabel id="user-type-label">User type</InputLabel>
                        <Select
                            className="ad-select mb-2"
                            labelId="user-type-label"
                            id="userType"
                            label="User type"
                            value={userType}
                            inputMode="numeric"
                            onChange={(e)=>{setUserType(e.target.value)}}
                        >    
                            <MenuItem value="0">Global Administrator</MenuItem>
                            <MenuItem value="1">Manager</MenuItem>
                            <MenuItem value="9">Customer</MenuItem>
                        </Select>
                </div>
            {   <div className="admin-users-group user-list">
                {(users)?(
                    users.map(useritem=>{
                        return(
                             <SmallUserCard user={useritem} setEditUser={setEditUser}/>
                        )
                    })
                ):null}
                </div>
            }
            {
                (user)?(<UpdatePopoverModal user={user} isModalOpen={user} reset={()=>{setEditUser(null)}}></UpdatePopoverModal>):null
            }
            {
                 (newPopOver)?(<NewPopoverModal isModalOpen={newPopOver} CloseNewModal={()=>{setNewPopOver(false)}}></NewPopoverModal>):null
            }
        </div>
       
        </>
    );
}
  
export default (AdminUsers);