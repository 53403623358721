// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-stack {
  position: relative;
  width: 100px;
  height: 160px; /* Adjust height to accommodate the stack */
  margin: 0 auto;
}

.stacked-image {
  position: absolute;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 5px; /* Adjust padding to match the new size */
  box-sizing: border-box;
  border-radius: 4px; /* Optional: gives a slightly rounded edge */
}`, "",{"version":3,"sources":["webpack://./src/ImageStack.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa,EAAE,2CAA2C;EAC1D,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,wCAAwC;EACxC,uBAAuB;EACvB,YAAY,EAAE,yCAAyC;EACvD,sBAAsB;EACtB,kBAAkB,EAAE,4CAA4C;AAClE","sourcesContent":[".image-stack {\n  position: relative;\n  width: 100px;\n  height: 160px; /* Adjust height to accommodate the stack */\n  margin: 0 auto;\n}\n\n.stacked-image {\n  position: absolute;\n  transition: transform 0.3s ease;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  background-color: white;\n  padding: 5px; /* Adjust padding to match the new size */\n  box-sizing: border-box;\n  border-radius: 4px; /* Optional: gives a slightly rounded edge */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
