

const initialState = {
    order:{}
  };
  const orderReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ORDER':
        return {
          ...state,
          order:action.payload.order,
          step:action.payload.step
        };
      default:
        return state;
    }
  };
  
  export default orderReducer;
  