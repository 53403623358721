import { connect, useSelector } from "react-redux";
import { listProducts, loadMoreProducts } from "../../actions/productAction";
import { useCallback, useEffect, useRef, useState } from "react";
import SmallProductCard from "../productComponents/smallProductCard";
import { Button, Checkbox, Divider, InputLabel, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import {Buffer} from 'buffer';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { WriteRating } from "../common/ratings";
import { listCategories } from "../../actions/categoryAction";
import { listSubCategories } from "../../actions/subCategoryAction";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import WebClient from "../../client/webclient.client";
import StaticProductImage from '../../assets/images/products/v1.webp'
import Admin from "../../Pages/Admin";
import ListOrderCard from "../orderComponents/listOrderCard";
import ListCart from "../orderComponents/listCart";
import ListLineItems from "../orderComponents/listLineItems";
import { statusList } from "../common/helper";
function UpdatePopoverModal({order,isModalOpen,reset}){
    
    const user = useSelector(state=>state.user.user);
    const [orderStatus,setOrderStatus] = useState(statusList[order.orderStatus]);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const statusStep = Object.keys(statusList);
    const statusOptions = [...statusStep];
    statusOptions.splice(0,4);
    const [step,setStep] = useState(statusStep.indexOf(order.orderStatus)+1);
    console.log(order);
    useEffect(()=>{
        setOrderStatus(statusStep[step-1]);
    },[step])
    function updateOrder(){
        let updatedOrder = {orderStatus};
        WebClient.put({ 
            path: '/orders/admin/'+order.id,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: updatedOrder
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    function deleteOrder(){
        
        WebClient.delete({ 
            path: '/orders/admin/'+order.id,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {}
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setDeleteConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    return(
        <div className={`ht-login-modal admin-product-popover orders-modal ${isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>

            {(confirmation)?(<div className="ad-confirmation">
                <h6>Do you want to update this order?</h6>
                <Button onClick={updateOrder} variant="contained" className="mr-3">Ok</Button>
                <Button onClick={(e)=>{setConfirmation(false)}} variant="outlined" color="error">Cancel</Button>
            </div>):(null)}
            {(deleteConfirmation)?(<div className="delete-confirmation" >
                <h6>Do you want to permanently delete this order?</h6>
                <Button onClick={deleteOrder} variant="contained" className="mr-3">Ok</Button>
                <Button onClick={(e)=>{setDeleteConfirmation(false)}} variant="outlined" color="error">Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{reset()}} style={{backgroundColor:'black'}}>+</button>
                
                <div className="edit-details">
                    <div className="order-details">
                        <div>
                            <h3>Order #{order.id}</h3>
                        </div>
                        <div className="status-stepper">
                            <Stepper sx={{ width: '65%' }} className="mb-2" activeStep={step} alternativeLabel>
                                {statusStep.map((key) => (
                                    <Step key={statusStep.indexOf(key)}>
                                    <StepLabel>{statusList[key]}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <TextField name="order-status" variant="filled" label="Order status" select defaultValue={order.orderStatus} onChange={(e)=>{setStep(statusStep.indexOf(e.target.value)+1)}} color="success">
                                {statusOptions.map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {statusList[key]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="customer-details">
                            <h6>Customer details</h6>
                            <Divider/>
                            <p> </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    
                                    <TextField disabled  id="first-name" required className="mt-2" label="First name" variant="filled" value={order.customer?.firstName||''}/>
                                </div>
                                <div className="col-sm-6">
                                    
                                    <TextField disabled  id="last-name" required className="mt-2" label="Last name" variant="filled" value={order.customer?.lastName||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="registered-number" required className="mt-2" label="Registered phone number" variant="filled" value={order.phoneNumber||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="phone-number" required className="mt-2" label="Phone number" variant="filled" value={order.customer?.contactPhoneNumber||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="alternate-phone-number" required className="mt-2" label="Alternate phone number" variant="filled" value={order.customer?.alternatePhoneNumber||''}/>
                                </div>
                            </div>
                        </div>
                        <div className="shipping-address">
                            <h6>Shipping address</h6>
                            <Divider/>
                            <p> </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-street-address" required className="mt-2" label="Street" variant="filled" value={order.shippingAddress?.street||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-city" required className="mt-2" label="City" variant="filled" value={order.shippingAddress?.city||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-state" required className="mt-2" label="State" variant="filled" value={order.shippingAddress?.state||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-country" required className="mt-2" label="Country" variant="filled" value={order.shippingAddress?.country||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-zip" required className="mt-2" label="Zip code" variant="filled" value={order.shippingAddress?.zip||''}/>
                                </div>
                            </div>
                        </div>
                        <div className="billing-address">
                            <h6>Billing address</h6>
                            <Divider/>
                            <p> </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-street-address" required className="mt-2" label="Street" variant="filled" value={order.billingAddress?.street||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-city" required className="mt-2" label="City" variant="filled" value={order.billingAddress?.city||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-state" required className="mt-2" label="State" variant="filled" value={order.billingAddress?.state||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-country" required className="mt-2" label="Country" variant="filled" value={order.billingAddress?.country||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-zip" required className="mt-2" label="Zip code" variant="filled" value={order.billingAddress?.zip||''}/>
                                </div>
                            </div>
                        </div>
                        <div className="cart">
                            <ListLineItems cart={{items:order.lineItems,total:order.totalAmount}} readOnly={true}/>
                        </div>
                        <div className="payments">
                            <h6>Payment status</h6>
                            <Divider/>
                            <p> </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField disabled  id="payment-mode" required className="mt-2" label="Payment method" variant="filled" value={order.payment?.paymentMethod||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="payment-status" required className="mt-2" label="Payment status" variant="filled" value={order.payment?.paymentStatus||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="payment-link-expiry" required className="mt-2" label="Payment link expires at" variant="filled" value={new Date(order.payment?.expiry*1000)||''}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="admin-popup-footer">
                        <Button variant="contained" color="success" className="mr-2" onClick={(e)=>{setConfirmation(true)}}>Update</Button>
                        <Button variant="outlined" color="error" onClick={(e)=>{setDeleteConfirmation(true)}}>Delete</Button>
                    </div>
                </div>
        </div>
    );
}
function NewPopoverModal({isModalOpen,CloseNewModal}){
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [rating,setRating]=useState(0);
    const [productName,setProductName]=useState(null);
    const [description,setDescription] = useState(null);
    const [category,setCategory] = useState(null);
    const [subCategoryName,setSubCategoryName] = useState(null);
    const [mrp,setMrp] = useState(null);
    const [saleRate,setSaleRate] = useState(null);
    const [isFeatured,setIsFeatured] = useState(false);
    const [isDailyBestSell,setIsDailyBestSell] = useState(false);
    const [recentlyAdded,setRecentlyAdded] = useState(true);
    const [topSell,setTopSell] = useState(false);
    const [trendingSell,setTrendingSell] = useState(false);
    const [isHotDeal,setIsHotDeal] = useState(false);
    const [isPromotions,setIsPromotions] = useState(false);
    const [confirmation,setConfirmation] = useState(false);
    const user = useSelector(state=>state.user.user)
    const categories = useSelector(state=>state.category.categories);
    let subCategories = useSelector(state=>state.subCategory.categories);
    useEffect(()=>{
        subCategories=subCategories.filter((a)=>{return (a.categoryName===category)?true:false});
        console.log(subCategories.filter((a)=>((a.categoryName===category)?true:false)),category,subCategoryName);
    },[category]);
    
    function createProduct(){
        let product = {productName,description,category,subCategoryName,mrp,saleRate,rating,image:fileData,isFeatured,recentlyAdded,topSell,trendingSell,isHotDeal,isPromotions,updatedAt:Math.round(Date.now()/1000),isDailyBestSell};
        console.log(product);
        WebClient.post({ 
            path: '/products',
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: product
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            CloseNewModal();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            {(confirmation)?(<div className="ad-confirmation">
                <h6>Do you want to add this product?</h6>
                <Button onClick={createProduct} variant="contained" className="mr-3">Ok</Button>
                <Button onClick={(e)=>{setConfirmation(false)}} variant="outlined" color="error">Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{CloseNewModal()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-image">
                    <img src={fileData?(`data:${mimeType};base64,${fileData}`):StaticProductImage}></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                </div>
                <div className="edit-details">
                    <TextField disabled  id="product-name" required className="mt-2" label="Product name" variant="filled" onChange={(e)=>setProductName(e.target.value)}/>
                    <TextField disabled 
                        id="product-description"
                        name="description"
                        label="Description"
                        multiline
                        rows={4}
                        className="mt-2" 
                        variant="filled"
                        required
                        onChange={(e)=>setDescription(e.target.value)}
                        />
                    {/* <TextField disabled  id="category" className="mt-2" label="Category" variant="filled" defaultValue={product.category} onChange={(e)=>setCategory(e.target.value)}/> */}

                    <div className="ht-f mt-2">
                        <div className="ht-f ht-fc  mr-4">
                        <InputLabel id="category-label" required>Category</InputLabel>
                        <Select
                            className="ad-select"
                            labelId="category-label"
                            id="category"
                            label="Category"
                            onChange={(e)=>{setCategory(e.target.value)}}
                            required
                        >
                            {
                                categories.map(item=>{
                                    return(<MenuItem value={item.categoryName}>{item.categoryLabel}</MenuItem>)
                                })
                            }
                        </Select>
                        </div>
                        <div className="ht-f ht-fc">
                        <InputLabel id="sub-category-label" required>Sub Category</InputLabel>
                        <Select
                            className="ad-select"
                            labelId="sub-category-label"
                            id="subCategoryName"
                            label="Sub Category"
                            onChange={(e)=>{setSubCategoryName(e.target.value)}}
                            required
                        >
                            {
                                subCategories.filter((a)=>((a.categoryName===category)?true:false)).map(item=>{
                                    return(<MenuItem value={item.subCategoryName}>{item.subCategoryLabel}</MenuItem>)
                                })
                            }
                        </Select>
                        </div>
                    </div>
                    <TextField disabled  id="mrp" className="mt-2" label="MRP" variant="filled" type="number" required onChange={(e)=>setMrp(Number(e.target.value))}/>
                    <TextField disabled  id="saleRate" className="mt-2" label="Sale rate" variant="filled" required type="number" onChange={(e)=>setSaleRate(Number(e.target.value))}/>
                    <InputLabel id="rating-label" required>Rating</InputLabel>
                    <WriteRating labelId="rating-label" rating={rating} setRating={setRating}/>
                     
                    <div className="ht-f ht-fw ht-ac">
                        <FormControlLabel control={<Checkbox id="isFeatured"  defaultChecked={isFeatured} onChange={(e,v)=>{setIsFeatured(v)}}/>} label="Is Featured?" />
                        <FormControlLabel control={<Checkbox id="isDailyBestSell"  defaultChecked={isDailyBestSell} onChange={(e,v)=>{setIsDailyBestSell(v)}}/>} label="Is daily best sell?" />
                        <FormControlLabel control={<Checkbox id="recentlyAdded"  defaultChecked={recentlyAdded} onChange={(e,v)=>{setRecentlyAdded(v)}}/>} label="Is recently added?" />
                        <FormControlLabel control={<Checkbox id="topSell"  defaultChecked={topSell} onChange={(e,v)=>{setTopSell(v)}}/>} label="Is top sell?" />
                        <FormControlLabel control={<Checkbox id="trendingSell"  defaultChecked={trendingSell} onChange={(e,v)=>{setTrendingSell(v)}}/>} label="Is trending sell?" />
                        <FormControlLabel control={<Checkbox id="isHotDeal" defaultChecked={isHotDeal} onChange={(e,v)=>{setIsHotDeal(v)}}/>} label="Is hot deal?" />
                        <FormControlLabel control={<Checkbox id="isPromotions" defaultChecked={isPromotions} onChange={(e,v)=>{setIsPromotions(v)}}/>} label="Is promotions?" />
                    </div>
                    <div className="admin-popup-footer">
                        <Button variant="contained" color="success" className="admin-success-btn" onClick={(e)=>{setConfirmation(true)}}>Create</Button>
                    </div>
                </div>
        </div>
    );
}
function AdminOrders({searchTerm}){
    const [popOverOrder,setPopOverOrder] = useState(null);
    const [newOrderPopover,setNewOrderPopover] = useState(null);
    const [orders,setOrders] = useState([])
    const authorizedUser = useSelector(state=>state.user.user);
    useEffect(()=>{
        WebClient.get({path:'/orders',headers:{Authorization:`Bearer ${authorizedUser.token}`},parameters:{searchTerm}}).then(resp=>{
            setOrders(resp.data.Items);
        }).catch(err=>{
            console.log("Error fetching orders");
        })
    },[searchTerm])
    useEffect(()=>{
        WebClient.get({path:'/orders',headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(resp=>{
            setOrders(resp.data.Items);
        }).catch(err=>{
            console.log("Error fetching orders");
        })
    },[])
    return (
        <>
        <div className="admin-orders ht-orderList-page">
            <div className="page-header text-center" >
        		<div className="container">
        			<h1 className="page-title">My Orders</h1>
        		</div>
        	</div>
            {   
                orders.sort((a,b)=>((b.createdAt||0)-(a.createdAt||0))).map(order=>{
                    return(
                        <>
                            <ListOrderCard order={order} click={(e)=>{setPopOverOrder(order)}}/>
                        </>
                    )
                })
            }
            {
                (popOverOrder)?(<UpdatePopoverModal order={popOverOrder} reset={()=>{setPopOverOrder(null)}}></UpdatePopoverModal>):null
            }
            {
                (newOrderPopover)?(<NewPopoverModal isModalOpen={newOrderPopover} CloseNewModal={()=>{setNewOrderPopover(false)}}></NewPopoverModal>):null
            }
        </div>
        {/* <input className="load-more-prods" style={{position:'relative',justifyItems:'center'}} type="button" hidden={(nextPage!==null&&nextPage!==undefined)?false:true} value={'Load more'} onClick={(e)=>{e.preventDefault();listMoreOrders();}}/> */}
        </>
    );
}
  
export default (AdminOrders);