
import DefaultImage from '../../assets/images/products/v1.webp';
import { fixName, statusList } from '../common/helper';
import ImageStack from '../common/imageStack';
function ListOrderCard({order,click}){
    
    return (<>
    
            <div className="order-card ht-f" onClick={click}>
                <figure className="product-image-container">
                        <ImageStack images={order?.lineItems?.map(item=>item.image).splice(0,(order?.lineItems?.length>3)?3:order?.lineItems?.length)||[DefaultImage]}/>
                        {/* <img width="100" src={order?.lineItems[0]?.image||DefaultImage} alt="product" /> */}
                    
                </figure>
                <div className='order-details'>
                    <h3>Order #{order.id}</h3>
                    <h6><b>Status:</b> {statusList[order.orderStatus]}</h6>
                    <h6><b>Customer:</b> {order.customer.firstName} {order.customer.lastName}</h6>
                    <h6><b>Registered mobile:</b> {order.phoneNumber}</h6>
                    <h6><b>Created at:</b> {(new Date(order.createdAt*1000)).toDateString()}</h6>
                </div>
            </div>
    </>)
}
export default ListOrderCard;