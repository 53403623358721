import WebClient from "../client/webclient.client";

export const setUser = (user) => {
    return {
      type: 'SET_USER',
      payload: user
    };
  };
  export const syncUser = (user) => {
    
    return async (dispatch)=>{
      try{
        let data = await WebClient.get({path:'/users/common/'+user.phoneNumber,headers:{Authorization:`Bearer ${user.token}`}});
        console.log("sync",data.data);
        dispatch({
          type: 'SET_USER',
          payload: {...user,...data.data}
        })
      }catch(err){
        console.log("Sync error",err);
      }
  };
  };

  export const removeUser = () => {
    return {
      type: 'SET_USER',
      payload: null
    };
  };
  
  export const resetState = () => {
    return {
      type: 'RESET'
    }
  }
  