import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isArrayEmpty } from "./helper";
import RangeSlider from 'react-range-slider-input';

import 'react-range-slider-input/dist/style.css';

function FilterCard({subCategories,categories,categoryFilter,addCategoryFilter,subCategoryFilter,addSubCategoryFilter,lowPriceFilter,changeLowPriceFilter,highPriceFilter,changeHighPriceFilter}){
    // const categories = useSelector(state=>state.category.categories);
    const [_categories,_setCategories] = useState([]);
    const [_subCategories,_setSubCategories] = useState({});

    useEffect(()=>{
        _setCategories(categories);
    },[categories])
    useEffect(()=>{
        let obj={};
        if(subCategories.length>0){
            subCategories.map(item=>{
                if(!obj.hasOwnProperty(item.categoryName)){
                    obj[item.categoryName]={};
                }
                obj[item.categoryName] = { ...obj[item.categoryName], [item.subCategoryName]: item.subCategoryLabel };
            });
        }
        _setSubCategories({ ...obj });
    }, [subCategories]);
    useEffect(() => {
        if (window.innerWidth <= 800) {
            // If not, exit the function early
            return;
        }
        
        const sidebar = document.getElementsByClassName("ht-product-filter")[0];
        const sidebarContent = document.getElementsByClassName("filter-categ-content")[0];
        const footer = document.getElementsByClassName("footer")[0];
    
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const viewportHeight = window.innerHeight;
            const sidebarTop = sidebar.getBoundingClientRect().top + window.pageYOffset;
            const contentHeight = sidebarContent.getBoundingClientRect().height;
            const footerTop = footer.getBoundingClientRect().top + window.pageYOffset;
    
            if (scrollTop > sidebarTop) {
                if (scrollTop + viewportHeight < footerTop) {
                    // Sidebar is fixed
                    sidebarContent.style.position = "fixed";
                    sidebarContent.style.top = "30px";
                    sidebarContent.style.transform = `translateY(40px)`;
                    
                } else {
                    // Sidebar is scrolling with the footer
                    const translateYValue = footerTop - scrollTop - viewportHeight;
                    sidebarContent.style.position = "fixed";
                    sidebarContent.style.top = "30px";
                    sidebarContent.style.transform = `translateY(${translateYValue}px)`;
                }
            } else {
                // Sidebar is in its initial position
                sidebarContent.style.position = "";
                sidebarContent.style.top = "";
                sidebarContent.style.transform = "";
            }
        };
    
        const handleAccordionToggle = () => {
            // Trigger handleScroll to adjust positions
            handleScroll();
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Observe changes in the accordion to adjust the height dynamically
        const observer = new MutationObserver(handleAccordionToggle);
        observer.observe(sidebarContent, { attributes: true, childList: true, subtree: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
            observer.disconnect();
        };
    }, []);
    
    const toggleFilterContent = () => {
        const filterContent = document.querySelector('.filter-categ-content');
        filterContent.classList.toggle('open');
    
        // Function to check if the click is outside the filter content
        const handleClickOutside = (event) => {
            if (!filterContent.contains(event.target)) {
                filterContent.classList.remove('open');
                document.removeEventListener('click', handleClickOutside);
            }
        };
    
        // Only add the event listener if the 'open' class was just added
        if (filterContent.classList.contains('open')) {
            setTimeout(() => { // Timeout ensures the listener is not immediately invoked by the current click
                document.addEventListener('click', handleClickOutside);
            }, 0);
        } else {
            // If 'open' class was removed, ensure to clean up the event listener
            document.removeEventListener('click', handleClickOutside);
        }
    };
    
    return (
        <div className="ht-product-filter">
            <div className="filter-categ-content">
                <div className="filter-categories">
                    <h5 className="filter-title">Product Filter</h5>
                    <div className="filter-category-container">

                        {
                            (isArrayEmpty(_categories))?(<></>):(_categories.map(category=>{
                                return(
                                        <div className="filter-category">
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    className={(category.categoryName === categoryFilter) ? "CategoryFilter active" : "CategoryFilter"}
                                                    id={category.categoryName}
                                                    onClick={(e) => { e.preventDefault(); addCategoryFilter(category.categoryName); }}
                                                >
                                                    {category.categoryLabel}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <ul>
                                                    {
                                                        ( _subCategories[category.categoryName] && Object.keys(_subCategories[category.categoryName]))?Object.keys(_subCategories[category.categoryName]).map(key=>{
                                                                return(
                                                                    <li class={(key==subCategoryFilter)?("SubCategoryFilter active"):("SubCategoryFilter")} id={key} onClick={(e)=>{e.preventDefault();addSubCategoryFilter(key);addCategoryFilter(category.categoryName);}}>
                                                                        {_subCategories[category.categoryName][key]}
                                                                    </li>
                                                                )
                                                            }):(<></>)
                                                    }
                                                    </ul>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                )
                            }))
                        }
                    </div>

                </div>
                <div className="filter-categories">
                    <h5 className="filter-title">Price Range</h5>
                    <div className="range-slider-blk">
                        <RangeSlider min={0} max={10000} step={100} value={[lowPriceFilter, highPriceFilter]} onInput={(e) => {
                            changeLowPriceFilter(e[0]);
                            changeHighPriceFilter(e[1]);
                        }} />
                        <span className="go-btn">Go</span>
                    </div>
                    <span className="range-text">Price: {lowPriceFilter} - {highPriceFilter}</span>
                    <span className="reset-filter" onClick={(e) => { e.preventDefault(); addCategoryFilter(null); addSubCategoryFilter(null); }}>
                        Clear filter
                    </span>
                </div>
            </div>
            <button className=" btn-primary btn-rounded filter-mobile" onClick={toggleFilterContent}>
            <svg fill="#fff" height="800px" width="800px" version="1.1" id="Layer_1" 
	            viewBox="0 0 300.906 300.906"><g><g><path d="M288.953,0h-277c-5.522,0-10,4.478-10,10v49.531c0,5.522,4.478,10,10,10h12.372l91.378,107.397v113.978
			c0,3.688,2.03,7.076,5.281,8.816c1.479,0.792,3.101,1.184,4.718,1.184c1.94,0,3.875-0.564,5.548-1.68l49.5-33
			c2.782-1.854,4.453-4.977,4.453-8.32v-80.978l91.378-107.397h12.372c5.522,0,10-4.478,10-10V10C298.953,4.478,294.476,0,288.953,0
			z M167.587,166.77c-1.539,1.809-2.384,4.105-2.384,6.48v79.305l-29.5,19.666V173.25c0-2.375-0.845-4.672-2.384-6.48L50.585,69.531
			h199.736L167.587,166.77z M278.953,49.531h-257V20h257V49.531z"/></g></g></svg>
            </button>
        </div>
    );
}

export default FilterCard;
