import { useEffect, useState, React } from 'react';
import logo from '../assets/images/logo.png';
import OTP from 'react-otp-input';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import WebClient from '../client/webclient.client';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../actions/userAction';
import { setCartToAccount, setCartToLocal } from '../actions/cartAction';
import { setWishlistToAccount, setWishlistToLocal } from '../actions/wishlistAction';
import { onEnter } from '../components/common/helper';
import { Checkbox } from '@mui/material';
//import createHmac from 'create-hmac';
import hex from 'crypto-js/enc-hex';
import hmac from 'crypto-js/hmac-sha256';

function Login({isModalOpen,openModal,closeModal,redirectTo="/"}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [otp, setOtp] = useState('');
    const [loginAttempt,setLoginAttempt]=useState(0);
    const [phone, setPhone] = useState('');
    const [isOTP, setIsOTP] = useState(false);
    const [countDown, setCountDown] = useState(0);
    const [resend, setResend] = useState(false);
    const [xContentHash, setXContentHash] = useState(null);
    const [termsCheckin,setTermsCheckin] = useState(false);
    //const [showModal, setShowModal] = useState(true);
    const cart = useSelector(state=>state.cart);
    const wishlist = useSelector(state=>state.wishlist);
    const authorizationUser = useSelector(state=>state.user.user)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        if(authorizationUser){
            navigate('/');
        }
    },[])
    const login = () => {
        if(!termsCheckin){
            alert("Terms and condition not accepted");
            return;
        }
        setResend(false);
        WebClient.post({ path: '/login/initiate', headers: {}, parameters: {}, body: { phoneNumber: phone } }).then(response => {
            setIsOTP(true);
            setCountDown(5);
            setLoginAttempt(loginAttempt+1);
            console.log('authresponse',response,process.env.REACT_APP_ENV==='DEV')
            setXContentHash(response.data.contentHash);

            if(process.env.REACT_APP_ENV==='DEV'){
                window.alert("Development OTP: "+response.data.otp);
            }
            
        }).catch(err=>{
            console.log(err);
            window.alert(err.response.data.error.message);
        });
        
    }
    const relogin = ()=>{
        setResend(false);
        WebClient.post({ path: '/login/initiate', headers: {}, parameters: {attempt:loginAttempt+1}, body: { phoneNumber: phone,attempt:loginAttempt+1 } }).then(response => {
            //setIsOTP(true);
            setCountDown(30);
            setLoginAttempt(loginAttempt+1);
            console.log('authresponse',response,process.env.REACT_APP_ENV==='DEV')
            setXContentHash(response.data.contentHash);

            if(process.env.REACT_APP_ENV==='DEV'){
                window.alert("Development OTP: "+response.data.otp);
            }
            
        }).catch(err=>{
            console.log(err);
            window.alert(err.response.data.error.message);
        });
    }

    const validateLogin = () => {
        WebClient.post({ path: '/login/validate', headers: { authorization: xContentHash }, parameters: {}, body: { phoneNumber: phone, otp } }).then(response => {
            //localStorage.setItem('token',response.token);
            console.log('authresponse', response)
            let saltFree = response.data;
            let salt = saltFree.salt;
            delete saltFree.salt;
            let resalt = hmac(JSON.stringify(saltFree),process.env.REACT_APP_SALTKEY).toString(hex);
            if(salt!==resalt){
                throw new Error("RESP_CORRUPTED");
            }
            dispatch(setUser(response.data));
            dispatch(setCartToLocal(response.data));
            dispatch(setWishlistToLocal(response.data));
            navigate(redirectTo)
        }).catch(err => {
            console.log(err);
            if(err.message==="RESP_CORRUPTED"){
                window.alert(err.message);
            }else{
                window.alert(err.response.data.error.message);
            }
            
        });
    }

    useEffect(() => {
        if (countDown > 0) {
            setTimeout(() => {
                setCountDown(countDown - 1);
            }, 1000)
        } else {
            setResend(true);
            console.log("resend",resend);
        }
    }, [countDown]);

    // const closeModal = () => {
    //     setShowModal(false);
    // }

    // if (!isModalOpen) {
    //     return null;
    // }

    return (
        <div className={`ht-login-modal ${isModalOpen ? 'ht-login-modal-enter' : 'ht-login-modal-exit'}`}>
            <div className='login-left'>
                <button className="close-button" onClick={closeModal}>+</button>
                <a href="/" className="logo mb-4">
                    <img src={logo} alt="HTC Logo" width="100" height="52" />
                </a>
                <h3>Hi Welcome!</h3>
                <h4>Login / Signup</h4>
                {(!isOTP) ? ( 
                    <div className='ht-login-form'>
                        <p>Enter your mobile number to get started</p>
                        <PhoneInput
                            defaultCountry="in"
                            value={phone}
                            onChange={(number) => setPhone(number)}
                            onKeyUp={(e)=>onEnter(e,validateLogin)}
                        />
                        <input type='button' className='btn btn-primary mt-2' name="signin" value="Log in" onClick={login} />
                    </div>) : (
                    <div className='otp-form'>
                        <p>Enter OTP received in {phone}</p>
                        <OTP
                            value={otp}
                            onChange={setOtp}
                            renderInput={(props) => <input {...props} />}
                        />
                        {(!resend) ? (<p>Token resend in {countDown}</p>) : (<p onClick={relogin}>resend</p>)}
                        <input type='button' className='btn btn-primary mt-2' name="sign-via-otp" value="Login" onClick={validateLogin} />
                    </div>)}
                    <span className='login-terms'><Checkbox onChange={(e,v)=>setTermsCheckin(v)}/> By logging in/signing up, I acknowledge that I have read and agree to the Terms and Conditions. I consent to the collection, use, and disclosure of my personal data as described therein.</span>
            </div>
            <div className='login-right'>
                <img src={require('../assets/images/login-img.png')} alt="Login" width="500" height="500" />
            </div>
        </div>
    )
}

export default Login;
