import WebClient from "../client/webclient.client";

  

  export const listDailyBestSells = (category) => {
    return async (dispatch) => {
      //dispatch({ type: 'LIST_DAILY_BEST_SELLS_START',payload:[]});
      try {
        const response = await WebClient.get({
          path:'/products',
          parameters:{isDailyBestSell:true}
        })
        let data = await response.data;
        data.Items=data.Items.map(item=>{
          item.updatedAt=item.updatedAt?item.updatedAt:0;
          return item;
        })
        if(!data.hasOwnProperty('LastEvaluatedKey')){
          dispatch({ type: 'LIST_DAILY_BEST_SELLS_COMPLETED', payload: data.Items });
        }
        while(data.hasOwnProperty('LastEvaluatedKey')){
          let response = await WebClient.get({
            path:'/products',
            parameters:{startKey:data.LastEvaluatedKey.id,isDailyBestSell:true}
          })
          response = await response.data;
          data.Items = [...data.Items,...response.Items];
          data.Items=data.Items.map(item=>{
            item.updatedAt=item.updatedAt?item.updatedAt:0;
            return item;
          })
          if(response.hasOwnProperty('LastEvaluatedKey')){
            data.LastEvaluatedKey=response.LastEvaluatedKey;
          }else{
            delete data.LastEvaluatedKey;
          }
          
        }
        let sortedData = [...data.Items];
        sortedData.sort((a, b) => b.updatedAt - a.updatedAt);
        dispatch({ type: 'LIST_DAILY_BEST_SELLS_COMPLETED', payload: sortedData });
      } catch (error) {
        console.log('Failed',error)
        dispatch({ type: 'LIST_DAILY_BEST_SELLS_COMPLETED', payload: error });
      }
    }
  };