import { connect, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Checkbox, InputLabel, TextField, Typography } from "@mui/material";
import {Buffer} from 'buffer';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { WriteRating } from "../common/ratings";
import { listSubCategories } from "../../actions/subCategoryAction";

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import WebClient from "../../client/webclient.client";
import StaticProductImage from '../../assets/images/products/v1.webp'
import SmallSubCategoryCard from "../subCategoryComponents/smallSubCategoryCard";
import { canonicalizeKey, empty } from "../common/helper";
import { listCategories } from "../../actions/categoryAction";
function UpdatePopoverModal({subCategory,isModalOpen,reset}){
    
    const [subCategoryLabel,setSubCategoryLabel] = useState(subCategory.subCategoryLabel);
    const [subCategoryName,setSubCategoryName] = useState(subCategory.subCategoryName);
    const [category,setCategory] = useState(subCategory.categoryName);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const user = useSelector(state=>state.user.user)
    const categories = useSelector(state=>state.category.categories);
    
    useEffect(()=>{
        if(subCategoryLabel&&(!subCategoryLabel.match(/[a-zA-Z0-9 ]+/g))){
            alert("Sub Category should only contain Alpha numberic characters");
        }
        if(subCategoryLabel&& subCategoryLabel.match(/[a-zA-Z0-9 ]+/g)){
            setSubCategoryName(canonicalizeKey(subCategoryLabel));
        }

    },[subCategoryLabel])
    function updateSubCategory(){
        let updatedSubCategory = {subCategoryLabel};
        WebClient.put({ 
            path: '/sub_categories/'+subCategory.subCategoryName,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: updatedSubCategory
        }).then(response => {
            setConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
    }
    function deleteSubCategory(){
        
        WebClient.delete({ 
            path: '/sub_categories/'+subCategory.subCategoryName,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {}
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setDeleteConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            {(confirmation)?(<div className="ad-confirmation">
                <Button variant="contained" className="mr-2" color="success" onClick={updateSubCategory}>Ok</Button>
                <Button variant="outlined" color="error" onClick={(e)=>{setConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
            {(deleteConfirmation)?(<div className="ad-confirmation">
                <Button variant="contained" className="mr-2" color="success" onClick={deleteSubCategory}>Ok</Button>
                <Button variant="outlined" color="error" onClick={(e)=>{setDeleteConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{reset()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-details">
                <TextField id="sub-category-name" className="mt-2" label="Sub category name" variant="filled" disabled  value={subCategory.subCategoryName}/>
                    <TextField id="sub-category-label" className="mt-2" label="Sub category label" variant="filled" defaultValue={subCategory.subCategoryLabel} onChange={(e)=>(setSubCategoryLabel(e.target.value))}/>
                    <Select
                        className="ad-select mt-2"
                        labelId="category-label"
                        id="category"
                        defaultValue={category}
                        label="Category"
                        readOnly={true}
                    >
                        {
                            categories.map(item=>{
                                return(<MenuItem value={item.categoryName}>{item.categoryLabel}</MenuItem>)
                            })
                        }
                    </Select>  
                    <div className="admin-popup-footer">
                        <Button variant="contained" className="mr-2" color="success" onClick={(e)=>{setConfirmation(true)}}>Update</Button>
                        <Button variant="outlined" color="error" onClick={(e)=>{setDeleteConfirmation(true)}}>Delete</Button>
                    </div>
                </div>
        </div>
    );
}
function NewPopoverModal({isModalOpen,CloseNewModal}){
    
    const [subCategoryLabel,setSubCategoryLabel] = useState(null);
    const [subCategoryName,setSubCategoryName] = useState(null);
    const [category,setCategory] = useState(null);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const user = useSelector(state=>state.user.user);
    const categories = useSelector(state=>state.category.categories); 

    useEffect(()=>{
        if(subCategoryLabel&&(!subCategoryLabel.match(/[a-zA-Z0-9 ]+/g))){
            alert("Category should only contain Alpha numberic characters");
        }
        if(subCategoryLabel&&(subCategoryLabel.match(/[a-zA-Z0-9 ]+/g))){
            setSubCategoryName(canonicalizeKey(subCategoryLabel));
        }
    },[subCategoryLabel])
    
    function createSubCategory(){
        let subCategory = {subCategoryName,subCategoryLabel,categoryName:category};   
        WebClient.post({ 
            path: '/sub_categories',
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: subCategory
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            CloseNewModal();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
    }
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            {(confirmation)?(<div className="confirmation" style={{position:'fixed',overflow:"auto",backgroundColor:"white",width:"40vw",height:"10vh"}}>
                <Button variant="contained" className="mr-2" color="success" onClick={createSubCategory}>Ok</Button>
                <Button variant="outlined" color="error" onClick={(e)=>{setConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{CloseNewModal()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-details">
                    <TextField id="subCategory-label" className="mt-2" label="Sub Category label" variant="filled" onChange={(e)=>(setSubCategoryLabel(e.target.value))}/>
                    <Select
                        className="ad-select mt-2"
                        labelId="category-label"
                        id="category"
                        defaultValue={category}
                        label="Category"
                        onChange={(e)=>{setCategory(e.target.value)}}
                    >
                        {
                            categories.map(item=>{
                                return(<MenuItem value={item.categoryName}>{item.categoryLabel}</MenuItem>)
                            })
                        }
                    </Select>  
                    <div className="admin-popup-footer">
                        <Button variant="contained" color="success" onClick={(e)=>{setConfirmation(true)}}>Create</Button>
                    </div>
                </div>
        </div>
    );
}
function AdminSubCategories({searchTerm,subCategories,categories,listCategories,listSubCategories}){
    const [editPopOver,setEditPopOver] = useState(null);
    const [newPopOver,setNewPopOver] = useState(null);
    const [editSubCategory,setEditSubCategory] = useState(null);
    useEffect(()=>{
        if(!empty(searchTerm))
            listSubCategories({searchTerm})
        else
            listSubCategories()
    },[searchTerm]);
    useEffect(()=>{
        console.log(subCategories);
    },[subCategories]);
    useEffect(()=>{
        listSubCategories()
    },[listSubCategories])
    useEffect(()=>{
        listCategories()
    },[listCategories])
    return (
        <>
        <div className="admin-products ht-productList-page">
            <Button className="add-product mdc-button mdc-button--raised" variant="contained" onClick={(e)=>{setNewPopOver(true)}}>Add Sub Category</Button>
            {   <div className="admin-products-group product-list">
                {(subCategories)?(
                    subCategories.map(subCategory=>{
                        return(
                            <SmallSubCategoryCard subCategory={subCategory} setEditSubCategory={setEditSubCategory}/>
                        )
                    })
                ):null}
                </div>
            }
            {
                (editSubCategory)?(<UpdatePopoverModal subCategory={editSubCategory} isModalOpen={editSubCategory} reset={()=>{setEditSubCategory(null)}}></UpdatePopoverModal>):null
            }
            {
                (newPopOver)?(<NewPopoverModal isModalOpen={newPopOver} CloseNewModal={()=>{setNewPopOver(false)}}></NewPopoverModal>):null
            }
        </div>
       
        </>
    );
}
const mapStateToProps = (state) => ({
    subCategories: state.subCategory.categories,
    categories: state.category.categories
});
  
export default connect(mapStateToProps, { listSubCategories,listCategories })(AdminSubCategories);