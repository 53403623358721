import WebClient from "../client/webclient.client";

export const setCategories = (categories) => {
    return {
      type: 'SET_CATEGORIES',
      payload: categories
    };
  };
  export const listCategories = (filter={}) => {
    return async (dispatch) => {
      dispatch({ type: 'LIST_CATEGORIES',payload:[]});
      try {
        const response = await WebClient.get({
          path:'/categories',
          parameters:filter
        })
        console.log("category",response);
        const data = await response.data;
        // data.Items=data.Items.map(item=>{
        //   item.image = process.env.REACT_APP_BASEAPIURL+item.image;
        //   return item;
        // })
        dispatch({ type: 'LIST_CATEGORIES', payload: data.Items });
      } catch (error) {
        console.log('Failed',error)
        dispatch({ type: 'LIST_CATEGORIES', payload: error });
      }
    }
  };
  