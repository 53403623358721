import {useSelector } from "react-redux";
import {  useEffect, useState } from "react";
import { Divider, Step, StepLabel, Stepper, TextField } from "@mui/material";
import WebClient from "../client/webclient.client";
import ListOrderCard from "../components/orderComponents/listOrderCard";
import ListLineItems from "../components/orderComponents/listLineItems";
import { statusList } from "../components/common/helper";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import { useNavigate, useSearchParams } from "react-router-dom";
function UpdatePopoverModal({order,isModalOpen,reset}){
    
    const user = useSelector(state=>state.user.user);
    const navigate = useNavigate();
    useEffect(()=>{
        if(!user){
            navigate('/');
        }
    },[])
    const [orderStatus,setOrderStatus] = useState(statusList[order.orderStatus]);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const statusStep = Object.keys(statusList);
    const statusOptions = [...statusStep];
    statusOptions.splice(0,4);
    const [step,setStep] = useState(statusStep.indexOf(order.orderStatus)+1);
    console.log(order);
    return(
        <div className={`ht-login-modal admin-product-popover orders-modal ${isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>

                <button className="close-button" onClick={()=>{reset()}} style={{backgroundColor:'black'}}>+</button>
                
                <div className="edit-details">
                    <div className="order-details">
                        <div>
                            <h3 className="mb-4">Order #{order.id}</h3>
                        </div>
                        <div className="status-stepper">
                            <Stepper sx={{ width: '100%' }} className="mb-2" activeStep={step} alternativeLabel>
                                {statusStep.map((key) => (
                                    <Step key={statusStep.indexOf(key)}>
                                    <StepLabel>{statusList[key]}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        <div className="customer-details mb-2">
                            <h6>Customer details</h6>
                            <Divider/>
                            <p> </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    
                                    <TextField disabled  id="first-name" required className="mt-2" label="First name" variant="filled" value={order.customer?.firstName||''}/>
                                </div>
                                <div className="col-sm-6">
                                    
                                    <TextField disabled  id="last-name" required className="mt-2" label="Last name" variant="filled" value={order.customer?.lastName||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="registered-number" required className="mt-2" label="Registered phone number" variant="filled" value={order.phoneNumber||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="phone-number" required className="mt-2" label="Phone number" variant="filled" value={order.customer?.contactPhoneNumber||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="alternate-phone-number" required className="mt-2" label="Alternate phone number" variant="filled" value={order.customer?.alternatePhoneNumber||''}/>
                                </div>
                            </div>
                        </div>
                        <div className="shipping-address mb-2">
                            <h6>Shipping address</h6>
                            <Divider/>
                            <p> </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-street-address" required className="mt-2" label="Street" variant="filled" value={order.shippingAddress?.street||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-city" required className="mt-2" label="City" variant="filled" value={order.shippingAddress?.city||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-state" required className="mt-2" label="State" variant="filled" value={order.shippingAddress?.state||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-country" required className="mt-2" label="Country" variant="filled" value={order.shippingAddress?.country||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="shipping-zip" required className="mt-2" label="Zip code" variant="filled" value={order.shippingAddress?.zip||''}/>
                                </div>
                            </div>
                        </div>
                        <div className="billing-address mb-2">
                            <h6>Billing address</h6>
                            <Divider/>
                            <p> </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-street-address" required className="mt-2" label="Street" variant="filled" value={order.billingAddress?.street||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-city" required className="mt-2" label="City" variant="filled" value={order.billingAddress?.city||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-state" required className="mt-2" label="State" variant="filled" value={order.billingAddress?.state||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-country" required className="mt-2" label="Country" variant="filled" value={order.billingAddress?.country||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="billing-zip" required className="mt-2" label="Zip code" variant="filled" value={order.billingAddress?.zip||''}/>
                                </div>
                            </div>
                        </div>
                        <div className="cart mb-2">
                            <ListLineItems cart={{items:order.lineItems,total:order.totalAmount}} readOnly={true}/>
                        </div>
                        <div className="payments">
                            <h6>Payment status</h6>
                            <Divider/>
                            <p> </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField disabled  id="payment-mode" required className="mt-2" label="Payment method" variant="filled" value={order.payment?.paymentMethod||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="payment-status" required className="mt-2" label="Payment status" variant="filled" value={order.payment?.paymentStatus||''}/>
                                </div>
                                <div className="col-sm-6">
                                    <TextField disabled  id="payment-link-expiry" required className="mt-2" label="Payment link expires at" variant="filled" value={new Date(order.payment?.expiry*1000)||''}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}
function UserOrders({searchTerm}){
    const [popOverOrder,setPopOverOrder] = useState(null);
    const [id,setId] = useState(null);
    const [newOrderPopover,setNewOrderPopover] = useState(null);
    const [orders,setOrders] = useState([])
    const authorizedUser = useSelector(state=>state.user.user);
    const navigate = useNavigate();
    const [searchParams,setSearchParams] = useSearchParams();
    useEffect(()=>{
        if(!authorizedUser){
            navigate('/');
            return;
        }
        if(searchParams.get('id') && orders){
            let id = searchParams.get('id');
            setId(id);
        }
        WebClient.get({path:'/orders/common/'+authorizedUser.phoneNumber,headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(resp=>{
            setOrders(resp.data.Items);
        }).catch(err=>{
            console.log("Error fetching orders");
        })
    },[])
    useEffect(()=>{
        if(orders && id){
            let data = orders.filter(item=>(item.id===id))[0];
            if(data){
                setPopOverOrder(data);
            }
            setId(null);
            searchParams.delete('id');
            setSearchParams(searchParams);
        }
    },[orders])
    return (
        <>
        <Header/>
        <div className="admin-orders ht-orderList-page">
        <div className="page-header text-center" >
        		<div className="container">
        			<h1 className="page-title">My Orders</h1>
        		</div>
        	</div>
            {   
                orders.sort((a,b)=>((b.createdAt||0)-(a.createdAt||0))).map(order=>{
                    return(
                        <>
                            <ListOrderCard order={order} click={(e)=>{setPopOverOrder(order)}}/>
                        </>
                    )
                })
            }
            {
                (popOverOrder)?(<UpdatePopoverModal order={popOverOrder} reset={()=>{setPopOverOrder(null)}}></UpdatePopoverModal>):null
            }
        </div>
        {/* <input className="load-more-prods" style={{position:'relative',justifyItems:'center'}} type="button" hidden={(nextPage!==null&&nextPage!==undefined)?false:true} value={'Load more'} onClick={(e)=>{e.preventDefault();listMoreOrders();}}/> */}
        <Footer/>
        </>
    );
}
  
export default (UserOrders);