import {Tabs,Tab, Snackbar, Alert, Button, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import WebClient from "../../client/webclient.client";
import { useSelector } from "react-redux";
import { Buffer } from "buffer";
import DefaultHomeMainBanner from '../../assets/images/banners/defaultHomeMain.webp';
import DefaultHomeSecondaryBanner from '../../assets/images/banners/defaultHomeMain.webp';
import DefaultHomeFormBanner from '../../assets/images/banners/defaultHomeMain.webp';
import { empty } from "../common/helper";

function UpdatePopoverModal({section,version,isModalOpen,closeModal}){
    console.log({section,version,isModalOpen,closeModal});
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const user = useSelector(state=>state.user.user)
    function generatePath(str){
        let returnVal = str;
        let iterator = str.matchAll(/[A-Z]/g)
        let item = iterator.next()
        while(!item.done){
            returnVal=returnVal.replaceAll(item.value[0],'_'+item.value[0].toLowerCase());
            item=iterator.next();
        }
        return returnVal.substring(1,)
    }

    function updateBanner(){
        let updatedCategory = {image:fileData,version};
        WebClient.post({ 
            path: '/banners/'+generatePath(section)+'/',
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: updatedCategory
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            closeModal();
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    function deleteBanner(){
        WebClient.delete({ 
            path: '/banners/'+generatePath(section)+'/'+version,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {}
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            closeModal();
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
            window.alert(err.response.data.error.message);
        });
       
       
    }
   
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            
                <button className="close-button" onClick={(e)=>{closeModal()}} style={{backgroundColor:'black'}}>+</button>
                {(confirmation)?(<div className="ad-confirmation" >
                <h6>Do you want to update this banner?</h6>
                <Button variant="contained" className="mr-2" color="success" onClick={updateBanner}>Ok</Button>
                <Button variant="outlined" color="error" onClick={(e)=>{setConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
            {(deleteConfirmation)?(<div className="ad-confirmation" >
                <h6>Do you want to permanently delete this order?</h6>
                <Button onClick={deleteBanner} variant="contained" className="mr-3">Ok</Button>
                <Button onClick={(e)=>{setDeleteConfirmation(false)}} variant="outlined" color="error">Cancel</Button>
            </div>):(null)}
                <div className="edit-image">
                    <h4>{version}</h4>
                    <img src={fileData?(`data:${mimeType};base64,${fileData}`):(isModalOpen)}></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                    
                </div>
                <div className="admin-popup-footer">
                    <Button variant="contained" color="success" className="mr-2" onClick={()=>{setConfirmation(true)}}>Update</Button>
                    <Button variant="outlined" color="error" onClick={()=>{setDeleteConfirmation(true)}}>Delete</Button>
                </div>
        </div>
    );
}
function NewPopoverModal({isModalOpen,closeModal}){
    console.log({isModalOpen,closeModal});
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [section,setSection] = useState(null);
    const [sectionObjects,setSectionObjects] = useState({});
    const [version,setVersion] = useState(null);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    
    const user = useSelector(state=>state.user.user)
    const sectionOptions = ["HomeMainBanner","HomeSecondaryBanner","ProductMainBanner"];
    function generatePath(str){
        let returnVal = str;
        let iterator = str.matchAll(/[A-Z]/g) 
        let item = iterator.next()
        while(!item.done){
            returnVal=returnVal.replaceAll(item.value[0],'_'+item.value[0].toLowerCase());
            item=iterator.next();
        }
        return returnVal.substring(1,)
    }
    useEffect(()=>{
        if(section){
            WebClient.get({path:'/banners/'+generatePath(section),headers:{Authorization:`Bearer ${user.token}`}}).then(item=>{
                if(Object.keys(item.data).length <= 0){
                    setVersion('v1');
                }
                else{
                    let latest = Object.keys(item.data).sort((a,b)=>(Number(b.replaceAll(/[^0-9]/g,''))-Number(a.replaceAll(/[^0-9]/g,''))))[0].replaceAll(/[^0-9]/g,'');
                latest = Number(latest)+1;
                setVersion('v'+latest);
                console.log(latest,typeof latest,version);
                setSectionObjects(item.data);
                }
            }).catch(err=>{
                alert("Error getting banner details")
            })
        }
    },[section])
    function createBanner(){
        let updatedCategory = {image:fileData,version};
        if(section==null){
            alert("Section can't be empty");
            return;
        }if(empty(fileData)){
            alert("Image can't be empty");
            return;
        }
        WebClient.post({ 
            path: '/banners/'+generatePath(section)+'/',
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: updatedCategory
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            closeModal();
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
            window.alert(err.response.data.error.message);
        });
       
       
    }
   
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            
                <button className="close-button" onClick={(e)=>{closeModal()}} style={{backgroundColor:'black'}}>+</button>
                {(confirmation)?(<div className="ad-confirmation" >
                <h6>Do you want to create this banner?</h6>
                <Button variant="contained" className="mr-2" onClick={createBanner}>Ok</Button>
                <Button variant="contained" color="error" onClick={(e)=>{setConfirmation(false)}}>Cancel</Button>
            </div>):(null)}
            
                <div className="edit-image edit-details ht-ac">
                    <h4>{section}</h4>
                    <div className="ht-f">
                    <div className="ht-f ht-fc mb-2 mr-5">
                        <InputLabel id="section-label">Section</InputLabel>
                        <Select
                            className="ad-select"
                            labelId="section-label"
                            id="section"
                            defaultValue={section}
                            label="Section"
                            value={section}
                            onChange={(e)=>{setSection(e.target.value)}}
                        >
                            {
                                sectionOptions.map(item=>{
                                    return(<MenuItem value={item}>{item}</MenuItem>)
                                })
                            }
                        </Select>
                        </div>
                        <div className="ht-f ht-fc mb-2">
                    <img     src={fileData?(`data:${mimeType};base64,${fileData}`):((section=="HomeMainBanner")?DefaultHomeMainBanner:(section==="HomeSecondaryBanner"?DefaultHomeSecondaryBanner:DefaultHomeFormBanner))}></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                    </div>
                    </div>
                <div className="admin-popup-footer">
                    <Button variant="contained" color="success" className="mr-2" onClick={()=>{setConfirmation(true)}}>Add</Button>    
                </div>
        </div>
        </div>
    );
}
function AdminBanners({}){
    const [value, setValue] = useState(0);
    const [mainBanner,setMainBanner] = useState({});
    const [productMainBanner,setProductMainBanner] = useState({});
    const [secondaryBanner,setSecondaryBanner] = useState({});
    const [formBanner,setFormBanner] = useState({});
    const [snack,setSnack]=useState(null);
    const [snackType,setSnackType]=useState("error");
    const [editPopOver,setEditPopOver] = useState(null);
    const [editSection,setEditSection] = useState(null);
    const [editVersion,setEditVersion] = useState(null);
    const [newPopOver,setNewPopOver] = useState(null);
    const [newSection,setNewSection] = useState(null);
    const [newVersion,setNewVersion] = useState(null);
    const authorizedUser = useSelector(state=>state.user.user);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(()=>{
        WebClient.get({path:'/banners/home_main_banner',headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(item=>{
            setMainBanner(item.data);
        }).catch(err=>{
            setSnackType("error");
            setSnack(err.response.data.error.message);
        })
        WebClient.get({path:'/banners/home_secondary_banner',headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(item=>{
            setSecondaryBanner(item.data);
        }).catch(err=>{
            setSnackType("error");
            setSnack(err.response.data.error.message);
        })
        WebClient.get({path:'/banners/home_form_banner',headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(item=>{
            setFormBanner(item.data);
        }).catch(err=>{
            setSnackType("error");
            setSnack(err.response.data.error.message);
        })
        WebClient.get({path:'/banners/product_main_banner',headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(item=>{
            setProductMainBanner(item.data);
        }).catch(err=>{
            setSnackType("error");
            setSnack(err.response.data.error.message);
        })
    },[])
    useEffect(()=>{
        setTimeout(()=>{
            setSnack(null);
            setSnackType("error");
        },[5000])
    },[snack])
    return(
        <>
            <div className="admin-banners">
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                >
                <Tab label="Home"/>
                <Tab label="Products"/>
            </Tabs>
            {
                (value==0)?(<>
                    <div className="home-main-banner">
                        <h2>Main Page Banners</h2>
                        <Button variant="contained" color="success" onClick={(e)=>{setNewPopOver(true)}}>Add home banner</Button>
                        <Divider><h4>Home Main Banner</h4></Divider>
                        {
                            (Object.keys(mainBanner))?(Object.keys(mainBanner).map(version=>{
                                return(
                                    <div className={"home-main-banner-"+version}>
                                        <h5>Home Main Banner {version}</h5>
                                        <img src={mainBanner[version]} onClick={()=>{
                                            setEditSection('HomeMainBanner');
                                            setEditPopOver(mainBanner[version]);
                                            setEditVersion(version);
                                        }}/>
                                    </div> 
                                )
                            })):(null)
                        }
                        <Divider variant="middle" component="div" style={{margin:'30px 0'}}/>
                        <Divider><h4>Home Secondary Banner</h4></Divider>
                        {
                            (Object.keys(secondaryBanner))?(Object.keys(secondaryBanner).map(version=>{
                                return(
                                    <div className={"home-secondary-banner-"+version}>
                                        <h5>Home Secondary Banner {version}</h5>
                                        <img src={secondaryBanner[version]} onClick={()=>{
                                            setEditSection('HomeSecondaryBanner');
                                            setEditPopOver(secondaryBanner[version]);
                                            setEditVersion(version);
                                        }}/>
                                    </div> 
                                )
                            })):(null)
                        }
                    </div>
                </>):(null)
            }
            {
                (value==1)?(<>
                    <div className="product-main-banner">
                        <h2>Products Page Banners</h2>
                        <Button variant="contained" color="success" onClick={(e)=>{setNewPopOver(true)}}>Add product banner</Button>
                        <Divider><h3>Product Main Banners</h3></Divider>
                        {
                            (Object.keys(productMainBanner))?(Object.keys(productMainBanner).map(version=>{
                                return(
                                    <div className={"product-main-banner-"+version}>
                                        <h5>Product Main Banner {version}</h5>
                                        <img src={productMainBanner[version]} onClick={()=>{
                                            setEditSection('ProductMainBanner');
                                            setEditPopOver(productMainBanner[version]);
                                            setEditVersion(version);
                                        }}/>
                                    </div> 
                                )
                            })):(null)
                        }
                    </div>
                </>):(null)
            }
            {
                (editPopOver)?( <UpdatePopoverModal version={editVersion} section={editSection} isModalOpen={editPopOver} closeModal={()=>{setEditPopOver(null)}}></UpdatePopoverModal>):(null)
            }
            {
                (newPopOver)?( <NewPopoverModal isModalOpen={newPopOver} closeModal={()=>{setNewPopOver(false)}}></NewPopoverModal>):(null)
            }
            {
                (snack)?(<Alert variant="outlined" severity={snackType}>{snack}</Alert>):(null)
            }
            </div>
        </>
    )
}
export default (AdminBanners);
