import { useSelector } from "react-redux";
import WebClient from "../client/webclient.client";

export const addToCart = (item,qty=1) => {
    return async (dispatch)=>{
        dispatch({type: 'ADD_TO_CART',
        payload: {item,qty}});
    };
  };
export const setCartToAccount = (user,cart) => {
  let updateCart = cart;
  if(!cart || !cart.hasOwnProperty('items')){
    updateCart={items:[],total:0}
  }
  return async (dispatch)=>{
    try{
      let response = await WebClient.post({path:`/carts/user/${user.phoneNumber}`,headers:{authorization:`Bearer ${user.token}`},parameters:{},body:updateCart})
    }catch(err){
      dispatch({ type: 'SET_CART', payload: {items:[],total:0} });
    }
  };
};
export const setCartToLocal = (user) => {
  return async (dispatch)=>{
    try{
      let response = await WebClient.get({path:`/carts/user/${user.phoneNumber}`,headers:{authorization:`Bearer ${user.token}`},parameters:{}})
      if(response.data.Items.length>0){
       if(!response.data.Items[0].cart.hasOwnProperty('items')){
        dispatch({ type: 'SET_CART', payload: {items:[],total:0} });
      }else if(response.data.Items[0].cart?.items.length==0)
        {
        }else{
        dispatch({ type: 'SET_CART', payload: response.data.Items[0].cart});
      }
      }
    }catch(err){
      alert(err.message);
      console.log(err);
      dispatch({ type: 'SET_CART', payload: {items:[],total:0} });
    }
  };
};
  
  export const removeFromCart = (item) => {
    return async (dispatch) => {
      dispatch({
        type: 'REMOVE_FROM_CART',
        payload: item
      });
    };
  };
  export const resetCart = (user) => {

    return async (dispatch) => {
      try{
        let response = await WebClient.post({path:`/carts/user/${user.phoneNumber}`,headers:{authoeization:`Bearer ${user.token}`},parameters:{},body:{items:[],total:0}})
        console.log("Set cart",response);
        dispatch({
          type: 'RESET_CART'
        });
      }catch(err){
        dispatch({
          type: 'RESET_CART'
        });
      }
    };
  };
  