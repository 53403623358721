import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../actions/cartAction";
import { Link } from 'react-router-dom';
import { useEffect } from "react";

function Cart() {
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const handleRemoveItem = (item) => {
    dispatch(removeFromCart(item));
  }

  return (
    <div className="dropdown cart-dropdown">
      <a href="#" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
        <div className="icon">
          <i className="icon-shopping-cart"></i>
          <span className="cart-count">{cart.items.length}</span>
        </div>
        <p>Cart</p>
      </a>

      <div className="dropdown-menu dropdown-menu-right">
        <div className="dropdown-cart-products">
          {
            cart.items.map(item => {
              return (
                <div className="product" key={item.id}>
                  <div className="product-cart-details">
                    <h4 className="product-title">
                      <Link to={'/' + item.id}>{item.productName}</Link>
                    </h4>

                    <span className="cart-product-info">
                      <span className="cart-product-qty">{(item.qty)?(item.qty):1}</span>
                      x Rs.{item.saleRate}
                    </span>
                  </div>

                  <figure className="product-image-container">
                    <Link to={'/' + item.id} className="product-image">
                      <img src={item.image} alt="product" />
                    </Link>
                  </figure>
                  <button className="btn-remove" onClick={(e) => { e.preventDefault(); handleRemoveItem(item); }} title="Remove Product"><i className="icon-close"></i></button>
                </div>
              )
            })
          }
        </div>

        <div className="dropdown-cart-total">
          <span>Total</span>
          <span className="cart-total-price">{cart.total}</span>
        </div>

        <div className="dropdown-cart-action">
          {/* <Link to="/cart" className="btn btn-primary">View Cart</Link> */}
          {(cart.items && cart.items.length>0)?(<Link to="/checkout" className="btn btn-outline-primary-2">
            <span>Checkout</span><i className="icon-long-arrow-right"></i>
          </Link>):null}
        </div>
      </div>
    </div>
  )
}

export default Cart;
