import './assets/css/bootstrap.min.css';
import "./assets/css/demos/demo-2.css";
import './assets/css/skins/skin-demo-2.css';
import './assets/css/style.css';
import './assets/css/plugins/jquery.countdown.css';
import './assets/css/plugins/magnific-popup/magnific-popup.css';
import './assets/css/plugins/magnific-popup/magnific-popup.css';
import logo from './logo.svg';
import './App.css';
import Homepage from './Pages/Homepage';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SingleProduct from './Pages/SingleProduct';
import InvalidPage from './Pages/InvalidPage';
import ProductsList from './Pages/ProductsList';
import Login from './Pages/Login';
import Admin from './Pages/Admin';
import PrivacyPolicy from './Pages/Privacy_policy';
import TermsConditions from './Pages/Terms_conditions';
import AboutUs from './Pages/AboutUs';
import Contact from './Pages/Contact';
import CancelRefundPolicy from './Pages/Cancel_refundPolicy';
import ShippingDelivery from './Pages/Shipping_delivery';
import UnAuthorizedPage from './Pages/UnAuthorizedAccess';
import Checkout from './Pages/Checkout';
import ProfilePage from './Pages/Profile';
import Payments from './Pages/Payments';
import Wishlist from './components/common/wishlist';
import UserOrders from './Pages/Orders';
import OrderComplete from './components/orderComponents/orderCompletePage';
import { useCallback } from 'react';
//testing changes
function App() {
  let navigate = useNavigate(); 
  const callBackHome = useCallback(()=>{
    navigate('/');
  })
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Homepage/>}></Route>
        <Route path='/products/:id' element={<SingleProduct/>}></Route>
        <Route path='/products' element={<ProductsList/>}></Route>
        <Route path='/login' element={<Login  isModalOpen={true} closeModal={callBackHome}/>}></Route>
        <Route path='/admin' shouldRevalidate={(currentUrl)=>{
          console.log("adminPage",currentUrl.pathname);
          return (currentUrl.pathname === "/")
        }} element={<Admin/>}></Route>
        <Route path='/unauthorized' element={<UnAuthorizedPage/>}/>
        <Route path='/Checkout' element={<Checkout/>}/>
        <Route path='/Profile' element={<ProfilePage/>}/>
        <Route path='/payments' element={<Payments/>}/>
        <Route path='/wishlist' element={<Wishlist/>}/>
        <Route path='/orders' element={<UserOrders/>}/>
        <Route path='/Privacy_policy' element={<PrivacyPolicy/>}/>
        <Route path='/Terms_conditions' element={<TermsConditions/>}/>
        <Route path='/Cancel_refundPolicy' element={<CancelRefundPolicy/>}/>
        <Route path='/Shipping_delivery' element={<ShippingDelivery/>}/>
        <Route path='/AboutUs' element={<AboutUs/>}/>
        <Route path='/Contact' element={<Contact/>}/>
        <Route path='/order_complete/:id' element={<OrderComplete/>}/>
        <Route path='*' element={<InvalidPage/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
