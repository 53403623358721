import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Address from '../components/common/address';
import Header from '../components/common/header';
import WebClient from '../client/webclient.client';
import { setUser, syncUser } from '../actions/userAction';
import StaticProfile from '../assets/images/users/default-profile.webp';
import {Buffer} from 'buffer';
import Footer from '../components/common/footer';
import { Button, InputAdornment, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const dispatch = useDispatch();
    const authorizedUser = useSelector(state=>state.user.user);
    const navigate = useNavigate();
    useEffect(()=>{
      if(!authorizedUser){
        navigate('/');
      }
    },[authorizedUser]);
    useEffect(()=>{
      if(authorizedUser){
        dispatch(syncUser(authorizedUser));
      }
    },[]);
    const [profile, setProfile] = useState({...authorizedUser,image:undefined});
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleAddressChange = (updatedAddresses) => {
    setProfile({ ...profile, addresses: updatedAddresses });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let updated = {
        firstName:profile.firstName,
        lastName:profile.lastName,
        userDisplayName:profile.userDisplayName,
        email:profile.email,
        alternatePhone:profile.alternatePhone,
        sex:profile.sex,
        addresses:profile.addresses,
        image:profile.image
    }
    WebClient.put({path:'/users/common/'+authorizedUser.phoneNumber,headers:{Authorization:`Bearer ${authorizedUser.token}`},body:updated}).then(resp=>{
        let updatedResp = resp.data.Attributes;
        dispatch(setUser({...authorizedUser,...updatedResp}));
        alert('User updated');
    }).catch(err=>{
        alert(err.message);
    })
  };

  return (
    <>
        <Header/>


        <div className="checkout">
          <div className="container">
            <div className="checkout-discount">
              <h3>My Account</h3>
            </div>
            
            <form onSubmit={handleSubmit}>
              <div className="row">
               <div className="profile-img mb-5">
                <div className='img-blk'>
                  <img src={fileData?(`data:${mimeType};base64,${fileData}`):authorizedUser?.image }></img>
                </div>
            <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
            <input ref={fileInput} type="file" name="image" style={{ display: 'none' }} onChange={(e)=>{
                if(e.target.files.length>0){
                setMimeType(e.target.files[0].type);
                e.target.files[0].arrayBuffer().then(data=>{
                    let file = Buffer.from(data).toString('base64');
                    setFileData(file);
                    setProfile({ ...profile, 'image': file });
                })
                }
            }}/>
        </div>

                <div className="col-lg-8 checkout-left mb-6">
                  <h2 className="checkout-title">Billing Address</h2>
                  <div className="row">
                    <div className="col-sm-6">
                      <label>First Name *</label>
                      <input type="text" className="form-control" name="firstName" value={profile?.firstName} onChange={handleChange} />
                    </div>
                    <div className="col-sm-6">
                      <label>Last Name *</label>
                      <input type="text" className="form-control" name="lastName" value={profile?.lastName} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <label>User Name *</label>
                      <input type="text" className="form-control" name="userDisplayName" value={profile?.userDisplayName} onChange={handleChange} />
                    </div>
                  </div>
                  <label>Email *</label>
                  <input type="email" className="form-control" name="email" value={profile?.email} onChange={handleChange}  />
                  
                  <label>Phone Number *</label>
                  <input type="number" className="form-control" name="phoneNumber" value={profile?.phoneNumber} onChange={handleChange}  />
                  
                  <label>Alternate Phone *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="alternatePhone" 
                    value={profile?.alternatePhone} 
                    onChange={handleChange} 
                  />
                  <label>Sex *</label>
                  <select 
                    name="sex" 
                    className="form-control"
                    value={profile?.sex} 
                    onChange={handleChange}
                  >
                    <option value="m">Male</option>
                    <option value="f">Female</option>
                    <option value="o">Other</option>
                  </select>
                  {<Address addresses={profile?.addresses||[]} setAddresses={handleAddressChange}/>}
                  <div className='ht-f ht-je'>
                    <Button variant="contained" color="success" size="large" type='submit'>Submit</Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer/>
    </>
  );
};

export default ProfilePage;
