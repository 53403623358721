import { useParams } from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

function CancelRefundPolicy(props){
    let {id} = useParams();
    // useEffect(()=>{console.log("Updated",id)},[id])
    return(<>
        <Header/>
            <section className="container">
                <div className="row">
                    <div className="col-md-12 mb-4 mt-4 text-left">
                    <h2 className="mb-4 text-center">Cancellation Policy</h2>
                    <p className="font-weight-bold">HTC MART is committed to assisting its customers to the fullest extent possible, which is why we
have adopted a flexible cancellation policy. According to this policy:</p>
                    <ul className="list-unstyled">
                        <li className="mb-2">Cancellations will be considered only if the request is made within 30 minutes of placing the order
and the customer confirms the cancellation via phone. However, the cancellation request may not be
entertained if the orders have already been communicated to the vendors/merchants and they have
initiated the shipping process.</li>
                        <li className="mb-2">HTC MART does not accept cancellation requests for perishable items. However, a refund or
replacement can be provided if the customer demonstrates that the quality of the delivered product
is not satisfactory.</li>
                        <li className="mb-2">If you receive damaged or defective items, please report the issue by contacting us via the phone
number provided on our website. Your request will be processed only after the merchant has
inspected and confirmed the issue on their end. Please ensure that the damage or defect is reported
within the same day of receiving the product.</li>
                        <li className="mb-2">In case you feel that the product received is not as shown on the site or as per your expectations, you
must bring it to the notice of our customer service within same day of receiving the product. The
Customer Service Team after looking into your complaint will take an appropriate decision.</li>
                        <li className="mb-2">If you receive damaged or defective items, please report the issue by contacting us via the phone
number provided on our website. Your request will be processed only after the merchant has
inspected and confirmed the issue on their end. Please ensure that the damage or defect is reported
within the same day of receiving the product.</li>
                        <li className="mb-2">In the event that a refund is approved by HTC MART, it will take 3-4 business days for the refund to
be processed and credited to the customer.</li>
                    </ul>
                    </div>
                </div>
            </section>
        <Footer/>
    </>)
}
export default CancelRefundPolicy;


