import { useParams } from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

function AboutUs(props){
    let {id} = useParams();
    // useEffect(()=>{console.log("Updated",id)},[id])
    return(<>
        <Header/>
            <section className="container">
                <div className="row">
                    <div className="col-md-12 mb-4 mt-4 text-left">
                    <h2 className="mb-4 text-center">About HTC Mart</h2>
                    <p>Welcome to HTC Mart, your go-to destination for all essential items needed in day-to-day life.</p>
                    <p>Conveniently located at 52-B, Near Reliance Petrol Pump, Pal Road, Nandan Van Nagar,
                    Jodhpur, Rajasthan - 342008, we have been proudly serving the community since our
                    establishment in 2022.</p>
                    <p>Founded by Ms. Manju Sharma, who brings a wealth of experience from the retail service
                    industry, HTC Mart was created to offer a seamless and pleasant shopping experience for
                    everyone. With a focus on quality and customer satisfaction, we ensure that all the necessities
                    you need are readily available under one roof.</p>
                    <p>Whether you're shopping for groceries, household essentials, or other daily items, HTC Mart has
                    got you covered. We are proud to be one of the best grocery marts in the area, known for our
                    wide variety and exceptional service.</p>
                    <p>In our commitment to making your shopping experience even more convenient, HTC Mart is
                    now available online. You can browse our extensive range of products through our e-commerce
                    platform at htcmart.in and enjoy the ease of doorstep delivery, right to your home.</p>
                    <h3 className="mt-5 mb-4">Our Mission</h3>
                    <p>At HTC Mart, our mission is to provide our customers with the highest quality essential products
                    for everyday life, ensuring convenience, affordability, and excellence in service. We are
                    dedicated to becoming the trusted neighborhood grocery mart by consistently offering a wide
                    range of items that cater to the diverse needs of our community. Through our e-commerce
                    platform, we aim to extend this convenience to your doorstep, making shopping seamless and
                    efficient. Guided by our founder’s deep experience in retail, we strive to enhance the shopping
                    experience both in-store and online, prioritizing customer satisfaction in everything we do.</p>
                    <h3 className="mt-5 mb-4">Our Vision</h3>
                    <p>Our vision at HTC Mart is to become Jodhpur's leading grocery and departmental store,
                    recognized for our commitment to quality, variety, and exceptional customer service. We strive
                    to be the first choice for individuals and families, offering a seamless shopping experience both
                    in-store and online. By continuously expanding our product range and enhancing convenience
                    through innovative solutions, we aim to set a new standard in grocery retail, making HTC Mart a
                    trusted name in every household across Jodhpur.</p>
                    </div>
                </div>
            </section>
        <Footer/>
    </>)
}
export default AboutUs;


