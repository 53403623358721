import { useParams } from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

function PrivacyPolicy(props){
    let {id} = useParams();
    // useEffect(()=>{console.log("Updated",id)},[id])
    return(<>
        <Header/>
            <section className="container">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <h2 className="title mt-4 mb-4">PRIVACY POLICY FOR HTC MART</h2>
                        <p className="text-justify">
                            This Privacy Policy is for the use of your data by HTC MART as the sole owner of htcmart.in. As a Visitor of the Site/Customer, please read our Privacy Policy. As a condition of using the Site services, you agree to be bound by this Privacy Policy and our use of your information as described herein.
                        </p>
                        <h3 className="mt-4 mb-2 text-left">What information is, or maybe, collected from you?</h3>
                        <p className="text-justify">
                            HTC MART may collect the following personally identiﬁable information as part of a condition of purchase from you: Name including ﬁrst and last name, email address, mobile phone number and contact details, Postal code, Demographic proﬁle (such as your age, gender, occupation, education, address, etc.), and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page, and any such browsing information.
                        </p>
                        <h3 className="mt-4 mb-2 text-left">How do we collect the Information?</h3>
                        <p className="text-justify">
                            HTC MART will collect personally identiﬁable information about you only as part of a voluntary registration process, on-line survey or any combination thereof. HTC MART is not responsible for the privacy practices of such Web sites which it does not own, manage or control. The Site and third-party vendors, including Google, use ﬁrst-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize, and serve ads based on someone's past visits to the Site.
                        </p>
                        <h3 className="mt-4 mb-2 text-left">How is the information used?</h3>
                        <p className="text-justify">
                            HTC MART will use your personal information to provide personalized features to you on the Site and to provide for promotional offers to you through the Site and other channels. HTC MART will also provide this information to its business associates and partners to get in touch with you when necessary to provide the services requested by you. HTC MART will use this information to preserve transaction history as governed by existing law or policy. HTC MART may also use contact information internally to direct its efforts for product improvement, to contact you as a survey respondent, to notify you if you win any contest; and to send you promotional materials from its contest sponsors or advertisers. HTC MART will also use this information to serve various promotional and advertising materials to you via display advertisements through the Google Ad network on third party websites. You can opt-out of Google Analytics for Display Advertising and customize Google Display Network ads using the Ads Preferences Manager. Information about Customers on an aggregate (excluding any information that may identify you speciﬁcally) covering Customer transaction data and Customer demographic and location data may be provided to partners of HTC MART for the purpose of creating additional features on the website, creating appropriate merchandising or creating new products and services and conducting marketing research and statistical analysis of customer behavior and transactions.
                        </p>
                        <h3 className="mt-4 mb-2 text-left">With whom your information will be shared</h3>
                        <p className="text-justify">
                            HTC MART will conﬁrm that none of your ﬁnancial data is being used other than for transactions. HTC MART does not rent, sell, or share your personal information and will not disclose any of the sensitive information uniquely identiﬁable information to third parties. In instances where this is violated, permission of the customer to provide information about the products and services as well as their related details is requested, which is necessary to be shared with HTC MART's business associates and partners. However, consumer information is shared on an aggregate with its partners or third parties as per the necessity. In addition, HTC MART may use this information for promotional offers, thereby aiding in investigating, preventing, or taking action regarding illegal activities, suspected fraud, potential risks threatening the safety or security of any person, violations of the site's "Terms of use" or to defend against legal claims; special circumstances such as compliance with warrants, court orders, requests/orders from legal authorities, or law enforcement agencies requiring such disclosure.
                        </p>
                        <h3 className="mt-4 mb-2 text-left">How do we protect your information?</h3>
                        <p className="text-justify">
                            HTC MART implements appropriate and fair security measures under its control, in compliance with applicable laws to safeguard consumers against the loss, misuse, or any illegitimate access, damage, or unauthorized alterations. In the process of providing your personnel, as per your product/service requests, required for transaction purposes, HTC MART servers make sure that sanctioned or authorized officials have access. Although HTC MART will endeavor to protect the conﬁdentiality of each consumer's sensitive information, transactions made via means of the Internet cannot be made secure. By using this site, you agree that HTC MART will have no liability in the disclosure of your details due to errors in transmission or unauthorized acts of third parties. By utilizing the services of this site or providing information, you agree that (i) We can communicate with you digitally regarding security, privacy, and administrative issues regarding your use of the site (ii) We can undertake adequate and efficient physical, managerial, and technical safeguards to preserve the integrity and security of your data till the timebound you use the site or the services provided directly or indirectly.
                        </p>
                        <h3 className="mt-4 mb-2 text-left">How can you correct inaccuracies in the information?</h3>
                        <p className="text-justify">
                            To update any information you have provided, online corrections are provided by our team. In the event of loss of access details, you can send an email
                            <a href="mailto:htcmartweb@gmail.com">htcmartweb@gmail.com</a>
                        </p>
                        <h3 className="mt-4 mb-2 text-left">Impacts due to Changes in HTC MART's Business</h3>
                        <p className="text-justify">
                            If HTC MART engages in a sale or consolidation of some or all of its assets or stock, acquisition of a part or the whole of its property, dissolution, bankruptcy, reorganization, ﬁnancing, public offering of securities, a similar transaction or proceeding, or actions in contemplation of such activities (e.g. due diligence), "part or all Other Information" may be transferred as part of the process thereof, subjecting to standard conﬁdentiality practices and following the Indian laws.
                        </p>
                        <h3 className="mt-4 mb-2 text-left">Policy updates</h3>
                        <p className="text-justify">
                            HTC MART reserves the right to change or update this policy at any period. Such changes shall be effective immediately upon posting to the Site.
                        </p>
                    </div>
                </div>
            </section>
        <Footer/>
    </>)
}
export default PrivacyPolicy;


