import Rating from '@mui/material/Rating'
import { useEffect, useState } from 'react';
function ReadRating({value}){
    const [rate,setRate]=useState(value);
    return(
        <div className='read-ratings'>
            <Rating
                name={'read-only'}
                value={rate}
                readOnly={true}
            />
            <span className='rating-value'> ({rate}) </span>
        </div>
    )
}
function WriteRating({rating,required=true,setRating}){
    const rate = rating;
    const updateRating = ((value)=>{
        setRating(value);
    })
    return(
        <div className='read-ratings'>
            <Rating
                name={'read-only'}
                value={rating}
                defaultValue={rating}
                onChange={(event,value)=>{updateRating(value)}}
                required={required}
            />
            <span className='rating-value'> ({rating}) </span>
        </div>
    )
}
export {ReadRating,WriteRating};