import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import { Button, InputAdornment, Step, StepLabel, Stepper, TextField, MobileStepper } from "@mui/material";
import ListCart from "../components/orderComponents/listCart";
import { connect, useDispatch, useSelector } from "react-redux";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PaymentCard from "../components/orderComponents/paymentCard";
import WebClient from "../client/webclient.client";
import { resetOrder, setOrder } from "../actions/orderAction";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { resetCart, setCartToAccount } from "../actions/cartAction";
import Login from "./Login";
import { validateMandatory } from "../components/common/helper";
import { syncUser } from "../actions/userAction";


const Checkout = ({stateOrder}) => {
  const steps = ["cart","addresses","payment","order"];
  const authorizedUser = useSelector(state=>state.user.user);
  const defaultAddress = (authorizedUser?.addresses && authorizedUser?.addresses?.length>0)?(authorizedUser?.addresses?.reduce(item=>(item?.isDefault?item:null))):({});
  
  const [firstName,setFirstName] = useState(stateOrder?.order?.customer?.firstName||authorizedUser?.firstName);
  const [lastName,setLastName] = useState(stateOrder?.order?.customer?.lastName||authorizedUser?.lastName);
  const [email,setEmail] = useState(stateOrder?.order?.customer?.email||authorizedUser?.email);
  const [country,setCountry] = useState(stateOrder?.order?.shippingAddress?.country||defaultAddress?.country);
  const [street,setStreet] = useState(stateOrder?.order?.shippingAddress?.street||defaultAddress?.street);
  const [city,setCity] = useState(stateOrder?.order?.shippingAddress?.city||defaultAddress?.city);
  const [state,setState] = useState(stateOrder?.order?.shippingAddress?.state||defaultAddress?.state);
  const [zip,setZip] = useState(stateOrder?.order?.shippingAddress?.zip||defaultAddress?.zip);
  const [phoneNumber,setPhoneNumber] = useState(stateOrder?.order?.shippingAddress?.phoneNumber||authorizedUser?.phoneNumber);
  const [billingCountry,setBillingCountry] = useState();
  const [billingStreet,setBillingStreet] = useState();
  const [billingCity,setBillingCity] = useState();
  const [billingState,setBillingState] = useState();
  const [billingZip,setBillingZip] = useState();
  const [billingPhoneNumber,setBillingPhoneNumber] = useState();
  const [billToDifferentAddress,setShipToDifferentAddress] = useState(false);
  const [step,setStep]=useState(stateOrder?.step||1);
  const [paymentMethod,setPaymentMethod] = useState("card");
  const [paymentLink,setPaymentLink]=useState(stateOrder?.order?.payment?.paymentLink||null);
  const [paymentStatus,setPaymentStatus] = useState(stateOrder?.order?.payment?.paymentStatus||null);
  const [traversalAllowed,setTraversalAllowed]=useState(true);
  const [hasWindow,setNewWindow] = useState(false);
  const [intervalId,setIntervalId]=useState();
  const dispatch = useDispatch();
  const cart = useSelector(state=>state.cart);
  const navigate = useNavigate();
  const [mandateLogin,setMandateLogin] = useState(false);
  useEffect(()=>{
    if(!cart.items || cart.items.length==0){
      dispatch(resetOrder())
      navigate('/');
    }
    console.log("State order",stateOrder);
    if(!stateOrder?.order?.id && stateOrder?.step > 0){
      dispatch(resetOrder());
      navigate('/');
    }
    if(!authorizedUser){
      dispatch(resetOrder())
      setMandateLogin(true);
    }else{
      setMandateLogin(false);
    }
    
  },[])
  useEffect(()=>{
    if(intervalId){
      localStorage.setItem('intervalId',intervalId);
    }else{
      let temp = localStorage.getItem('intervalId');
      if(temp){
        setIntervalId(intervalId);
      }
    }
  },[intervalId])
  useEffect(()=>{
    if(authorizedUser){
      setMandateLogin(false);
    }
  },[authorizedUser])
  useEffect(()=>{
    if(cart.items.length==0){
      setStep(0);
    }
  },[cart])
  useEffect(()=>{
    
    if(step==0){
      console.log(stateOrder?.order)
      if(authorizedUser){
        if(stateOrder?.order && ['payment_completed','payment_initiated'].includes(stateOrder?.order?.orderStatus)){
          WebClient.put({path:'/orders/common/'+stateOrder.order.id,headers:{Authorization:`Bearer ${authorizedUser.token}`},body:{...stateOrder.order,status:'abandoned'}}).then(resp=>{
            dispatch(resetOrder())
          }).catch(err=>{
            console.log(err.response)
          });
        }else{
          if(stateOrder?.order){
            WebClient.delete({path:'/orders/common/'+stateOrder.order.id,headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(resp=>{
              dispatch(resetOrder())
            }).catch(err=>{
              console.log(err.response)
            });
          }
        }
      }
      navigate('/');

    }
    if(step==2){
      if(stateOrder?.order?.id){
        let order = {
          ...stateOrder.order,
          lineItems:cart.items,
          totalAmount:cart.total,
          phoneNumber:authorizedUser.phoneNumber,
          userDisplayName:authorizedUser.userDisplayName,
          customer:{
            firstName,
            lastName,
            email,
            contactPhoneNumber:phoneNumber
          },
          billingAddress:{
            street:billingStreet,
            city:billingCity,
            state:billingState,
            zip:billingZip,
            country:billingCountry
          },shippingAddress:{
            street,
            city,
            state,
            zip,
            country
          },
          shippingCost:0,
        }
        if(!billToDifferentAddress){
          order["billingAddress"]=order["shippingAddress"]
        }
        let mandatoryItems={firstName,lastName,street,city,state,zip,country};
        if(billToDifferentAddress){
          mandatoryItems={...mandatoryItems,...{
            billingStreet,
            billingCity,
            billingState,
            billingZip,
            billingCountry
          }}
        }
        if(!validateMandatory(mandatoryItems)){
          console.log("mandatory",mandatoryItems);
          alert('Mandatory fields missing')
          setStep(step-1);
          return;
        }else{
          console.log("mandatory",mandatoryItems);
        }
        WebClient.put({path:'/orders/common/'+stateOrder.order.id,headers:{Authorization:`Bearer ${authorizedUser.token}`},body:order}).then(resp=>{
          console.log(resp.data);
          dispatch(setOrder(resp.data.Attributes,step));
        }).catch(err=>{
          console.log(err.response)
          setStep(step-1);
        })
        
      }else{
        let order = {
          ...stateOrder.order,
          lineItems:cart.items,
          totalAmount:cart.total,
          phoneNumber:authorizedUser.phoneNumber,
          userDisplayName:authorizedUser.userDisplayName,
          customer:{
            firstName,
            lastName,
            email,
            contactPhoneNumber:phoneNumber
          },
          billingAddress:{
            street:billingStreet,
            city:billingCity,
            state:billingState,
            zip:billingZip,
            country:billingCountry
          },shippingAddress:{
            street,
            city,
            state,
            zip,
            country
          },
          shippingCost:0,
        }
        if(!billToDifferentAddress){
          order["billingAddress"]=order["shippingAddress"]
        }
        let mandatoryItems={firstName,lastName,street,city,state,zip,country};
        if(billToDifferentAddress){
          mandatoryItems={...mandatoryItems,...{
            billingStreet,
            billingCity,
            billingState,
            billingZip,
            billingCountry
          }}
        }
        if(!validateMandatory(mandatoryItems)){
          console.log("mandatory",mandatoryItems);
          alert('Mandatory fields missing')
          setStep(step-1);
          return;
        }else{
          console.log("mandatory",mandatoryItems);
        }
        
        WebClient.post({path:'/orders/common/'+authorizedUser.phoneNumber,headers:{Authorization:`Bearer ${authorizedUser.token}`},body:order}).then(resp=>{
          console.log(resp.data);
          dispatch(setOrder(resp.data.Attributes,step));
        }).catch(err=>{
          setStep(step-1);
          console.log(err.response)
        })
        
      }
    }else if(step==3){
      if(authorizedUser){
        dispatch(syncUser(authorizedUser));
      }
      if(cart.items.length<=0){
        setStep(1);
        navigate('/');
      }
      if(stateOrder?.order && stateOrder?.order?.orderStatus?.indexOf('payment')<0){
        let order={orderStatus:'payment_initiated',payment:{paymentMethod}};
        WebClient.put({path:'/orders/common/'+stateOrder.order.id,headers:{Authorization:`Bearer ${authorizedUser.token}`},body:order}).then(resp=>{
          dispatch(setOrder(resp.data.Attributes,step));
          setPaymentLink(resp.data.Attributes.payment.paymentLink);
          setTraversalAllowed(false);
          setPaymentStatus('processing');
        }).catch(err=>{
          setStep(step-1);
          console.log(err.response)
        })
      }
    }else if(step==4){
      let orderId = stateOrder.order.id;
      dispatch(resetCart());
      dispatch(setCartToAccount(authorizedUser,null))
      dispatch(resetOrder());
      navigate('/order_complete/'+orderId);
    }
  },[step])
  useEffect(()=>{
    if(paymentLink){
      if(!hasWindow){
        const newWindow = window.open(paymentLink, '_blank', 'noopener,noreferrer');
        setNewWindow(true);
        if (newWindow){ newWindow.opener = null }
      }
      let tempIntervalId = setInterval(()=>{
        WebClient.get({path:'/payments/received/'+stateOrder.order.payment.id,parameters:{'checkout':'1'},headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(resp=>{
          if(resp.data.paymentStatus==="completed"||resp.data.paymentStatus==="manual"){
            // dispatch(resetCart());
            // dispatch(resetOrder());
            setPaymentLink(null);
            setStep(4);
            clearInterval(intervalId);
          }
        }).catch(err=>{
          console.log(err);
          if(err?.data?.error?.code==="HTC_PAYMENT_EXPIRED"){
            dispatch(resetOrder());
            setPaymentLink(null);
            clearInterval(intervalId);
            setStep(5);
          }
          console.log("Contact support. Error code HTC payment status check failed",err.response)
        })
      },1000*15);
      setIntervalId(tempIntervalId);
      if(stateOrder?.order?.payment && stateOrder?.order?.payment?.expiry<(Math.round(Date.now()/1000))){
        WebClient.get({path:'/payments/received/'+stateOrder.order.payment.id,parameters:{'checkout':'1'},headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(resp=>{
          if(resp.data.paymentStatus==="completed"||resp.data.paymentStatus==="manual"){
            setStep(4);
            setPaymentLink(null);
            clearInterval(intervalId);
          }
        }).catch(err=>{
          if(err?.data?.error?.code==="HTC_PAYMENT_EXPIRED"){
            dispatch(resetOrder());
            setPaymentLink(null);
            clearInterval(intervalId);
            setStep(5);
          }
          dispatch(resetOrder());
          setStep(5);
        })
      }
      setTimeout(()=>{
        WebClient.get({path:'/payments/received/'+stateOrder.order.payment.id,parameters:{'checkout':'1'},headers:{Authorization:`Bearer ${authorizedUser.token}`}}).then(resp=>{
          if(resp.data.paymentStatus==="completed"||resp.data.paymentStatus==="manual"){
            setStep(4);
            dispatch(resetCart());
            dispatch(resetOrder());
            setPaymentLink(null);
            clearInterval(intervalId);
          }
        }).catch(err=>{
          dispatch(resetOrder());
          setPaymentLink(null);
          clearInterval(intervalId);
          setStep(5);
        })
      },1000*60*15)
      
    }
  },[paymentLink]);
  
  return (
    <>
    <Header/>
    {(mandateLogin)?(<Login isModalOpen={mandateLogin} openModal={mandateLogin} closeModal={()=>{if(authorizedUser){setMandateLogin(false)}}} redirectTo={"/checkout"}/>):null}
    <main className="main">
      <div className="page-content">
        <div className="checkout">
          <div className="container">
            <div className="checkout-discount">
              <h3>Where should we send the order?</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
            </div>
            {/* End .checkout-discount */}

            <Stepper sx={{ width: '65%' }} className="mb-2" activeStep={step} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <form action="#">
              <div className="row ht-as">
                <div className="col-lg-8 checkout-left">
                  {(step==1)?(<div className="main-address-content">
                    <h2 className="checkout-title">Shipping Address</h2>
                    {/* End .checkout-title */}
                    <div className="row">
                      <div className="col-sm-6">
                        <label>First Name *</label>
                        <input type="text" className="form-control" required="" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}}/>
                      </div>
                      {/* End .col-sm-6 */}
                      <div className="col-sm-6">
                        <label>Last Name *</label>
                        <input type="text" className="form-control" required="" value={lastName} onChange={(e)=>{setLastName(e.target.value)}} />
                      </div>
                      {/* End .col-sm-6 */}
                    </div>
                    {/* End .row */}
                    <label>Email</label>
                    <TextField className="form-control" variant="outlined" value={email} InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon/>
                        </InputAdornment>
                      ),
                    }} onChange={(e)=>{setEmail(e.target.value)}}>
                  </TextField>
                  <div className="row">
                    <div className="col-sm-6">
                      <label>Country *</label>
                      <input type="text" className="form-control" placeholder="Country" required="" value={country}
                        onChange={(e)=>setCountry(e.target.value)}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <label>Street address *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="House number and Street name"
                        required=""
                        value={street}
                        onChange={(e)=>setStreet(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <label>Town / City *</label>
                      <input type="text" className="form-control" required="" value={city}
                    onChange={(e)=>setCity(e.target.value)} />
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-6">
                      <label>State *</label>
                      <input type="text" className="form-control" required="" value={state}
                    onChange={(e)=>setState(e.target.value)} />
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-6">
                      <label>Zip *</label>
                      <input type="text" className="form-control" required="" value={zip}
                    onChange={(e)=>setZip(e.target.value)} />
                    </div>
                    </div>
                    {/* End .row */}
                    {/* End .custom-checkbox */}
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="checkout-diff-address"
                        onClick={(e)=>{setShipToDifferentAddress(e.target.checked)}}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkout-diff-address"
                      >
                        Bill to a different address?
                      </label>
                    </div>
                    {(billToDifferentAddress)?(<div className="billing-address">
                      <h2 className="checkout-title">Billing Address</h2>
                      <label>Country *</label>
                      <input type="text" className="form-control" required="" value={billingCountry} onChange={(e)=>setBillingCountry(e.target.value)}/>
                      <label>Street address *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="House number and Street name"
                        required=""
                        value={billingStreet}
                        onChange={(e)=>setBillingStreet(e.target.value)}
                      />
                      <div className="row">
                        <div className="col-sm-6">
                          <label>Town / City *</label>
                          <input type="text" className="form-control" required="" value={billingCity}
                        onChange={(e)=>setBillingCity(e.target.value)} />
                        </div>
                        {/* End .col-sm-6 */}
                        <div className="col-sm-6">
                          <label>State / County *</label>
                          <input type="text" className="form-control" required="" value={billingState}
                        onChange={(e)=>setBillingState(e.target.value)}/>
                        </div>
                        {/* End .col-sm-6 */}
                      </div>
                      {/* End .row */}
                      <div className="row">
                        <div className="col-sm-6">
                          <label>Postcode / ZIP *</label>
                          <input type="text" className="form-control" required="" value={billingZip}
                        onChange={(e)=>setBillingZip(e.target.value)}/>
                        </div>
                        {/* End .col-sm-6 */}
                        <div className="col-sm-6">
                          <label>Phone *</label>
                          <input type="tel" className="form-control" required=""value={billingPhoneNumber}
                        onChange={(e)=>setBillingPhoneNumber(e.target.value)}/>
                        </div>
                        {/* End .col-sm-6 */}
                      </div>
                    </div>):(null)}
                    {/* End .custom-checkbox */}
                    {/* <ListCart/> */}
                  </div>):(null)
                  }
                  {
                    (step==2)?(<div className="ht-confirm-msg main-payment-content">
                        <PaymentCard paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod}/>
                        {/* <ListCart/> */}
                      </div>):(null)
                  }
                  {
                    
                    (step==3)?(<div className="ht-confirm-msg main-payment-waiting">
                            <h3>Payment is {paymentStatus}. Do not close the page</h3>
                        <div></div>
                      </div>):(null)
                  }
                  {
                    
                    (step==4)?(<div className="ht-confirm-msg main-payment-waiting">
                            <h3>Order has been placed 🎉. Check <a href="/orders">orders</a> page for more information.</h3>
                            <CheckCircleOutlineIcon color="green"/>
                        <div></div>
                      </div>):(null)
                  }
                  { 
                    (step==5)?(<div className="ht-confirm-msg main-payment-waiting">
                            <h3>Payment is failed. Retry checking out item</h3>
                            <ErrorOutlineIcon/>
                        <div></div>
                      </div>):(null)
                  }
                </div>
                <div className="ht-f ht-fc col-lg-4">
                  {(step<4)?(<ListCart readOnly={(step>2)?(true):(false)}/>):null}
                    <div className="ht-f ht-ac mt-2">
                      {(traversalAllowed && step<3)?(<Button variant="contained" color="success" size="large" className="btn-next ht-f1 mr-2" onClick={()=>{setStep(step-1)}}>Prev</Button>):(null)}
                      {(step>0 && step<3 && authorizedUser)?(<Button variant="contained" color="success" size="large" className="btn-next ht-f1" onClick={()=>{setStep(step+1)}}>Next</Button>):null}
                    </div>
                </div>
                {/* End .col-lg-3 */}
              </div>
              {/* End .row */}
            </form>
          </div>
          {/* End .container */}
        </div>
        {/* End .checkout */}
      </div>
      {/* End .page-content */}
    </main>
    <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  stateOrder: state.order,
});

export default connect(mapStateToProps, {  })(Checkout);