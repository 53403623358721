

import { UseDispatch, connect, useDispatch, useSelector } from 'react-redux';
import { setCategories } from '../actions/categoryAction';
import { useCallback, useEffect, useRef } from 'react';
import Banner from '../components/common/banner';
import Header from '../components/common/header';
import SubHeader from '../components/common/subHeader';
import DailyBestSell from '../components/productComponents/dailyBestSells';
import FeaturedProductCarousel from '../components/productComponents/featuredProductCarousel';
import RecentlyAdded from '../components/productComponents/recentlyAdded';
import TopSells from '../components/productComponents/TopSells';
import TrendingSells from '../components/productComponents/TrendingSells';
import CategoryCarousel from '../components/categoryComponents/categoryCarousel';
import Footer from '../components/common/footer';
import { listPopularCategories } from '../actions/popularCategoryAction';
import { listCategories } from '../actions/categoryAction';
import { setCartToAccount, setCartToLocal } from '../actions/cartAction';
import { setWishlistToAccount, setWishlistToLocal } from '../actions/wishlistAction';
import { OfferBanner } from '../components/common/offerBanner';
function Homepage({popularCategory,category,listCategories,listPopularCategories}) {
  const dispatch = useDispatch();
  const cart =useSelector(state=>state.cart);
  const wishlist = useSelector(state=>state.wishlist);
  const authorizedUser = useSelector(state=>state.user.user);
  useEffect(()=>{
      if(authorizedUser){
        if(cart.items.length>0){
          dispatch(setCartToAccount(authorizedUser,cart));
        }if(wishlist.items.length>0){
          dispatch(setWishlistToAccount(authorizedUser,wishlist));
        }
      }
  },[])
  useEffect(()=>{
    listPopularCategories()
  },[listPopularCategories])
  useEffect(()=>{
    listCategories();
  },[listCategories])
  return (
    <div className="Home">
        <Header refTo={''}/>
        {/* <SubHeader/> */}
        <Banner isInput={true}/>
        <CategoryCarousel/>
        <FeaturedProductCarousel/>
        <OfferBanner/>
            <DailyBestSell/>
        <div className='container'>
          <div className='ht-TopRecent-products'>
            <TopSells/>
            <TrendingSells/>
            <RecentlyAdded/>
          </div>
        </div>
        <Footer/>
    </div>
  );
}

const mapStateToProps = (state) => ({
  popularCategory: state.popularCategory.categories,
  category:state.category.categories
});

export default connect(mapStateToProps, { listCategories,listPopularCategories })(Homepage);