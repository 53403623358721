
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import {useCallback, useState} from 'react';
import Login from '../../Pages/Login';

function Footer(){
    const [isModalOpen, setModalOpen] = useState(false);
	const openModal = useCallback(() => {
		setModalOpen(true);
  },[isModalOpen]);
  const closeModal = useCallback(() => {
	setModalOpen(false);
  },[isModalOpen]);
    return(
        <footer className="footer footer-2">
        	<div className="footer-middle">
	            <div className="container">
	            	<div className="row">
	            		<div className="col-sm-12 col-lg-4">
	            			<div className="widget widget-about">
	            				{/* <p className="mb-2 text-left">Nam dui mi, tincidunt quis, dsdwdwdc porttitor, facilisis luctus, metus. </p> */}
                                <div className="social-icons social-icons-color mb-3">
                                    <a href="#" className="social-icon social-facebook" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                                    <a href="#" className="social-icon social-twitter" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                                    <a href="#" className="social-icon social-instagram" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                                    <a href="#" className="social-icon social-youtube" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>
                                    <a href="#" className="social-icon social-pinterest" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>
                                </div>{/* End .soial-icons */}
	            				<div className="ht-f ht-ac">
                                    <a href="index.html" className="sm-links fb"></a>
                                    <a href="index.html" className="sm-links in"></a>
                                    <a href="index.html" className="sm-links yt"></a>
                                    <a href="index.html" className="sm-links ln"></a>
                                    <a href="index.html" className="sm-links wh"></a>
                                </div>
                                <div className="widget-about-info mb-1">
                                    <span className="ft-icon"><i className="ft-i-map"></i></span>
                                    <div className="ht-f ht-fc text-left"><a href="javascript:void(0)">Near Realince Petrol Pump Opp. Aashapurna City, Main Boranada Road, Jodhpur</a></div>
	            				</div>{/* End .widget-about-info */}
	            				<div className="widget-about-info">
                                    <span className="ft-icon"><i className="ft-i-headset"></i></span>
                                    <div className="ht-f ht-fc text-left"><span className="widget-about-title">Mon - Fri: 9:00 am - 9:00 pm</span><a href="tel:123456789">+91 9216136074, +91 8619966885</a></div>
	            				</div>{/* End .widget-about-info */}
	            			</div>{/* End .widget about-widget */}
	            		</div>{/* End .col-sm-12 col-lg-3 */}

	            		<div className="col-sm-4 col-lg-3">
	            			<div className="widget">
	            				<h4 className="widget-title">Useful Links</h4>{/* End .widget-title */}

	            				<ul className="widget-list">
	            					<li><Link to="/AboutUs">About us</Link></li>
	            					<li><Link to="/Contact">Contact us</Link></li>
	            					<li><Link onClick={(e)=>{openModal()}}>Login</Link></li>
									{isModalOpen?(
                                                <Login isModalOpen={isModalOpen} openModal={openModal} closeModal={closeModal}/>
                                            ):(<></>)}
	            				</ul>{/* End .widget-list */}
	            			</div>{/* End .widget */}
	            		</div>{/* End .col-sm-4 col-lg-3 */}

	            		<div className="col-sm-4 col-lg-3">
	            			<div className="widget">
	            				<h4 className="widget-title">Customer Care</h4>{/* End .widget-title */}

	            				<ul className="widget-list">
	            					
	            					<li><Link to="/Terms_conditions">Term & Conditions</Link></li>
	            					<li><Link to="/Shipping_delivery">Shipping</Link></li>
	            					<li><Link to="/Cancel_refundPolicy">Returns</Link></li>
	            					<li><Link to="/Privacy_policy">Privacy Policy</Link></li>
	            				</ul>{/* End .widget-list */}
	            			</div>{/* End .widget */}
	            		</div>{/* End .col-sm-4 col-lg-3 */}

	            		<div className="col-sm-4 col-lg-2">
	            			<div className="widget">
	            				<h4 className="widget-title">Product Categories</h4>{/* End .widget-title */}

	            				<ul className="widget-list">
	            					<li><a href="#ht-recent-sell">Top Sells</a></li>
	            					<li><a href="#ht-recent-sell">Trending Sells </a></li>
	            					<li><a href="#ht-recent-sell">Recent sells </a></li>
	            					<li><a href="#ht-best-sell">Daily Best sells</a></li>
	            				</ul>{/* End .widget-list */}
	            			</div>{/* End .widget */}
	            		</div>{/* End .col-sm-64 col-lg-3 */}
	            	</div>{/* End .row */}
	            </div>{/* End .container */}
	        </div>{/* End .footer-middle */}

	        <div className="footer-bottom">
	        	<div className="container ht-f ht-ac">
	        		<p className="footer-copyright">Copyright © 2024 HTC Market. All Rights Reserved.</p>{/* End .footer-copyright */}
	        		{/* <ul className="footer-menu">
	        			<li><a href="#">Terms Of Use</a></li>
	        			<li><a href="#">Privacy Policy</a></li>
	        		</ul> */}

	        		<figure className="footer-payments">
                        <img src={require('../../assets/images/payments.png')} alt="Payment methods" width="200" height="20"/>
                    </figure>
	        	</div>{/* End .container */}
	        </div>{/* End .footer-bottom */}
        </footer>
    );
}
export default Footer;