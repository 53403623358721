import axios from 'axios';
import Logger from './logger.client.js';

class WebClient{
    static get({path,headers,parameters}){
        Logger.log({scope:'WebClient',method:'GET',message:'PreRequest Log',option:{path,headers,parameters}});
        return axios({
            method:"GET",
            url:process.env.REACT_APP_BASEAPIURL+path,
            params:parameters,
            headers:{...headers},
        })
    }
    static post({path,headers,parameters,body}){
        Logger.log({scope:'WebClient',method:'POST',message:'PreRequest Log',option:{path,headers,parameters}});
        return axios({
            method:"POST",
            url:process.env.REACT_APP_BASEAPIURL+path,
            params:parameters,
            data:body,
            headers:{...headers},
        })
    }
    static put({path,headers,parameters,body}){
        Logger.log({scope:'WebClient',method:'PUT',message:'PreRequest Log',option:{path,headers,parameters}});
        return axios({
            method:"PUT",
            url:process.env.REACT_APP_BASEAPIURL+path,
            params:parameters,
            data:body,
            headers:{...headers},
        })
    }
    static delete({path,headers,parameters}){
        Logger.log({scope:'WebClient',method:'Delete',message:'PreRequest Log',option:{path,headers,parameters}});
        return axios({
            method:"DELETE",
            url:process.env.REACT_APP_BASEAPIURL+path,
            params:parameters,
            headers:{...headers},
        })
    }
}
export default WebClient;