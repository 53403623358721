import React, { useState } from "react";
import mastercard from '../../assets/images/demos/demo-2/icons/cards.png';
import visa from '../../assets/images/demos/demo-2/icons/card1.png';
import upi from '../../assets/images/demos/demo-2/icons/upi.png';
import netbanking from '../../assets/images/provider.png';
const PaymentCard = ({paymentMethod,setPaymentMethod}) => {
  

  const handleSelection = (method) => {
    setPaymentMethod(method);
  };

  return (
    <div className="payment-method">
      <h3>Choose a payment method</h3>
      <div className="payment-option">
        <input
          type="radio"
          id="credit-debit-card"
          name="payment"
          checked={paymentMethod === "card"}
          onChange={() => handleSelection("card")}
        />
        <label htmlFor="credit-debit-card">
          Credit / Debit Card
          <img
            src={visa}
            alt="Visa"
            className="payment-icon"
          />
          <img
            src={mastercard}
            alt="MasterCard"
            className="payment-icon"
          />
        </label>
      </div>
      <div className="payment-option">
        <input
          type="radio"
          id="upi"
          name="payment"
          checked={paymentMethod === "upi"}
          onChange={() => handleSelection("upi")}
        />
        <label htmlFor="upi">
          UPI
          <span>Unlimited users and unlimited individual data.</span>
          <img
            src={upi}
            alt="UPI"
            className="payment-icon"
          />
        </label>
      </div>
      <div className="payment-option">
        <input
          type="radio"
          id="net-banking"
          name="payment"
          checked={paymentMethod === "netbanking"}
          onChange={() => handleSelection("netbanking")}
        />
        <label htmlFor="net-banking">
          Net Banking
          <span>Unlimited users and unlimited individual data.</span>
          <img
            src={netbanking}
            alt="Net Banking"
            className="payment-icon"
          />
        </label>
      </div>
      <div className="payment-option">
        <input
          type="radio"
          id="cod"
          name="payment"
          checked={paymentMethod === "cod"}
          onChange={() => handleSelection("cod")}
        />
        <label htmlFor="cod">
          Cash on Delivery (COD)
          <span>You will be redirected to the PayPal website after submitting your order.</span>
          
        </label>
      </div>
    </div>
  );
};

export default PaymentCard;
