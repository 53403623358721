// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown-rtl{direction:rtl}.countdown-holding span{color:#888}.countdown-row{width:100%;text-align:center}.countdown-row:after{content:'';display:table;clear:both}.countdown-show1 .countdown-section{width:calc(100% - 20px)}.countdown-show2 .countdown-section{width:calc(50% - 20px)}.countdown-show3 .countdown-section{width:calc(33.3% - 20px)}.countdown-show4 .countdown-section{width:calc(25% - 20px)}.countdown-show5 .countdown-section{width:calc(20% - 20px)}.countdown-show6 .countdown-section{width:calc(16.66% - 20px)}.countdown-show7 .countdown-section{width:calc(14.285% - 20px)}.countdown-section{position:relative;display:block;float:left;font-size:75%;text-align:center;margin-left:10px;margin-right:10px}.countdown-amount{font-size:200%}.countdown-period{display:block}.countdown-descr{display:block;width:100%}.countdown-separator .countdown-section:not(:last-child):after{content:':';display:inline-block;font-size:30px;line-height:1;position:absolute;left:100%;margin-left:8px;top:50%;transform:translateY(-50%);-ms-transform:translateY(-50%)}
/*# sourceMappingURL=jquery.countdown.css.map */
`, "",{"version":3,"sources":["webpack://./src/assets/css/plugins/jquery.countdown.css"],"names":[],"mappings":"AAAA,eAAe,aAAa,CAAC,wBAAwB,UAAU,CAAC,eAAe,UAAU,CAAC,iBAAiB,CAAC,qBAAqB,UAAU,CAAC,aAAa,CAAC,UAAU,CAAC,oCAAoC,uBAAuB,CAAC,oCAAoC,sBAAsB,CAAC,oCAAoC,wBAAwB,CAAC,oCAAoC,sBAAsB,CAAC,oCAAoC,sBAAsB,CAAC,oCAAoC,yBAAyB,CAAC,oCAAoC,0BAA0B,CAAC,mBAAmB,iBAAiB,CAAC,aAAa,CAAC,UAAU,CAAC,aAAa,CAAC,iBAAiB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,kBAAkB,cAAc,CAAC,kBAAkB,aAAa,CAAC,iBAAiB,aAAa,CAAC,UAAU,CAAC,+DAA+D,WAAW,CAAC,oBAAoB,CAAC,cAAc,CAAC,aAAa,CAAC,iBAAiB,CAAC,SAAS,CAAC,eAAe,CAAC,OAAO,CAAC,0BAA0B,CAAC,8BAA8B;AACliC,+CAA+C","sourcesContent":[".countdown-rtl{direction:rtl}.countdown-holding span{color:#888}.countdown-row{width:100%;text-align:center}.countdown-row:after{content:'';display:table;clear:both}.countdown-show1 .countdown-section{width:calc(100% - 20px)}.countdown-show2 .countdown-section{width:calc(50% - 20px)}.countdown-show3 .countdown-section{width:calc(33.3% - 20px)}.countdown-show4 .countdown-section{width:calc(25% - 20px)}.countdown-show5 .countdown-section{width:calc(20% - 20px)}.countdown-show6 .countdown-section{width:calc(16.66% - 20px)}.countdown-show7 .countdown-section{width:calc(14.285% - 20px)}.countdown-section{position:relative;display:block;float:left;font-size:75%;text-align:center;margin-left:10px;margin-right:10px}.countdown-amount{font-size:200%}.countdown-period{display:block}.countdown-descr{display:block;width:100%}.countdown-separator .countdown-section:not(:last-child):after{content:':';display:inline-block;font-size:30px;line-height:1;position:absolute;left:100%;margin-left:8px;top:50%;transform:translateY(-50%);-ms-transform:translateY(-50%)}\r\n/*# sourceMappingURL=jquery.countdown.css.map */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
