import { useParams } from "react-router-dom";
import LargeProductCard from "../components/productComponents/LargeProductCard";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import { useEffect } from "react";

function SingleProduct(props){
    let {id} = useParams();
    // useEffect(()=>{console.log("Updated",id)},[id])
    return(<>
        <Header/>
        <LargeProductCard id={id}/>
        <Footer/>
    </>)
}
export default SingleProduct;


