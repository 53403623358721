const initialState = {
    categories: []
  };
  
  const subCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SUB_CATEGORIES':
        return {
          ...state,
          categories: action.payload
        };
      case 'LIST_SUB_CATEGORIES':
        return {
          ...state,
          categories:action.payload
        };
      default:
        return state;
    }
  };
  
  export default subCategoryReducer;
  