import { Link } from "react-router-dom";
import {ReadRating} from "../common/ratings";
import { fixName } from "../common/helper";
import { useEffect } from "react";
import AddToWishlist from "../common/addToWishlistButton";
import AddToCart from "../common/addToCartButton";

function SmallProductCard({product,setEditProduct,hasAddToCart=false,hasAddToWishlist=true}){
    useEffect(()=>{
        console.log("smallProd",product);
    },[])
    return(
        <div className="product product-11 text-center" key={"prod"+product.id} onClick={(e)=>{e.preventDefault();setEditProduct(product);}}>
            <figure class="product-media">
                {/* <Link to={'products/'+(product.id)}> */}
                    <img src={product.image} alt="Product image" class="product-image"/>
                {/* </Link> */}

                <div class="product-action-vertical">
                    {hasAddToWishlist?(<AddToWishlist item={product}/>):null}
                </div>{/* End .product-action-vertical */}
            </figure>{/* End .product-media */}

            <div class="product-body">
                <h5>{fixName(product.category)}</h5>
                <h3 class="product-title">{product.productName}</h3>{/* End .product-title */}
                <ReadRating value={product.rating}/>
                <div class="new-price">
                    Rs.{product.saleRate}
                </div>{/* End .product-price */}
            </div>{/* End .product-body */}
            {/* <AddToCart item={product}/> */}
            {hasAddToCart?(<AddToCart item={product}/>):null}
        </div>
    )
}
export default SmallProductCard;