import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToWishlist } from "../../actions/wishlistAction";

function AddToWishlist({ item, source }) {
    const dispatch = useDispatch();
    const [isAdded, setIsAdded] = useState(false);

    const handleAddToWishlist = async (product) => {
        dispatch(addToWishlist(product));
        setIsAdded(true); // Set added state to true

        // Remove the "added" class after 1 second
        setTimeout(() => {
            setIsAdded(false);
        }, 500);
    };

    return (
        <a
            className={`${source ? "btn-product btn-wishlist sss" : "btn-product-icon"} btn-wishlist ${isAdded ? "added" : ""}`}
            onClick={(e) => {
                e.preventDefault();
                handleAddToWishlist({ ...item });
            }}
        >
            <span onClick={(e) => {
                e.preventDefault();
                handleAddToWishlist({ ...item });
            }}>
                Add to wishlist
            </span>
        </a>
    );
}

export default AddToWishlist;