import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import AdminProducts from "../components/adminComponents/adminProducts";
import logo from '../assets/images/logo.png';
import { searchTermGenerator } from "../components/common/helper";
import AdminCategories from "../components/adminComponents/adminCategories";
import AdminSubCategories from "../components/adminComponents/adminSubCategories";
import AdminUsers from "../components/adminComponents/adminUsers";
import AdminBanners from "../components/adminComponents/adminBanners";
import AdminOrders from "../components/adminComponents/adminOrders";
import { removeUser } from "../actions/userAction";

function renderMenuBody(menu,searchTerm){
    switch(menu){
        case 'Products':
            return(
                <div className="products">
                    <AdminProducts searchTerm={searchTerm}/>
                </div>
            );
        case 'Banners':
            return(<div className="banners">
                    <AdminBanners/>
                </div>)
        case 'Categories':
            return(<div className="categories">
                    <AdminCategories searchTerm={searchTerm}/>
                </div>)
        case 'Sub categories':
            return(<div className="subcategories">
                    <AdminSubCategories searchTerm={searchTerm}/>
                </div>)
        case 'Orders':
            return(<div className="orders">
                    <AdminOrders searchTerm={searchTerm}/>
                </div>)
        case 'Users':
            return(<div className="users">
                    <AdminUsers searchTerm={searchTerm}/>
                </div>)
        default:
            return(<></>)
    }
}
function Admin(){
    const user = useSelector((state)=>(state.user.user));
    const navigate = useNavigate();
    const [menu,setMenu] = useState('Products');
    const [menuList,setMenuList] = useState([]);
    const [searchTerm,setSearchTerm] = useState(null);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(!user){
            navigate('/unauthorized');
        }else if(user.userType!==0 && user.userType!==1){
            navigate('/unauthorized');
        }
    },[]);
    const scope = user.userScope;
    return (
        <>
        <header className="header header-2 header-intro-clearance">
            <div className="header-middle">
                <div className="container">
                        
                    <div className="header-left">
                        <a href="/" className="logo">
                            <img src={logo} alt="HTC Logo" width="175" height="52"/>
                        </a>
                        
                    </div>
                    <div className="header-right">
                        <Link to="/">Home</Link>
                        <Link to="/" onClick={()=>{dispatch(removeUser())}}>Logout</Link>
                    </div>
                </div>
            </div>
        </header>
        <div className="admin-page container">
            <div className="admin-side-menu">
                <ul>
                    {(scope.includes('products.all')||scope.includes('products.write'))?(<li onClick={(e)=>{e.preventDefault();setMenu('Products')}}>
                        Products
                    </li>):null}
                    {(scope.includes('banners.all')||scope.includes('banners.write'))?(<li onClick={(e)=>{setMenu('Banners')}}>
                        Banners
                    </li>):null}
                    {(scope.includes('categories.all')||scope.includes('categories.write'))?(<li onClick={(e)=>{setMenu('Categories')}}>
                        Categories
                    </li>):null}
                    {(scope.includes('subCategories.all')||scope.includes('subCategories.write'))?(<li onClick={(e)=>{setMenu('Sub categories')}}>
                        Sub categories
                    </li>):null}
                    {(scope.includes('orders.all')||scope.includes('orders.write'))?(<li onClick={(e)=>{setMenu('Orders')}}>
                        Orders
                    </li>):null}
                    {(scope.includes('users.all')||scope.includes('users.write'))?(<li onClick={(e)=>{setMenu('Users')}}>
                        Users
                    </li>):null}
                </ul>
            </div>
            <div className="admin-right">
                <div className="menu-header">
                    <TextField id="outlined-search" label="Search field" type="search" onChange={(e)=>{setSearchTerm(searchTermGenerator(e.target.value))}}/>
                </div>
            <h1>Admin {menu}</h1>

                <div className={`menu-body ${menu.toLowerCase().replaceAll(' ','')}`}>
                    {
                        renderMenuBody(menu,searchTerm)
                    }
                </div>
            </div>
        </div>
        </>
    )
}
export default Admin;