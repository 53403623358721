
import { useEffect } from "react";

function SmallCategoryCard({category,setEditCategory}){
    return(
        <div class="product category-11 text-center" key={"category"+category.categoryName} onClick={(e)=>{e.preventDefault();setEditCategory(category);}}>
            <figure class="category-media">
                {/* <Link to={'categorys/'+(category.id)}> */}
                    <img src={category.image} alt="Category image" class="category-image"/>
                {/* </Link> */}
            </figure>{/* End .category-media */}

            <div class="category-body">
                <h3>{category.categoryLabel}</h3>
            </div>{/* End .category-body */}
            {/* <AddToCart item={category}/> */}
        </div>
    )
}
export default SmallCategoryCard;