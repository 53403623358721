const initialState = {
    items: []
  };
  const wishlistReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_TO_WISHLIST':
        const item = action.payload.item;

            // Check if the item is already in the wishlist
        const existItem = state.items.find(wishlistItem => wishlistItem.id === item.id);
        if (existItem) {
            // If the item exists, increment the quantity
            return {
              ...state,
              items: state.items
          };
        } else {
            // If the item doesn't exist, add it to the wishlist with qty 1
            return {
                ...state,
                items: [...state.items,{...item}]
            };
        }
      case 'RESET_WISHLIST':
        return initialState;
      case 'SET_WISHLIST':
        return {
          ...state,
          items:action.payload.items
        };
      case 'SYNC_WISHLISH':
        let existList=[];
        return {
          ...state,
          items:[...state.items,...action.payload.items].filter(item=>{
            if(existList.includes(item.id)){
              return false
            }else{
              existList.push(item.id);
              return true;
            }
          })
        }
      case 'REMOVE_FROM_WISHLIST':
        const updatedItems = state.items.filter(item => (
          item.id !== action.payload.id
        ));
        return {
          ...state,
          items:updatedItems
        };
      default:
        return state;
    }
  };
  
  export default wishlistReducer;
  