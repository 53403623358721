const initialState = {
  products: []
  };
  
  const recentlyAddedProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LIST_RECENTLY_ADDED_PRODUCTS_START':
          return {
            ...state,
            products:[...action.payload]
          };
      case 'LIST_RECENTLY_ADDED_PRODUCTS_LOADING':
        return {
          ...state,
          products:[...action.payload]
        };
        case 'LIST_RECENTLY_ADDED_PRODUCTS_COMPLETED':
          return {
            ...state,
            products:[...action.payload]
          };
      default:
        return state;
    }
  };
  
  export default recentlyAddedProductReducer;
  