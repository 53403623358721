import React, { Component,useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { connect, useSelector } from 'react-redux';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import { listFeaturedProducts } from '../../actions/featuredProductAction';
import { listPopularCategories } from '../../actions/popularCategoryAction';
import AddToCart from '../common/addToCartButton';
import {ReadRating} from '../common/ratings';
import {fixName} from '../common/helper';
import { Link } from 'react-router-dom';
import Wishlist from '../common/wishlist';
import AddToWishlist from '../common/addToWishlistButton';

function FeaturedProductCarousel({featuredProducts,listFeaturedProducts}){
    const popularCategory = useSelector(state=>state.popularCategory.categories);
    const [categoryFilter,setCategoryFilter] = useState("all");
    useEffect(()=>{
        console.log("featured products",featuredProducts,categoryFilter);
        listFeaturedProducts();
    },[listFeaturedProducts,categoryFilter]);
    useEffect(()=>{
        console.log("categoryFilter",categoryFilter);
        listFeaturedProducts()
    },[categoryFilter])
    return(
        <div className="featured-product-carousel mb-6">
            <div className="container title-container ht-f ht-fw ht-ac ht-jsb mb-3">
                <h3 className="title ht-fb-title">Featured Products</h3>
                {/* <ul className="nav nav-pills nav-border-anim nav-big justify-content-center" role="tablist">
                    <li className="nav-item">
                        <a class={"nav-link"+((categoryFilter==='all')?(" active"):(""))} id="products-featured-link" onClick={()=>setCategoryFilter("all")} data-toggle="tab" role="tab" aria-controls="products-featured-tab" aria-selected={(categoryFilter==='all')}>All</a>
                    </li>
                    {
                        popularCategory.map(category=>{
                            return(
                                <li className="nav-item" key={category.categoryName}>
                                    <a class={"nav-link"+((categoryFilter===category.categoryName)?(" active"):(""))} id="products-sale-link" onClick={()=>setCategoryFilter(category.categoryName)} data-toggle="tab" role="tab" aria-controls="products-sale-tab" aria-selected={(categoryFilter===category.categoryName)}>{category.categoryLabel}</a>
                                </li>
                            )
                        })
                    }
                </ul> */}
            </div>

            <div className="container">
                <div className="tab-content tab-content-carousel">
                    <div className="tab-pane p-0 fade show active" id="products-featured-tab" role="tabpanel" aria-labelledby="products-featured-link">
                        <OwlCarousel items={(featuredProducts.length<5)?(featuredProducts.length):(5)}  
                            className="owl-theme carousel-with-shadow ht-bg-products"  
                            responsive={{
                                0: {
                                    items: 1,
                                    nav: false
                                },
                                420: {
                                    items: 2,
                                    nav: false
                                },
                                576: {
                                    items: 2,
                                    nav: false
                                },
                                768: {
                                    items: 3,
                                    nav: false
                                },
                                992: {
                                    items: 4
                                },
                                1200: {
                                    items: 5
                                }
                            }}  
                            dots = {false}
                            nav
                            margin={20}>
                            {   featuredProducts.filter((product)=>(product.category==categoryFilter||categoryFilter=="all")).map((product)=>{
                                console.log("Featured product map",product,categoryFilter)
                                return(
                                    <div className="product product-11 text-center" key={"featuredProd"+product.id}>
                                        <figure className="product-media">
                                            <Link to={'products/'+(product.id)}>
                                                <img src={product.image} alt="Product image" className="product-image"/>
                                            </Link>

                                            <div className="product-action-vertical">
                                                <AddToWishlist item={product}/>
                                            </div>{/* End .product-action-vertical */}
                                        </figure>{/* End .product-media */}

                                        <div className="product-body">
                                            <h5><Link to={`products?category=${product.category}`}>{fixName(product.category)}</Link></h5>
                                            <h3 className="product-title"><Link to={'products/'+(product.id)}>{product.productName}</Link></h3>{/* End .product-title */}
                                            <ReadRating value={product.rating}/>
                                            <div className="new-price">
                                                Rs.{product.saleRate}
                                            </div>{/* End .product-price */}
                                        </div>{/* End .product-body */}
                                        <AddToCart item={product}/>
                                    </div>
                                )
                                })
                            }

                            

                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    featuredProducts: state.featuredProducts.products
  });
  
export default connect(mapStateToProps, { listFeaturedProducts })(FeaturedProductCarousel);