import { useEffect, useState } from 'react'
import WebClient from '../../client/webclient.client'
import OwlCarousel from 'react-owl-carousel';

export function OfferBanner(){
    const [offerBanner,setOfferBanner]=useState([])
    useEffect(()=>{
        WebClient.get({path:'/banners/home_secondary_banner'}).then(resp=>{
            setOfferBanner(Object.values(resp.data));
        }).catch(err=>{
            console.log(err.message);
        })
    },[])
    return(
        <div className='container mb-6'>
            <OwlCarousel 
                items={offerBanner.length>2?2:offerBanner.length}
                margin={20}
                // stagePadding={100}
                loop
                autoplay={true}
                autoplayTimeout={2500}
                autoplayHoverPause={true}
            >
            {
                offerBanner?offerBanner.map(image=>(<div className='sub-banner-left'>
              <img src={image} alt='sub-banner-1'/>
            </div>)):null
            }
            </OwlCarousel>
            
        </div>
    )
}