import { connect, useSelector } from "react-redux";
import { listProducts, loadMoreProducts } from "../../actions/productAction";
import { useCallback, useEffect, useRef, useState } from "react";
import SmallProductCard from "../productComponents/smallProductCard";
import { Button, Checkbox, InputLabel, TextField, Typography } from "@mui/material";
import {Buffer} from 'buffer';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { WriteRating } from "../common/ratings";
import { listCategories } from "../../actions/categoryAction";
import { listSubCategories } from "../../actions/subCategoryAction";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import WebClient from "../../client/webclient.client";
import StaticProductImage from '../../assets/images/products/v1.webp'
function UpdatePopoverModal({product,isModalOpen,reset}){
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [rating,setRating]=useState(product.rating);
    const [productName,setProductName]=useState(product.productName);
    const [description,setDescription] = useState(product.description);
    const [category,setCategory] = useState(product.category);
    const [subCategoryName,setSubCategoryName] = useState(product.subCategoryName);
    const [mrp,setMrp] = useState(product.mrp);
    const [saleRate,setSaleRate] = useState(product.saleRate);
    const [isFeatured,setIsFeatured] = useState(product.isFeatured);
    const [isDailyBestSell,setIsDailyBestSell] = useState(product.isDailyBestSell);
    const [isHotDeal,setIsHotDeal] = useState(product.isHotDeal);
    const [isPromotions,setIsPromotions] = useState(product.isPromotions);
    const [recentlyAdded,setRecentlyAdded] = useState(product.recentlyAdded);
    const [topSell,setTopSell] = useState(product.topSell);
    const [trendingSell,setTrendingSell] = useState(product.trendingSell);
    const [confirmation,setConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const user = useSelector(state=>state.user.user)
    const categories = useSelector(state=>state.category.categories); 
    let subCategories = useSelector(state=>state.subCategory.categories);
    useEffect(()=>{
        subCategories=subCategories.filter((a)=>{return (a.categoryName===category)?true:false});
        console.log(subCategories.filter((a)=>((a.categoryName===category)?true:false)),category,subCategoryName);
    },[category]);
    
    function updateProduct(){
        let updatedProduct = {productName,description,category,subCategoryName,mrp,saleRate,rating,image:fileData,isFeatured,recentlyAdded,topSell,trendingSell,updatedAt:Math.round(Date.now()/1000),isHotDeal,isPromotions,isDailyBestSell};
        WebClient.put({ 
            path: '/products/'+product.id,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: updatedProduct
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    function deleteProduct(){
        
        WebClient.delete({ 
            path: '/products/'+product.id,
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {}
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setDeleteConfirmation(false);
            reset();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    return(
        <div className={`ht-login-modal admin-product-popover ${isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>

            {(confirmation)?(<div className="ad-confirmation">
                <h6>Do you want to update this product?</h6>
                <Button onClick={updateProduct} variant="contained" className="mr-3">Ok</Button>
                <Button onClick={(e)=>{setConfirmation(false)}} variant="outlined" color="error">Cancel</Button>
            </div>):(null)}
            {(deleteConfirmation)?(<div className="delete-confirmation" >
                <h6>Do you want to permanently delete this product?</h6>
                <Button onClick={deleteProduct} variant="contained" className="mr-3">Ok</Button>
                <Button onClick={(e)=>{setDeleteConfirmation(false)}} variant="outlined" color="error">Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{reset()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-image">
                    <img src={fileData?(`data:${mimeType};base64,${fileData}`):product.image}></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                </div>
                <div className="edit-details">
                    <TextField id="product-name" className="mt-2" label="Product name" variant="filled" defaultValue={product.productName} onChange={(e)=>setProductName(e.target.value)}/>
                    <TextField
                        id="product-description"
                        name="description"
                        label="Description"
                        multiline
                        rows={4}
                        className="mt-2" 
                        variant="filled"
                        defaultValue={product.description}
                        onChange={(e)=>setDescription(e.target.value)}
                        />
                    {/* <TextField id="category" className="mt-2" label="Category" variant="filled" defaultValue={product.category} onChange={(e)=>setCategory(e.target.value)}/> */}
                    <div className="ht-f mt-2">
                        <div className="ht-f ht-fc  mr-4">
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category"
                            defaultValue={category}
                            label="Category"
                            onChange={(e)=>{setCategory(e.target.value)}}
                        >
                            {
                                categories.map(item=>{
                                    return(<MenuItem value={item.categoryName}>{item.categoryLabel}</MenuItem>)
                                })
                            }
                        </Select>
                        </div>
                        <div className="ht-f ht-fc">
                        <InputLabel id="sub-category-label">Sub Category</InputLabel>
                        <Select
                            labelId="sub-category-label"
                            id="subCategoryName"
                            defaultValue={subCategoryName}
                            label="Sub Category"
                            value={subCategoryName}
                            onChange={(e)=>{setSubCategoryName(e.target.value)}}
                        >
                            {
                                subCategories.filter((a)=>((a.categoryName===category)?true:false)).map(item=>{
                                    return(<MenuItem value={item.subCategoryName}>{item.subCategoryLabel}</MenuItem>)
                                })
                            }
                        </Select>
                        </div>
                    </div>
                    <TextField id="mrp" className="mt-2" label="MRP" variant="filled" type="number" defaultValue={product.mrp} onChange={(e)=>setMrp(e.target.value)}/>
                    <TextField id="saleRate" className="mt-2" label="Sale rate" variant="filled" type="number" defaultValue={product.saleRate} onChange={(e)=>setSaleRate(e.target.value)}/>
                    <Typography component="legend">Rating</Typography>
                    <WriteRating rating={rating} setRating={setRating}/>
                     
                    <div className="ht-f ht-fw ht-ac">
                        <FormControlLabel control={<Checkbox id="isFeatured"  defaultChecked={isFeatured} onChange={(e,v)=>{setIsFeatured(v)}}/>} label="Is Featured?" />
                        <FormControlLabel control={<Checkbox id="isDailyBestSell"  defaultChecked={isDailyBestSell} onChange={(e,v)=>{setIsDailyBestSell(v)}}/>} label="Is daily best sell?" />
                        <FormControlLabel control={<Checkbox id="recentlyAdded" defaultChecked={recentlyAdded} onChange={(e,v)=>{setRecentlyAdded(v)}}/>} label="Is recently added?" />
                        <FormControlLabel control={<Checkbox id="topSell"  defaultChecked={topSell} onChange={(e,v)=>{setTopSell(v)}}/>} label="Is top sell?" />
                        <FormControlLabel control={<Checkbox id="trendingSell" defaultChecked={trendingSell} onChange={(e,v)=>{setTrendingSell(v)}}/>} label="Is trending sell?" />
                        <FormControlLabel control={<Checkbox id="isHotDeal" defaultChecked={isHotDeal} onChange={(e,v)=>{setIsHotDeal(v)}}/>} label="Is hot deal?" />
                        <FormControlLabel control={<Checkbox id="isPromotions" defaultChecked={isPromotions} onChange={(e,v)=>{setIsPromotions(v)}}/>} label="Is promotions?" />
                    </div>
                    <div className="admin-popup-footer">
                        <Button variant="contained" color="success" className="mr-2" onClick={(e)=>{setConfirmation(true)}}>Update</Button>
                        <Button variant="outlined" color="error" onClick={(e)=>{setDeleteConfirmation(true)}}>Delete</Button>
                    </div>
                </div>
        </div>
    );
}
function NewPopoverModal({isModalOpen,CloseNewModal}){
    const fileInput = useRef();
    const [fileData,setFileData] = useState(null);
    const [mimeType,setMimeType] = useState(null);
    const [rating,setRating]=useState(0);
    const [productName,setProductName]=useState(null);
    const [description,setDescription] = useState(null);
    const [category,setCategory] = useState(null);
    const [subCategoryName,setSubCategoryName] = useState(null);
    const [mrp,setMrp] = useState(null);
    const [saleRate,setSaleRate] = useState(null);
    const [isFeatured,setIsFeatured] = useState(false);
    const [isDailyBestSell,setIsDailyBestSell] = useState(false);
    const [recentlyAdded,setRecentlyAdded] = useState(true);
    const [topSell,setTopSell] = useState(false);
    const [trendingSell,setTrendingSell] = useState(false);
    const [isHotDeal,setIsHotDeal] = useState(false);
    const [isPromotions,setIsPromotions] = useState(false);
    const [confirmation,setConfirmation] = useState(false);
    const user = useSelector(state=>state.user.user)
    const categories = useSelector(state=>state.category.categories);
    let subCategories = useSelector(state=>state.subCategory.categories);
    useEffect(()=>{
        subCategories=subCategories.filter((a)=>{return (a.categoryName===category)?true:false});
        console.log(subCategories.filter((a)=>((a.categoryName===category)?true:false)),category,subCategoryName);
    },[category]);
    
    function createProduct(){
        let product = {productName,description,category,subCategoryName,mrp,saleRate,rating,image:fileData,isFeatured,recentlyAdded,topSell,trendingSell,isHotDeal,isPromotions,updatedAt:Math.round(Date.now()/1000),isDailyBestSell};
        console.log(product);
        WebClient.post({ 
            path: '/products',
            headers: { authorization: `Bearer ${user.token}` },
            parameters: {},
            body: product
        }).then(response => {
            //localStorage.setItem('token',response.token);
            setConfirmation(false);
            CloseNewModal();
            window.location.reload();
        }).catch(err => {
            console.log(err);
            window.alert(err.response.data.error.message);
        });
       
       
    }
    return(
        <div className={`ht-login-modal admin-product-popover ${!isModalOpen ? 'ht-login-modal-exit' : 'ht-login-modal-enter'}`}>
            {(confirmation)?(<div className="ad-confirmation">
                <h6>Do you want to add this product?</h6>
                <Button onClick={createProduct} variant="contained" className="mr-3">Ok</Button>
                <Button onClick={(e)=>{setConfirmation(false)}} variant="outlined" color="error">Cancel</Button>
            </div>):(null)}
                <button className="close-button" onClick={()=>{CloseNewModal()}} style={{backgroundColor:'black'}}>+</button>
                <div className="edit-image">
                    <img src={fileData?(`data:${mimeType};base64,${fileData}`):StaticProductImage}></img>
                    <Button variant="contained" className="mt-2" type="button" onClick={()=>fileInput.current.click()}>Upload</Button>
                    <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={(e)=>{
                        if(e.target.files.length>0){
                        setMimeType(e.target.files[0].type);
                        e.target.files[0].arrayBuffer().then(data=>{
                            let file = Buffer.from(data).toString('base64');
                            setFileData(file);
                        })
                        }
                    }}/>
                </div>
                <div className="edit-details">
                    <TextField id="product-name" required className="mt-2" label="Product name" variant="filled" onChange={(e)=>setProductName(e.target.value)}/>
                    <TextField
                        id="product-description"
                        name="description"
                        label="Description"
                        multiline
                        rows={4}
                        className="mt-2" 
                        variant="filled"
                        required
                        onChange={(e)=>setDescription(e.target.value)}
                        />
                    {/* <TextField id="category" className="mt-2" label="Category" variant="filled" defaultValue={product.category} onChange={(e)=>setCategory(e.target.value)}/> */}

                    <div className="ht-f mt-2">
                        <div className="ht-f ht-fc  mr-4">
                        <InputLabel id="category-label" required>Category</InputLabel>
                        <Select
                            className="ad-select"
                            labelId="category-label"
                            id="category"
                            label="Category"
                            onChange={(e)=>{setCategory(e.target.value)}}
                            required
                        >
                            {
                                categories.map(item=>{
                                    return(<MenuItem value={item.categoryName}>{item.categoryLabel}</MenuItem>)
                                })
                            }
                        </Select>
                        </div>
                        <div className="ht-f ht-fc">
                        <InputLabel id="sub-category-label" required>Sub Category</InputLabel>
                        <Select
                            className="ad-select"
                            labelId="sub-category-label"
                            id="subCategoryName"
                            label="Sub Category"
                            onChange={(e)=>{setSubCategoryName(e.target.value)}}
                            required
                        >
                            {
                                subCategories.filter((a)=>((a.categoryName===category)?true:false)).map(item=>{
                                    return(<MenuItem value={item.subCategoryName}>{item.subCategoryLabel}</MenuItem>)
                                })
                            }
                        </Select>
                        </div>
                    </div>
                    <TextField id="mrp" className="mt-2" label="MRP" variant="filled" type="number" required onChange={(e)=>setMrp(Number(e.target.value))}/>
                    <TextField id="saleRate" className="mt-2" label="Sale rate" variant="filled" required type="number" onChange={(e)=>setSaleRate(Number(e.target.value))}/>
                    <InputLabel id="rating-label" required>Rating</InputLabel>
                    <WriteRating labelId="rating-label" rating={rating} setRating={setRating}/>
                     
                    <div className="ht-f ht-fw ht-ac">
                        <FormControlLabel control={<Checkbox id="isFeatured"  defaultChecked={isFeatured} onChange={(e,v)=>{setIsFeatured(v)}}/>} label="Is Featured?" />
                        <FormControlLabel control={<Checkbox id="isDailyBestSell"  defaultChecked={isDailyBestSell} onChange={(e,v)=>{setIsDailyBestSell(v)}}/>} label="Is daily best sell?" />
                        <FormControlLabel control={<Checkbox id="recentlyAdded"  defaultChecked={recentlyAdded} onChange={(e,v)=>{setRecentlyAdded(v)}}/>} label="Is recently added?" />
                        <FormControlLabel control={<Checkbox id="topSell"  defaultChecked={topSell} onChange={(e,v)=>{setTopSell(v)}}/>} label="Is top sell?" />
                        <FormControlLabel control={<Checkbox id="trendingSell"  defaultChecked={trendingSell} onChange={(e,v)=>{setTrendingSell(v)}}/>} label="Is trending sell?" />
                        <FormControlLabel control={<Checkbox id="isHotDeal" defaultChecked={isHotDeal} onChange={(e,v)=>{setIsHotDeal(v)}}/>} label="Is hot deal?" />
                        <FormControlLabel control={<Checkbox id="isPromotions" defaultChecked={isPromotions} onChange={(e,v)=>{setIsPromotions(v)}}/>} label="Is promotions?" />
                    </div>
                    <div className="admin-popup-footer">
                        <Button variant="contained" color="success" className="admin-success-btn" onClick={(e)=>{setConfirmation(true)}}>Create</Button>
                    </div>
                </div>
        </div>
    );
}
function AdminProducts({searchTerm,products,nextPage,listProducts,loadMoreProducts,categories,subCategories,listCategories,listSubCategories}){
    const [popOverProduct,setPopOverProduct] = useState(null);
    const [newProductPopover,setNewProductPopover] = useState(null);
    const setEditProduct = useCallback((item)=>{
        setPopOverProduct(item);
    })
    const listMoreProducts = ()=>{
        loadMoreProducts({startKey:nextPage})
    }
    useEffect(()=>{
        console.log("admin prods",products);
        let filter={};
        if(searchTerm){
            filter.searchTerm=searchTerm;
        }
        listProducts(filter);
    },[listProducts]);
    useEffect(()=>{
        let filter={};
        if(searchTerm){
            filter.searchTerm=searchTerm;
        }
        loadMoreProducts(filter);
    },[loadMoreProducts])
    useEffect(()=>{
        listProducts({searchTerm:searchTerm});
    },[searchTerm])
    useEffect(()=>{
        // alert('Popover',popOverProduct?popOverProduct.productName:'null')
    },[popOverProduct])
    useEffect(()=>{
        listCategories()
    },[listCategories])
    useEffect(()=>{
        listSubCategories()
    },[listSubCategories])
    return (
        <>
        <div className="admin-products ht-productList-page">
            <Button className="add-product mdc-button mdc-button--raised" variant="contained" onClick={(e)=>{setNewProductPopover(true)}}>Add product</Button>
            {   <div className="admin-products-group product-list">
                {(products)?(
                    products.map(product=>{
                        return(
                            <SmallProductCard product={product} setEditProduct={setEditProduct}/>
                        )
                    })
                ):null}
                </div>
            }
            {
                (popOverProduct)?(<UpdatePopoverModal product={popOverProduct} reset={()=>{setPopOverProduct(null)}}></UpdatePopoverModal>):null
            }
            {
                (newProductPopover)?(<NewPopoverModal isModalOpen={newProductPopover} CloseNewModal={()=>{setNewProductPopover(false)}}></NewPopoverModal>):null
            }
        </div>
        <input className="load-more-prods" style={{position:'relative',justifyItems:'center'}} type="button" hidden={(nextPage!==null&&nextPage!==undefined)?false:true} value={'Load more'} onClick={(e)=>{e.preventDefault();listMoreProducts();}}/>
        </>
    );
}
const mapStateToProps = (state) => ({
    products: state.products.items,
    nextPage: state.products.nextPage,
    category: state.category.categories,
    subCategory: state.subCategory.categories
});
  
export default connect(mapStateToProps, { listProducts, loadMoreProducts, listCategories, listSubCategories })(AdminProducts);