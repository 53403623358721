import WebClient from "../client/webclient.client";

  

  export const listTrendingSells = (products) => {
    
    return async (dispatch) => {
      dispatch({ type: 'LIST_TRENDING_SELLS_START',payload:[]});
      try {
        const response = await WebClient.get({
          path:'/products',
          parameters:{trendingSell:true}
        })
        let data = await response.data;
        console.log("List trendingSells product",data)
        data.Items=data.Items.map(item=>{
          item.updatedAt=item.updatedAt?item.updatedAt:0;
          return item;
        })
        
        if(!data.hasOwnProperty('LastEvaluatedKey')){
          dispatch({ type: 'LIST_TRENDING_SELLS_LOADING', payload: data.Items });
        }
        while(data.hasOwnProperty('LastEvaluatedKey')){
          let  response = await WebClient.get({
            path:'/products',
            parameters:{trendingSell:true,startKey:data.LastEvaluatedKey.id}
          })
          response = await response.data;
          data.Items = [...data.Items,...response.Items]
          data.Items=data.Items.map(item=>{
            item.updatedAt=item.updatedAt?item.updatedAt:0;
            return item;
          })
          if(response.hasOwnProperty('LastEvaluatedKey')){
            data.LastEvaluatedKey=response.LastEvaluatedKey;
          }else{
            delete data.LastEvaluatedKey;
          }
          console.log('In trendingSells Fetch',data)
        }
        let sortedData = [...data.Items];
        sortedData.sort((a, b) => b.updatedAt - a.updatedAt);
        dispatch({ type: 'LIST_TRENDING_SELLS_COMPLETED', payload: sortedData });
      } catch (error) {
        console.log('Failed',error)
        dispatch({ type: 'LIST_TRENDING_SELLS_COMPLETED', payload: error });
      }
    }
  };