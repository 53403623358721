import React, { useEffect,Component, useState} from 'react';
import { connect, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import Countdown from 'react-countdown'
import { listDailyBestSells } from '../../actions/dailyBestProductAction';
import AddToCart from '../common/addToCartButton';
import { Link } from 'react-router-dom';
import {ReadRating} from '../common/ratings';
import {fixName} from '../common/helper';
import AddToWishlist from '../common/addToWishlistButton';
import Login from '../../Pages/Login';
function getCountDown(){
    let date = new Date((new Date()).setHours(0,0,0));
    return date.setDate(date.getDate()+1);
}

function DailyBestSell({dailyProducts,listDailyBestSells}){
    const popularCategory = useSelector(state=>state.popularCategory.categories);
    const [categoryFilter,setCategoryFilter] = useState("all");
    const [mandateLogin,setMandateLogin] = useState(false);
    useEffect(()=>{
        listDailyBestSells();
    },[listDailyBestSells,categoryFilter]);
    const authorizedUser = useSelector(state=>state.user.user);
    useEffect(()=>{
        if(authorizedUser){
            setMandateLogin(false)
        }
    },[authorizedUser])
    return(
        <>
        {(mandateLogin)?(<Login isModalOpen={mandateLogin} openModal={mandateLogin} closeModal={()=>{setMandateLogin(false)}} redirectTo={"/checkout"}/>):null}
        <div id="ht-best-sell" className="ht-best-sell featured-product-carousel mb-6">
            <div className="container title-container ht-f ht-fw ht-ac mb-3">
                <h3 className="title ht-fb-title">Daily Best Sells</h3>
                {/* <ul className="nav nav-pills nav-border-anim nav-big justify-content-center ml-5" role="tablist">
                    <li className="nav-item">
                        <a className={"nav-link"+((categoryFilter==="all")?(" active"):(""))} id="products-featured-link" onClick={()=>(setCategoryFilter("all"))} data-toggle="tab" role="tab" aria-controls="products-featured-tab" aria-selected="true">All</a>
                    </li>
                    {   
                        popularCategory.map(category=>{
                            return(
                                <li className="nav-item" key={category.categoryName}>
                                    <a className={"nav-link"+((categoryFilter===category.categoryName)?(" active"):(""))} id="products-sale-link" onClick={()=>(setCategoryFilter(category.categoryName))} data-toggle="tab" role="tab" aria-controls="products-sale-tab" aria-selected="false">{category.categoryLabel}</a>
                                </li>
                            )
                        })
                    }
                </ul> */}
                <div className="ht-countdown mb-1">
                    <span>Expires in : </span>
                    <Countdown date={ getCountDown()} />
                </div>
            </div>

            <div className="container">
                <div className="ht-f ht-fw">
                    <div className="tab-content tab-content-carousel tab-content-withForm">
                        <div className="tab-pane p-0 fade show active" id="products-featured-tab" role="tabpanel" aria-labelledby="products-featured-link">
                            <OwlCarousel items={((dailyProducts.length<5)?dailyProducts.length:5)}  
                                className="owl-theme carousel-with-shadow ht-bg-products"  
                                responsive={{
                                    0: {
                                        items: 1,
                                        nav: false
                                    },
                                    420: {
                                        items: 2,
                                        nav: false
                                    },
                                    576: {
                                        items: 2,
                                        nav: false
                                    },
                                    768: {
                                        items: 2,
                                        nav: false
                                    },
                                    992: {
                                        items: 3,
                                        nav: false
                                    },
                                    1200: {
                                        items: 3,
                                    },
                                    1500: {
                                        items: 4,
                                    }
                                }}
                                nav
                                dots={false}
                                margin={20}
                                item={dailyProducts.toString()}
                                >

                           { 
                            
                            dailyProducts.filter((product)=>(product.category==categoryFilter||categoryFilter=="all")).map((product)=>{
                                
                                    return(
                                        
                                        <div className="product product-11 text-center" key={"dailyProd"+product.id}>
                                            <figure className="product-media">
                                                <span className="product-label label-circle label-sale">Sale</span>
                                                
                                                
                                                <Link to={'products/'+(product.id)}>
                                                    <img src={product.image} alt="Product image" className="product-image"/>
                                                </Link>

                                                    <div className="product-action-vertical">
                                                        <AddToWishlist item={product}/>
                                                    </div>{/* End .product-action-vertical */}

                                                </figure>{/* End .product-media */}

                                           <div className="product-body">
                                                <h5><Link to={`products?category=${product.category}`}>{fixName(product.category)}</Link></h5>
                                                <h3 className="product-title"><Link to={'products/'+(product.id)}>{product.productName}</Link></h3>{/* End .product-title */}
                                                <ReadRating value={product.rating}/>
                                                <div className="product-price">
                                                    <span className="new-price">Rs.{product.saleRate}</span>
                                                    <span className="old-price">Was Rs.{product.mrp}</span>
                                                </div>{/* End .product-price */}

                                                    
                                                </div>{/* End .product-body */}
                                                <AddToCart item={product}/>
                                            </div>
                                        );
                                    })
                                }
                                

                            </OwlCarousel>
                    </div>
                    
                </div>
                {!authorizedUser?(<div className='ht-signUp ht-f1'>
                            <h6>10% OFF</h6>
                            <p>For new member sign up at the first time</p>
                            {/* <div className="input-group mb-2 ht-f ht-fc text-left">
                                <span>Email</span>
                                <div className="input-group-prepend">
                                    <div className='ht-relative ht-f1'>
                                    <i className="mail-i"></i>
                                    <input type="email" className="form-control" placeholder="Email"/>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-2 ht-f ht-fc text-left">
                                <span>Password</span>
                                <div className="input-group-prepend">
                                    <div className='ht-relative ht-f1'>
                                    <i className="key-i"></i>
                                    <input type="password" className="form-control" placeholder="Password"/>
                                    </div>
                                </div>
                            </div> */}
                        <div className="product-action" onClick={()=>{setMandateLogin(true)}}><a className="btn-product reg-btn"><span>Register</span></a></div>
                    </div>):null}
                </div>
            </div>
        </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    dailyProducts: [...state.dailyProducts.products]
  });
  
export default connect(mapStateToProps, { listDailyBestSells })(DailyBestSell);