export const setOrder = (order,step) => {
    return async (dispatch)=>{
        dispatch({type: 'SET_ORDER',
        payload: {order,step}});
    };
  };
  export const resetOrder = () => {
    return async (dispatch)=>{
        dispatch({type: 'SET_ORDER',
        payload: {}});
    };
  };
