function fixName(name){
    let nname = name.replaceAll('_and_',' & ').replaceAll('_',' ');
    nname=nname.charAt(0).toUpperCase()+nname.substring(1,nname.length);
    return nname;
}
function isArrayEmpty(list){
    return ((Array.isArray(list))&&(list.length==0))||list===undefined||list===null
}
function empty(any){
    if(typeof any ==='string'){
        return (any==='' || any===null || any===undefined)
    }else if(typeof any ==='number'){
        return (any===null || any===undefined)
    }else{
        return true;
    }
}
const searchTermGenerator = (str)=>{
    return str.toLowerCase().replaceAll(/[~!@#$%^&*\(\)\-_\=\+`,\.\/<>\?\:";'\[\]]/g,'').replaceAll(/[ ]{1,}/g,'');
}
function canonicalizeKey(string){
    if(string===undefined||string===null){
        console.log("Null string incoming");
    }
    try{
        return string.trim().replaceAll('&','and').replaceAll('/\W','_').replaceAll(/[, ]/g,'_').toLowerCase();
    }catch(err){
        throw err;
    }
}
function validateMandatory(obj){
    let flag = true;
    for(let key in obj){
        let isEmpty = empty(obj[key]);
        console.log(`isEmpty: ${key} - ${obj[key]} => ${isEmpty}`)
        if(isEmpty){
            flag=false;
        }
    }
    return flag;
}
const statusList = {"created":"Created","payment_initiated":"Payment initiated","payment_requested":"Payment requested","payment_completed":"Payment completed","payment_manual":"Payment manual","shipped":"Shipped","delivered":"Delivered","return_initiated":"Return initiated","return_picked_up":"Return picked up","return_completed":"Return completed","refund_initiated":"Refund initiated","refund_completed":"Refund completed","abandoned":"Abandoned","cancelled":"Cancelled"};
const scopeList = [
    "products.read","products.all",
    "categories.read","categories.all",
    "subCategories.read","subCategories.all",
    "orders.read","orders.all","banners.write","banners.all",
    "users.read","users.write","users.all"
];
const onEnter = (event,functionCall)=>{
    alert(event);
        if (event.key === 'Enter') {
          functionCall()
        }
}
export {fixName,isArrayEmpty,searchTermGenerator,canonicalizeKey,empty,statusList,scopeList,onEnter,validateMandatory};