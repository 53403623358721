const initialState = {
    categories: []
  };
  
  const popularCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LIST_POPULAR_CATEGORIES':
        return {
          ...state,
          categories:action.payload
        };
      default:
        return state;
    }
  };
  
  export default popularCategoryReducer;
  