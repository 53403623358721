import WebClient from "../client/webclient.client";

  export const listPopularCategories = (categories) => {
    return async (dispatch) => {
      dispatch({ type: 'LIST_POPULAR_CATEGORIES',payload:[]});
      try {
        const response = await WebClient.get({
          path:'/categories'
        })
        const data = await response.data;
        data.Items = data.Items.sort((a,b)=>(b.popularity-a.popularity)).splice(0,2);
        console.log('In pop cat Fetch',data.Items)
        dispatch({ type: 'LIST_POPULAR_CATEGORIES', payload: data.Items });
      } catch (error) {
        console.log('Failed',error)
        dispatch({ type: 'LIST_POPULAR_CATEGORIES', payload: error });
      }
    }
  };
  