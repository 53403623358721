import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../actions/cartAction";

function AddToCart({ item, qty }) {
    const dispatch = useDispatch();
    const [isAdded, setIsAdded] = useState(false);

    const handleAddToCart = async (product) => {
        dispatch(addToCart(product, qty || 1));
        setIsAdded(true); // Set added state to true

        // Remove the "added" class after 1 second
        setTimeout(() => {
            setIsAdded(false);
        }, 1000);
    };

    return (
        <div className="product-action">
            <a
                onClick={(e) => {
                    e.preventDefault();
                    handleAddToCart({ ...item });
                }}
                className={`btn-product btn-cart ${isAdded ? "added" : ""}`}
            >
                <span>{isAdded ? "Added to cart" : "Add to cart"}</span>
            </a>
        </div>
    );
}

export default AddToCart;