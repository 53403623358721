const initialState = {
  products: []
  };
  
  const dailyBestProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LIST_DAILY_BEST_SELLS_START':
        return {
          ...state,
          products:[]
        };
      case 'LIST_DAILY_BEST_SELLS_LOADING':
          return {
            ...state,
            products:[...action.payload]
          };
      case 'LIST_DAILY_BEST_SELLS_COMPLETED':
        return {
          ...state,
          products:[...action.payload]
        };
      default:
        return state;
    }
  };
  
  export default dailyBestProductReducer;
  