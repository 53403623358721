import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/footer";
import { connect, useDispatch, useSelector } from "react-redux";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Login from "../../Pages/Login";


const OrderComplete = () => {
    let {id} = useParams();
  const authorizedUser = useSelector(state=>state.user.user);
  const [mandateLogin,setMandateLogin] = useState(false);
  
  return (
    <>
    <Header/>
    {(mandateLogin)?(<Login isModalOpen={mandateLogin} openModal={mandateLogin} closeModal={()=>{if(authorizedUser){setMandateLogin(false)}}} redirectTo={"/checkout"}/>):null}
    <main className="main">
      <div className="page-content">
        <div className="checkout">
          <div className="container">
            <form action="#">
              <div className="row ht-as">
                <div className="col-lg-9 checkout-left">
                  {  
                    <div className="ht-confirm-msg main-payment-waiting">
                    <h3>Order <a href={"/orders?id="+id}>#{id}</a> has been placed 🎉. Check <a href="/orders">orders</a> page for more information.</h3>
                    <CheckCircleOutlineIcon className="tick-icon"/>
                <div></div>
              </div>
                  }
                </div>
                {/* End .col-lg-3 */}
              </div>
              {/* End .row */}
            </form>
          </div>
          {/* End .container */}
        </div>
        {/* End .checkout */}
      </div>
      {/* End .page-content */}
    </main>
    <Footer />
    </>
  );
};


export default (OrderComplete);