function SubHeader(){
    return(
        <div className="sub-header">
            <div className='home'>
                <p>Home</p>
            </div>
            <div className='hotdeals'>
            hotdeals
            </div>
            <div className='promotions'>promotions</div>
            <div className='new-products'>new-products</div>
            <div className='contact-details'>Profile</div>
        </div>
    );
}
export default SubHeader;