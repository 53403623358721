import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Payments(){
    const [timer,setTimer]=useState();
    
    useEffect(()=>{
        setTimer(4);
    },[])
    useEffect(()=>{
        if(timer){
            if(timer>1){
                setTimeout(()=>{setTimer(timer-1)},1000);
            }else{
                window.close()
            }
        }
    },[timer])
    return(
        <div>
            <p>Payment received</p>
            <p>Redirecting to Homepage</p>
            <CircularProgress/>
        </div>
    )
}
export default Payments;