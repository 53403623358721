import WebClient from "../client/webclient.client";

export const setProducts = (products) => {
    return {
      type: 'SET_PRODUCTS',
      payload: products
    };
  };
  
  export const listProducts = (filter) => {
    
    return async (dispatch) => {
      dispatch({ type: 'LIST_PRODUCTS_START',payload:{items:[],nextPage:null}});
      try {
        const response = await WebClient.get({
          path:'/products',
          parameters:{...filter}
        })
        let data = await response.data;
        let nextPage = null;
        if(data.LastEvaluatedKey){
          nextPage=data.LastEvaluatedKey.id; 
        }
        // data.Items = data.Items?.map(item=>{
        //   item['image']=process.env.REACT_APP_BASEAPIURL+'/products/image/'+item.id
        //   return item;
        // })
        console.log("products action",data);
        dispatch({ type: 'LIST_PRODUCTS', payload: {items:data.Items,nextPage:nextPage} });
        // while(data.hasOwnProperty('LastEvaluatedKey')){
        //   const response = await WebClient.get({
        //     path:'/products',
        //     parameters:{isFeatured:true,startKey:data.LastEvaluatedKey.id,hasImages:true}
        //   })
        //   let newData = await response.data;
        //   console.log("products list loop",newData);
        //   data.Items=[...data.Items,...newData.Items];
        //   data['LastEvaluatedKey'] = newData.LastEvaluatedKey||{id:{S:null}};
          
        //   dispatch({ type: 'LIST_PRODUCTS', payload: {items:data.Items,nextPage:data.LastEvaluatedKey.id.S||null} });
        // }
      } catch (error) {
        console.log('Failed',error)
        dispatch({ type: 'LIST_PRODUCTS_ERROR', payload: {items:[],error:error}});
      }
    }
  };
  export const loadMoreProducts = (filter) => {
    
    return async (dispatch) => {
      
      dispatch({ type: 'LIST_PRODUCTS_LOAD_START', payload: {} })
      try {
        console.log("in dispatch");
        const response = await WebClient.get({
          path:'/products',
          parameters:{...filter,hasImages:true}
        })
        let data = await response.data;
        console.log("loading more products",response);
        let nextPage = null;
        if(data.LastEvaluatedKey){
          nextPage=data.LastEvaluatedKey.id; 
        }
        data.Items = data.Items?.map(item=>{
          item['image']=process.env.REACT_APP_BASEAPIURL+'/products/image/'+item.id
          return item;
        })
        console.log("products action",data);
        dispatch({ type: 'LIST_PRODUCTS_LOAD_MORE', payload: {items:data.Items,nextPage:nextPage} });
        // while(data.hasOwnProperty('LastEvaluatedKey')){
        //   const response = await WebClient.get({
        //     path:'/products',
        //     parameters:{isFeatured:true,startKey:data.LastEvaluatedKey.id,hasImages:true}
        //   })
        //   let newData = await response.data;
        //   console.log("products list loop",newData);
        //   data.Items=[...data.Items,...newData.Items];
        //   data['LastEvaluatedKey'] = newData.LastEvaluatedKey||{id:{S:null}};
          
        //   dispatch({ type: 'LIST_PRODUCTS', payload: {items:data.Items,nextPage:data.LastEvaluatedKey.id.S||null} });
        // }
      } catch (error) {
        console.log('Failed',error)
        dispatch({ type: 'LIST_PRODUCTS_ERROR', payload: {items:[],error:error}});
      }
    }
  };