import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { removeFromWishlist } from "../../actions/wishlistAction";
import Header from "./header";
import Footer from "./footer";
import SmallProductCard from "../productComponents/smallProductCard";
import DeleteIcon from '@mui/icons-material/Delete';

function Wishlist() {
  const wishlist = useSelector(state => state.wishlist);
  const dispatch = useDispatch();
  const handleRemoveItem = (item) => {
    dispatch(removeFromWishlist(item));
  }
  return (
      <>
        <Header/>
        <div className="page-header text-center" >
        		<div className="container">
        			<h1 className="page-title">Wishlist</h1>
        		</div>
        	</div>
        <div className="ht-productList-page container mt-5">
          <div className="product-list wishlist-blk">
            {
              wishlist.items.map(item => {
                return (
                  <div key={item.id} class="product-blk" >
                      <DeleteIcon className="delete-icon" onClick={()=>{dispatch(removeFromWishlist(item))}}/>
                      <SmallProductCard product={item} setEditProduct={()=>{}} hasAddToCart={true} hasAddToWishlist={false}/>
                      </div>
                )
              })
            }
          </div>
        </div>
        <Footer/>
        </>

        
  )
}
export default (Wishlist);
