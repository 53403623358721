import React, { Component} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import { useSelector } from 'react-redux';
import { isArrayEmpty } from '../common/helper';

function CategoryCarousel(){
    const categories = useSelector(state=>state.category.categories);
    console.log("selectorCategory",categories);
    return(
    <div className='categories-carousel mb-6'>
            <div className="container ht-f ht-ac ht-jsb mb-3">
                <h3 className="title ht-fb-title">Explore Categories</h3>
                
            </div>{/* End .container */}

            <div className="container">
                <div className="tab-content tab-content-carousel">
                    <div className="tab-pane p-0 fade show active" id="products-featured-tab" role="tabpanel" aria-labelledby="products-featured-link">
                        <OwlCarousel items={7}  
                            className="owl-theme carousel-with-shadow ht-bg-products"  
                            loop  
                            dots = {false}
                            nav 
                            responsive={{
                                0: {
                                    items: 2,
                                    nav: false
                                },
                                420: {
                                    items: 3,
                                    nav: false
                                },
                                576: {
                                    items: 3,
                                    nav: false
                                },
                                768: {
                                    items: 4,
                                    nav: false
                                },
                                992: {
                                    items: 6,
                                    nav: false
                                },
                                1200: {
                                    items: 7,
                                }
                            }}
                            margin={20}>
                            {
                                (categories)?(categories.map(category=>{
                                    return(
                                        <div className="product product-11 text-center">
                                            <figure className="product-media">
                                                <a href={'/products?category='+category.categoryName}>
                                                    <img src={category.image} alt="Product image" className="product-image"/>
                                                </a>

                                                
                                            </figure>

                                            <div className="product-body">
                                                <h3 className="product-title text-center"><a href={'/products?category='+category.categoryName}>{category.categoryLabel}</a></h3>{/* End .product-title */}
                                                
                                            </div>{/* End .product-body */}
                                            
                                        </div>
                                    )
                                })):(null)

                            }
                        </OwlCarousel>
                    </div>{/* .End .tab-pane */}
                    <div className="tab-pane p-0 fade" id="products-sale-tab" role="tabpanel" aria-labelledby="products-sale-link">
                        <OwlCarousel items={7}  
                            className="owl-theme carousel-with-shadow"  
                            loop  
                            dots
                            nav = {false}  
                            margin={20}>
                            
                        </OwlCarousel>{/* End .owl-carousel */}
                    </div>{/* .End .tab-pane */}
                    <div className="tab-pane p-0 fade" id="products-top-tab" role="tabpanel" aria-labelledby="products-top-link">
                        <OwlCarousel items={4}  
                            className="owl-theme carousel-with-shadow"  
                            loop  
                            dots
                            nav = {false}  
                            margin={20}>
                            
                        </OwlCarousel>{/* End .owl-carousel */}
                    </div>{/* .End .tab-pane */}
                </div>{/* End .tab-content */}
            </div>{/* End .container-fluid */}
</div>);
}
export default CategoryCarousel;