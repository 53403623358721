import React, { useState } from 'react';
import '../../ImageStack.css';

const ImageStack = ({images=[]}) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
  return (
    <div className="image-stack">
      {images.map((src, index) => (
        <img
        key={index}
        width="100px"
        height={"100px"}
        src={src}
        alt={`Book ${index + 1}`}
        className={`stacked-image image-${index}`}
        style={{
          transform: hoveredIndex === index
            ? `translateY(-20px) rotate(${(index + 1) * 5}deg)`
            : `translateY(${index * 20}px) rotate(0deg)`
        }}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
        />
      ))}
    </div>
  );
};

export default ImageStack;