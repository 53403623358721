const initialState = {
  products: []
  };
  
  const topSellingProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LIST_TOP_SELLS_START':
          return {
            ...state,
            products:[...action.payload]
          };
      case 'LIST_TOP_SELLS_LOADING':
        return {
          ...state,
          products:[...action.payload]
        };
        case 'LIST_TOP_SELLS_COMPLETED':
          return {
            ...state,
            products:[...action.payload]
          };
      default:
        return state;
    }
  };
  
  export default topSellingProductReducer;
  