const initialState = {
    items: [],
    nextPage:null

  };
  
  const productReducer = (state = initialState, action) => {
    console.log("PR action",action.payload);
    switch (action.type) {
      case 'SET_PRODUCTS':
        return {
          ...state,
          items: action.payload.items,
          nextPage:action.payload.nextPage
        };
      case 'LIST_PRODUCTS_START':
        return {
          ...state,
          items: action.payload.items,
          nextPage:action.payload.nextPage
        };
        case 'LIST_PRODUCTS_LOAD_MORE_START':
          return {
            ...state,
            items: [state.items],
            nextPage:state.nextPage
          };
      case 'LIST_PRODUCTS':
        return {
          ...state,
          items:action.payload.items,
          nextPage:action.payload.nextPage
        };
        case 'LIST_PRODUCTS_LOAD_MORE':
          return {
            ...state,
            items:[...state.items,...action.payload.items],
            nextPage:action.payload.nextPage
          };
        case 'LIST_PRODUCTS_ERROR':
        return {
          ...state,
          items:[],error:action.payload.error.message
        };
      default:
        return state;
    }
  };
  
  export default productReducer;
  